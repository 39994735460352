import React from "react";
import { useController, useFormContext } from "react-hook-form";
import Input from "../Input";
import { FormInputProps } from "./types";
export const FormInput: React.FC<FormInputProps> = ({
  name,
  defaultValue = "",
  ...inputProps
}) => {
  const formContext = useFormContext();
  const {
    control,
    formState: { errors },
  } = formContext;

  const { field } = useController({ name, control, defaultValue });

  return (
    <Input
      {...inputProps}
      error={errors[name]?.message}
      onChange={field.onChange}
      onBlur={field.onBlur}
      value={field.value}
    />
  );
};

export default FormInput;
