import { createTheme } from "@mui/material/styles";
// import InvescoEditor from "../fonts/InvescoEditor-Regular.otf";
const theme = createTheme({
  palette: {
    primary: {
      light: "#e65100",
      main: "#000",
    },
    secondary: {
      main: "#f33e57",
    },
  },
  // typography: {
  //   fontSize: 14,
  //   fontFamily: "InvescoEditor",
  // },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: `
  //     @font-face {
  //       font-family: 'InvescoEditor';
  //       font-style: normal;
  //       font-display: swap;
  //       font-weight: 400;
  //       src: local('InvescoEditor-Regular'), local('Raleway-Regular'), url(${InvescoEditor}) format('otf');
  //       unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
  //     }
  //   `,

  //   },
  // },

  // typography: {
  //   fontSize: 14,

  //   fontWeightLight: "lighter",
  //   fontFamily: "Roboto",
  // },
});

export default theme;
