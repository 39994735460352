import { Divider, Button } from "@mui/material";
import { Box } from "@mui/system";
import Input from "components/Input";
import ImageDropzone from "components/ImageDropZone";
import { ChangeEvent, useState, useRef } from "react";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import Text from "components/Text";
import colors from "res/colors";

const FactSheet = () => {
  const [title, setTitle] = useState("");
  const imageDropzoneRef = useRef<any>(null);

  const handleTitle = (event: ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };
  const handleImageDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    // setValue('image', { imageData: imageData, name: name });
  };

  return (
    <Box sx={{ mt: "40px" }}>
      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneRef}
        heading="Upload PDF file here"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="PDF"
      />

      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ pb: "16px" }}>
        <Text type="mediumBold">And add text here</Text>
      </Box>
      <Input
        variant="filled"
        InputProps={{
          disableUnderline: true,
        }}
        label="Add Title"
        onChange={handleTitle}
      />
      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};
export default FactSheet;
