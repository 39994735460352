import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SalesRMState = {
  isUploadingFundManager: boolean;
  uploadFundManagerError: string;
  didUploadFundManager: boolean;

  isUpdatingFundManager: boolean;
  updateFundManagerError: string;
  didUpdateFundManager: boolean;

  isLoadingFundManagers: boolean;
  fundManagersError: string;
  fundManagersData: Array<any>;

  isDeleteFundManager: boolean;
  fundManagerDeleteError: string;
  fundManagerDeleted: string;
};

const INITIAL_STATE: SalesRMState = {
  isUploadingFundManager: false,
  uploadFundManagerError: "",
  didUploadFundManager: false,

  isUpdatingFundManager: false,
  updateFundManagerError: "",
  didUpdateFundManager: false,

  isLoadingFundManagers: false,
  fundManagersError: "",
  fundManagersData: [],

  isDeleteFundManager: false,
  fundManagerDeleteError: "",
  fundManagerDeleted: "",
};

const fundManager = createSlice({
  name: "fundmanager",
  initialState: INITIAL_STATE,
  reducers: {
    uploadFundManager: (state, _action: PayloadAction<any>) => {
      state.isUploadingFundManager = true;
    },
    didUploadFundManager: (state) => {
      state.isUploadingFundManager = false;
      state.didUploadFundManager = true;
    },
    didFailToUploadFundManager: (state, action: PayloadAction<any>) => {
      state.isUploadingFundManager = false;
      state.uploadFundManagerError = action.payload;
    },
    resetFundManagerStates: (state) => {
      state.isUploadingFundManager = false;
      state.didUploadFundManager = false;
      state.uploadFundManagerError = "";
    },
    updateFundManager: (state, _action: PayloadAction<any>) => {
      state.isUpdatingFundManager = true;
    },
    didUpdateFundManager: (state) => {
      state.isUpdatingFundManager = false;
      state.didUpdateFundManager = true;
    },
    didFailToUpdateFundManager: (state, action: PayloadAction<any>) => {
      state.isUpdatingFundManager = false;
      state.updateFundManagerError = action.payload;
    },
    resetFundManagerUpdateStates: (state) => {
      state.isUpdatingFundManager = false;
      state.didUpdateFundManager = false;
      state.updateFundManagerError = "";
    },
    loadFundManagers: (state) => {
      state.isLoadingFundManagers = true;
      state.fundManagersError = "";
      state.fundManagersData = [];
    },
    didLoadFundManagers: (state, action: PayloadAction<any>) => {
      state.isLoadingFundManagers = false;
      state.fundManagersData = action.payload;
    },
    didFailToLoadFundManagers: (state, action: PayloadAction<any>) => {
      state.isLoadingFundManagers = false;
      state.fundManagersError = action.payload;
    },
    deleteFundManager: (state, action: PayloadAction<any>) => {
      state.isDeleteFundManager = true;
      state.fundManagerDeleteError = "";
      state.fundManagerDeleted = "";
    },
    didDeleteFundManager: (state, action: PayloadAction<any>) => {
      state.isDeleteFundManager = false;
      state.fundManagerDeleteError = "";
      state.fundManagerDeleted = action.payload;
    },
    didFailedToDeleteFundManager: (state, action: PayloadAction<any>) => {
      state.isDeleteFundManager = false;
      state.fundManagerDeleteError = action.payload;
    },
  },
});

export const {
  uploadFundManager,
  didUploadFundManager,
  didFailToUploadFundManager,
  resetFundManagerStates,
  loadFundManagers,
  didLoadFundManagers,
  didFailToLoadFundManagers,
  updateFundManager,
  didUpdateFundManager,
  didFailToUpdateFundManager,
  resetFundManagerUpdateStates,
  deleteFundManager,
  didDeleteFundManager,
  didFailedToDeleteFundManager,
} = fundManager.actions;
export default fundManager.reducer;
