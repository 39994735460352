import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import Input from "components/Input";
import Text from "components/Text";

import colors from "res/colors";
import { FileInputProps, FileInputHandle } from "./types";

const Choose = () => {
  return (
    <Button
      variant="text"
      sx={{
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: 500,
        px: 1,
        color: colors.backGroundBlue,
      }}
    >
      Choose
    </Button>
  );
};

const FileInput: ForwardRefRenderFunction<FileInputHandle, FileInputProps> = (
  { icon, type, onChange, error },
  ref
) =>
  // const FileInput: React.FC<FileInputProps> = ({
  //   icon,
  //   type,
  //   onChange,
  //   error,
  // }) =>
  {
    const [text, setText] = useState("");
    useImperativeHandle(ref, () => ({
      clearText() {
        setText("");
      },
    }));

    const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setText(event.target.value);
      if (onChange) {
        onChange(event.target.value);
      }
    };
    const renderComponent = () => {
      return (
        <div>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              minWidth: "450px",
              pl: "36px",
            }}
          >
            <Input
              value={text}
              variant="outlined"
              label="Enter Video URL"
              onChange={handleTextChange}
            />
          </Box>
        </div>
      );
    };
    return (
      <>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: "24px",
            pl: "32px",
            borderStyle: "dashed",
            borderRadius: "10px",
            borderColor: colors.uploadBoxBorderColor,
            backgroundColor: colors.uploadBoxColor,
            opacity: 0.4,
            width: "100%",
          }}
        >
          {icon}
          {renderComponent()}
        </Box>
        <Text type="error">{error}</Text>
      </>
    );
  };

// export default FileInput;
export default React.forwardRef(FileInput);
