import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import LocalStore from "utils/LocalStore";

type AuthState = {
  didLogin: boolean;
  baseURL: string;
};

const INITIAL_STATE: AuthState = {
  didLogin: LocalStore.getAccessToken() ? true : false,
  baseURL: "",
};

const auth = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {
    setDidLogin: (state, action: PayloadAction<boolean>) => {
      state.didLogin = action.payload;
    },
    setAppBaseURL: (state, action: PayloadAction<string>) => {
      state.baseURL = action.payload;
    },
  },
});

export const { setDidLogin, setAppBaseURL } = auth.actions;

export default auth.reducer;
