import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TProductNotesParams, TProductNoteDetailsParams } from "./types";

type ProductNotesState = {
  isLodingProductNotes: boolean;
  productNotesError: string;
  productNotesData: Array<any>;

  isDeleteProductNotes: boolean;
  productNotesDeleteError: string;
  productNotesDeleted: string;
};

const INITIAL_STATE: ProductNotesState = {
  isLodingProductNotes: false,
  productNotesError: "",
  productNotesData: [],

  isDeleteProductNotes: false,
  productNotesDeleteError: "",
  productNotesDeleted: "",
};

const productNotes = createSlice({
  name: "productNotes",
  initialState: INITIAL_STATE,
  reducers: {
    loadProductNotes: (state, _action: PayloadAction<TProductNotesParams>) => {
      state.isLodingProductNotes = true;
      state.productNotesError = "";
      // state.productNotesData = [];
    },
    didLoadProductNotes: (state, action: PayloadAction<any>) => {
      state.isLodingProductNotes = false;
      state.productNotesError = "";
      state.productNotesData = action.payload;
    },
    didFailToLoadProductNotes: (state, action: PayloadAction<any>) => {
      state.isLodingProductNotes = false;
      state.productNotesError = action.payload;
    },
    deleteProductNotes: (state, action: PayloadAction<any>) => {
      state.isDeleteProductNotes = true;
      state.productNotesDeleteError = "";
      state.productNotesDeleted = "";
    },
    didDeleteProductNotes: (state, action: PayloadAction<any>) => {
      state.isDeleteProductNotes = false;
      state.productNotesDeleteError = "";
      state.productNotesDeleted = action.payload;
    },
    didFailedToDeleteProductNotes: (state, action: PayloadAction<any>) => {
      state.isDeleteProductNotes = false;
      state.productNotesDeleteError = action.payload;
    },
  },
});

export const {
  loadProductNotes,
  didLoadProductNotes,
  didFailToLoadProductNotes,
  deleteProductNotes,
  didDeleteProductNotes,
  didFailedToDeleteProductNotes,
} = productNotes.actions;
export default productNotes.reducer;
