import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  CardMedia,
  Card,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";
import { ContentActionProps } from "./types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import colors from "res/colors";
import { useState } from "react";
import Text from "components/Text";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditDigitalPoster from "./EditDigitalPoster";

const DigitalPosterContentAction: React.FC<ContentActionProps> = ({
  onDelete,
  onEdit,
  onView,
  data,
  index,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewDialog, setViewDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleConfirmDelete = (data: any) => {
    setDeleteDialog(false);
    onDelete(data, index);
  };

  const handleClose = () => {
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    setDeleteDialog(true);
  };

  const handleView = () => {
    setViewDialog(true);
  };
  const handleViewClose = () => {
    setViewDialog(false);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditClose = () => {
    setEdit(false);
  };

  const editRender = () => {
    return (
      <Dialog open={edit} maxWidth="xl">
        <DialogActions>
          <Button onClick={handleEditClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Update Digital Poster</DialogTitle>
        <DialogContent>
          <EditDigitalPoster content={data} onCancel={handleEditClose} />
        </DialogContent>
      </Dialog>
    );
  };

  const viewRender = () => {
    return (
      <Dialog open={viewDialog}>
        <DialogActions>
          <Button onClick={handleViewClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Digital Poster</DialogTitle>
        <DialogContent>
          <Card sx={{ maxWidth: 600 }}>
            <Box sx={{ position: "relative" }}>
              <CardMedia component="img" image={data.assetUrl} />
            </Box>
            <Box sx={{ pl: "5px" }}>
              <Typography variant="h6">{data.title}</Typography>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      {/* <Link component="button" onClick={() => onView(data)}> */}
      <Link component="button" onClick={() => handleView()}>
        View
      </Link>
      <IconButton
        color="default"
        size="small"
        sx={{}}
        onClick={() => handleEdit()}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        color="default"
        size="small"
        sx={{}}
        onClick={() => handleDelete()}
      >
        <DeleteForeverIcon />
      </IconButton>
      <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete?</DialogTitle>
        <DialogContent>
          This action will permanently delete the content.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleConfirmDelete(data)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {viewRender()}
      {editRender()}
    </Box>
  );
};

export default DigitalPosterContentAction;
