import React from "react";
import RouteConfig from "routes/RouteConfig";
import { Routes, Route } from "react-router-dom";
import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/styles";

import BrokrageStructureManagment from "pages/BrokrageStructureManagment";
import ContentManagement from "pages/ContentManagement";
import EventManagement from "pages/EventManagement";
import LeadManagement from "pages/LeadManagement";
import NotificationManagment from "pages/NotificationManagment";
import SalesRMManagement from "pages/SalesRMManagement";
import UserManagement from "pages/UserManagement";
import SchemeManagement from "pages/SchemeManagement";
import SipPerformance from "pages/SipPerformance";
import BannerManagement from "pages/BannerManagement/BannerManagement";
import SalesrmClusterHead from "pages/SalesrmClusterHead";
import FundManager from "pages/FundManager/FundManager";

const AppRoute = () => {
  return (
    <div style={styles.container}>
      <Routes>
        <Route
          path={RouteConfig.content_management}
          element={<ContentManagement />}
        />
        <Route
          path={RouteConfig.event_management}
          element={<EventManagement />}
        />
        <Route
          path={RouteConfig.sales_rm_management}
          element={<SalesRMManagement />}
        />
        <Route
          path={RouteConfig.user_management}
          element={<UserManagement />}
        />
        <Route
          path={RouteConfig.lead_management}
          element={<LeadManagement />}
        />
        <Route
          path={RouteConfig.salesrm_cluster_Head}
          element={<SalesrmClusterHead />}
        />
        <Route
          path={RouteConfig.sip_performance}
          element={<SipPerformance />}
        />
        <Route
          path={RouteConfig.broker_structre_management}
          element={<BrokrageStructureManagment />}
        />
        <Route
          path={RouteConfig.notification_management}
          element={<NotificationManagment />}
        />
        <Route
          path={RouteConfig.scheme_management}
          element={<SchemeManagement />}
        />
        <Route path={RouteConfig.login_banner} element={<BannerManagement />} />
        <Route path={RouteConfig.fund_manager} element={<FundManager />} />
        <Route path={"*"} element={<ContentManagement />} />
      </Routes>
    </div>
  );
};
const styles = Object.freeze({
  container: {
    // display: "flex",
    marginTop: "80px",
    marginLeft: "100px",
    minHeight: "100vh",
    minWidth: "95vw",
    // backgroundColor: "green",
  },
});
export default AppRoute;
