import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import { TInfographicsParams, TInfographicDetailsParams } from "./types";
import ToastCenter from "utils/ToastCenter";
import Request from "networking/Request";
import {
  loadInfographics,
  didLoadInfographics,
  didFailToLoadInfographics,
  deleteInfoGraphics,
  didDeleteInfographics,
  didFailedToDeleteInfographics,
} from "./slice";

const INFOGRAPHICS_ENDPOINT = "v1/admin/infographics/fetchAll";
const INFOGRAPHICS_DELETED = "v1/admin/content/delete";

function* handleLoadInfographics(action: PayloadAction<TInfographicsParams>) {
  const response: TResponse = yield call(
    Request.POST,
    INFOGRAPHICS_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadInfographics(response.error));
  } else {
    yield put(didLoadInfographics(response.data.data.resultList));
  }
}

function* handleDeleteInfographics(action: PayloadAction<TInfographicsParams>) {
  const response: TResponse = yield call(
    Request.POST,
    INFOGRAPHICS_DELETED,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailedToDeleteInfographics(response.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteInfographics(action?.payload?.id));
  }
}

function* watcherLoadInfographics() {
  yield takeLatest(loadInfographics.type, handleLoadInfographics);
}

function* watcherDeleteInfographics() {
  yield takeLatest(deleteInfoGraphics.type, handleDeleteInfographics);
}

export function* infographicsSaga() {
  yield all([watcherLoadInfographics(), watcherDeleteInfographics()]);
}
