import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TProductTraningParams, TProductTraningDetailsParams } from "./types";

type ProductTrainingState = {
  isLodingProductTraining: boolean;
  productTrainingError: string;
  productTrainingData: Array<any>;

  isDeleteProductTraining: boolean;
  productTrainingDeleteError: string;
  productTrainingDeleted: string;
};

const INITIAL_STATE: ProductTrainingState = {
  isLodingProductTraining: false,
  productTrainingError: "",
  productTrainingData: [],

  isDeleteProductTraining: false,
  productTrainingDeleteError: "",
  productTrainingDeleted: "",
};

const productTraining = createSlice({
  name: "productTraining",
  initialState: INITIAL_STATE,
  reducers: {
    loadProductTraining: (
      state,
      _action: PayloadAction<TProductTraningParams>
    ) => {
      state.isLodingProductTraining = true;
      state.productTrainingError = "";
      state.productTrainingData = [];
    },
    didLoadProductTraining: (state, action: PayloadAction<any>) => {
      state.isLodingProductTraining = false;
      state.productTrainingError = "";
      state.productTrainingData = action.payload;
    },
    didFailToLoadProductTraining: (state, action: PayloadAction<any>) => {
      state.isLodingProductTraining = false;
      state.productTrainingError = action.payload;
    },
    deleteProductTraining: (state, action: PayloadAction<any>) => {
      state.isDeleteProductTraining = true;
      state.productTrainingDeleteError = "";
      state.productTrainingDeleted = "";
    },
    didDeleteProductTraining: (state, action: PayloadAction<any>) => {
      state.isDeleteProductTraining = false;
      state.productTrainingDeleteError = "";
      state.productTrainingDeleted = action.payload;
    },
    didFailedToDeleteProductTraining: (state, action: PayloadAction<any>) => {
      state.isDeleteProductTraining = false;
      state.productTrainingDeleteError = action.payload;
    },
  },
});

export const {
  loadProductTraining,
  didLoadProductTraining,
  didFailToLoadProductTraining,
  deleteProductTraining,
  didDeleteProductTraining,
  didFailedToDeleteProductTraining,
} = productTraining.actions;
export default productTraining.reducer;
