import * as yup from "yup";
export const contentValidationSchema = yup.object().shape({
  title: yup.string().min(5).required("Please enter a valid title"),
  notificationTitle: yup.string().when("isShowNotification", {
    is: (val: boolean) => val === true,
    then: yup.string().min(3).max(1000).required("Please enter a valid title"),
  }),
  // notificationDescription: yup.string().when("isShowNotification", {
  //   is: (val: boolean) => val === true,
  //   then: yup.string().max(1000).required("Please enter a valid description"),
  // }),
  notificationShortDescription: yup.string().when("isShowNotification", {
    is: (val: boolean) => val === true,
    then: yup
      .string()
      .max(1000)
      .required("Please enter a valid short description"),
  }),
  // description: yup
  //   .string()
  //   .min(15)
  //   .required("Please enter a valid description"),
  //   thumbnail_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
  //   detail_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
  //   material_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
});
