/* eslint-disable react-hooks/exhaustive-deps */
import { makeStyles } from "@material-ui/styles";
import { Box, Button, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import colors from "res/colors";
import FormInput from "components/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { emailValidationSchema } from "./validationSchema";
import { TUserNameForm } from "./types";
import { useAppDispatch } from "hooks/useAppDispatch";
import { generateOtp } from "./store/slice";
import { useAppSelector } from "hooks/useAppSelector";
import Text from "components/Text";
import { useEffect } from "react";
import { getGuid } from "utils/getGuid";

interface UsernameProps {
  signInCallBack: (arg: string) => void;
}

const UserNameForm: React.FC<UsernameProps> = ({ signInCallBack }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { isGeneratingOtp, generateOtpError, didGenerateOtp } = useAppSelector(
    (state) => state.login
  );
  useEffect(() => {
    if (didGenerateOtp) {
      signInCallBack(formMethods.getValues("email"));
    }
  }, [didGenerateOtp]);

  const formMethods = useForm<TUserNameForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(emailValidationSchema),
  });

  const onSubmit = (data: TUserNameForm) => {
    const otp_request = {
      emailId: data.email,
      requestReference: getGuid(),
      source: "W",
    };
    dispatch(generateOtp(otp_request));
  };
  const showError = Boolean(generateOtpError);
  return (
    <>
      <Box className={classes.container}>
        <FormProvider {...formMethods}>
          <FormInput variant="standard" name="email" label="User Name" />
        </FormProvider>
        {/* <Text type="mediumBold" sx={{ mt: "25px", color: "red" }}>
        {" "}
        {generateOtpError}{" "}
      </Text> */}
        {showError && (
          <Text type="mediumBold" sx={{ mt: "25px", color: "red" }}>
            {generateOtpError}
          </Text>
        )}
        <Button
          type="submit"
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={isGeneratingOtp}
          sx={{
            height: "56px",
            width: "160px",
            mt: "40px",
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Generate OTP
        </Button>
      </Box>
      {/* {isGeneratingOtp && <ActivityOverlay />} */}
    </>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "56px",
    paddingTop: 0,
  },
  buttonStyle: {
    height: 56,
    width: 160,
    color: "red",
    marginTop: "38.5px",
    background: colors.backGroundBlue,
  },
});
export default UserNameForm;
