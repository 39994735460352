import { Box, Divider, Grid, InputAdornment } from "@mui/material";
import Input from "components/Input";
import Text from "components/Text";
import { FC, useEffect, useState } from "react";
import { DisplayUserListProps, userListType } from "./types";
import UserListItem from "./UserListItem";
import { ReactComponent as SearchIcon } from "res/images/searchIcon.svg";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  deleteUser,
  resetDeleteUser,
  userLists,
  updateUser,
  resetUpdateUser,
} from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";
const UserList: FC<DisplayUserListProps> = ({ userList }: any) => {
  const dispatch = useAppDispatch();
  const {
    isDeletingUser,
    didDeleteUser,
    deleteUserError,
    isUpdateUser,
    didUpdatedUser,
    updateUserError,
  } = useAppSelector((state) => state.userManagement);
  const [searchResult, setSearchResult] = useState(userList);
  useEffect(() => {
    if (didDeleteUser) {
      ToastCenter.success("User has been deleted successfully");
      dispatch(resetDeleteUser());
    }
    if (didUpdatedUser) {
      ToastCenter.success("User has been updated successfully");
      dispatch(resetUpdateUser());
    }
    if (didDeleteUser || didUpdatedUser)
      dispatch(
        userLists({
          pageNumber: "0",
          size: "100",
        })
      );
  }, [didDeleteUser, didUpdatedUser]);
  useEffect(() => {
    setSearchResult(userList);
  }, [userList]);
  const handleDelete = (data: userListType) => {
    dispatch(deleteUser({ emailId: data.emailId }));
  };
  const handleEdit = (data: userListType) => {
    const params = {
      name: data.name,
      emailId: data.email,
      readAccess: data.readAccess,
      writeAccess: data.writeAccess,
      deleteAccess: data.deleteAccess,
      accessTo: data.accessTo,
    };
    dispatch(updateUser(params));
  };
  const handleSearch = (text: string) => {
    if (!userList && userList.length < 1) return;

    const filteredValues = userList?.filter((item: any) => {
      return (
        item?.name?.toUpperCase().includes(text.toUpperCase()) ||
        item?.emailId?.toUpperCase().startsWith(text.toUpperCase()) ||
        item?.mobile?.toUpperCase().startsWith(text.toUpperCase())
      );
    });
    setSearchResult(filteredValues);
  };
  return (
    <Box>
      <Text type="error">{updateUserError}</Text>
      <Text type="error">{deleteUserError}</Text>
      <Box sx={{ mt: "24px" }}>{deleteUserError}</Box>
      <Box sx={{ mt: "24px" }}>
        <Divider variant="fullWidth" />
      </Box>

      <Input
        sx={{
          [`& .MuiFilledInput-root`]: {
            backgroundColor: "#F8F8F8",
          },
        }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          placeholder: "Search by Name/ Employee ID/Mobile",
        }}
        onChange={(e: any) => {
          handleSearch(e?.target?.value);
        }}
      />

      <Box sx={{ mb: "24px" }}>
        <Divider variant="fullWidth" />
      </Box>

      <Grid container direction="row" sx={{ pl: "18px", pb: "16px" }}>
        <Grid xs={4.4}>
          <Text type="listMediumBold">Name</Text>
        </Grid>
        <Grid xs={4.8}>
          <Text type="listMediumBold">Email</Text>
        </Grid>
        <Grid xs={1}>
          <Text type="listMediumBold">Role</Text>
        </Grid>
        <Grid xs={0.5}>
          <Box></Box>
        </Grid>
      </Grid>

      {searchResult.map((user: any) => {
        return (
          <UserListItem
            data={user}
            key={user.id}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        );
      })}
      {isDeletingUser && <ActivityOverlay />}
      {isUpdateUser && <ActivityOverlay />}
    </Box>
  );
};

export default UserList;
