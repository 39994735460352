import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type UserManagementState = {
  isAddingUser: boolean;
  didAddUser: boolean;
  addUserError: string;

  isUpdateUser: boolean;
  didUpdatedUser: boolean;
  updateUserError: string;

  isAddingAdmin: boolean;
  didAddAdmin: boolean;
  addAdminError: string;

  isLoadingUserList: boolean;
  didUserListLoaded: Array<any>;
  loadUserListError: string;

  isDeletingUser: boolean;
  didDeleteUser: boolean;
  deleteUserError: string; 

};

const INITIAL_STATE: UserManagementState = {  
    isAddingUser: false,
    didAddUser: false,
    addUserError: "",

    isUpdateUser: false,
    didUpdatedUser: false,
    updateUserError: "",

    isAddingAdmin: false,
    didAddAdmin: false,
    addAdminError: "",

    isLoadingUserList: false,
    didUserListLoaded: [],
    loadUserListError: "",

    isDeletingUser: false,
    didDeleteUser: false,
    deleteUserError: "",
};

const userManagement = createSlice({
  name: "userManagement",
  initialState: INITIAL_STATE,
  reducers: {
    addUser: (state, _action: PayloadAction<any>) => {
      state.isAddingUser = true;
    },
    didAddedUser: (state) => {
      state.isAddingUser = false;
      state.didAddUser = true;
    },
    didFailToAddUser: (state, action: PayloadAction<any>) => {
      state.isAddingUser = false;
      state.addUserError = action.payload;
    },
    updateUser: (state, _action: PayloadAction<any>) => {
      state.isUpdateUser = true;
    },
    didUpdatedUser: (state) => {
      state.isUpdateUser = false;
      state.didUpdatedUser = true;
    },
    didFailToUpdateUser: (state, action: PayloadAction<any>) => {
      state.isUpdateUser = false;
      state.updateUserError = action.payload;
    },
    addAdmin: (state, _action: PayloadAction<any>) => {
      state.isAddingAdmin = true;
    },
    didAddedAdmin: (state) => {
      state.isAddingAdmin = false;
      state.didAddAdmin = true;
    },
    didFailToAddAdmin: (state, action: PayloadAction<any>) => {
      state.isAddingAdmin = false;
      state.addAdminError = action.payload;
    },
    userLists: (state, _action: PayloadAction<any>) => {
      state.isLoadingUserList = true;
    },
    didUserList: (state,action: PayloadAction<any>) => {
      state.isLoadingUserList = false;
      state.didUserListLoaded = action.payload;
    },
    didFailToLoadUserList: (state, action: PayloadAction<any>) => {
      state.isLoadingUserList = false;
      state.loadUserListError = action.payload;
    },
    deleteUser: (state, _action: PayloadAction<any>) =>{
      state.isDeletingUser = true;
    },
    didDeleteUser: (state) =>{
      state.isDeletingUser = false;
      state.didDeleteUser = true;
    },
    didFailToDeleteUser: (state, action: PayloadAction<any>) =>{
      state.isDeletingUser = false;
      state.deleteUserError = action.payload;
    },
    resetDeleteUser:(state)=>{
      state.isDeletingUser = false;
      state.didDeleteUser = false;
      state.deleteUserError="";
    },
    resetStatesUser: (state) => {
      state.isAddingUser = false;
      state.didAddUser = false;
      state.addUserError = "";
    }, 
    resetStatesAdmin: (state) => {
      state.isAddingAdmin = false;
      state.didAddAdmin = false;
      state.addAdminError = "";
    },  
    resetUpdateUser: (state) => {
      state.isUpdateUser = false;
      state.didUpdatedUser = false;
      state.updateUserError = "";
    }, 
    resetStatesUserList: (state) => {
      state.isLoadingUserList = false;
      state.loadUserListError = "";
    },
  },
});

export const {
  addUser,
  didAddedUser,
  didFailToAddUser,
  updateUser,
  didUpdatedUser,
  didFailToUpdateUser,
  userLists,
  didUserList,
  didFailToLoadUserList,
  deleteUser,
  didDeleteUser,
  didFailToDeleteUser,
  addAdmin,
  didAddedAdmin,
  didFailToAddAdmin,
  resetStatesUser,
  resetStatesUserList,
  resetDeleteUser,
  resetStatesAdmin,
  resetUpdateUser,
  } = userManagement.actions;
export default userManagement.reducer;
