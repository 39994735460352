import { all, takeEvery, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import { TDigitalPostersParams, TDigitalPosterDetailsParams } from "./types";
import ToastCenter from "utils/ToastCenter";

import Request from "networking/Request";
import {
  loadDigitalPosters,
  didLoadDigitalPosters,
  didFailToLoadDigitalPosters,
  deleteDigitalPosters,
  didDeleteDigitalPosters,
  didFailToDeleteDigitalPosters,
} from "./slice";

const DIGITAL_POSTERS_ENDPOINT = "v1/admin/digitalposter/fetchAll";
const DIGITAL_POSTERS_DELETE = "v1/admin/content/delete";

function* handleLoadDigitalPosters(
  action: PayloadAction<TDigitalPostersParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    DIGITAL_POSTERS_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadDigitalPosters(response.error));
  } else {
    yield put(didLoadDigitalPosters(response.data.data.resultList));
  }
}

function* handleDeleteDigitalPosters(
  action: PayloadAction<TDigitalPostersParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    DIGITAL_POSTERS_DELETE,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailToDeleteDigitalPosters(response?.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteDigitalPosters(response?.data?.resultInfo));
  }
}

function* watcherLoadDigitalPosters() {
  yield takeLatest(loadDigitalPosters.type, handleLoadDigitalPosters);
}

function* watcherDeleteDigitalPosters() {
  yield takeLatest(deleteDigitalPosters.type, handleDeleteDigitalPosters);
}

export function* digitalPosterSaga() {
  yield all([watcherLoadDigitalPosters(), watcherDeleteDigitalPosters()]);
}
