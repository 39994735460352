import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ImageDropzone from "components/ImageDropZone";
import { useRef } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import {
  uploadSalesRMList,
  resetUploadSalesRMListStates,
  loadSalesRMs,
  getCount,
  resetAddNewSalesRMStates,
  resetDeleteNewSalesRMStates,
  resetSalesRMNewEditStates,
  addNewSalesRM,
  deleteNewSalesRM,
  editNewSalesRM,
  swapNewSalesRM,
  resetSalesRMNewSwapStates,
} from "./store/slice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import Warning from "@mui/icons-material/Warning";
import Text from "components/Text";
const ExcelAddRm = () => {
  const dispatch = useAppDispatch();
  const {
    isUploadingSalesRMList,
    didUploadSalesRMList,
    uploadSalesRMListError,
    didAddNewSalesRM,
    addNewSalesRMError,

    didDeleteNewSalesRM,
    deleteNewSalesRMError,

    didSwapNewSalesRM,
    swapNewSalesRMError,

    didEditNewSalesRM,
    EditNewSalesRMError,
  } = useAppSelector((state) => state.salesRM);
  const imageDropzoneRef = useRef<any>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [bulkUploadDialog, setBulkUploadDialog] = useState(false);
  const [editDialog, setEditDialog] = useState(false);
  const [swapDialog, setSwapDialog] = useState(false);
  const [newUploadDialog, setNewUploadDialog] = useState(false);

  useEffect(() => {
    if (didUploadSalesRMList) {
      ToastCenter.success("Successfully added SalesRM");
      imageDropzoneRef.current.clearImage();

      dispatch(resetUploadSalesRMListStates());
      dispatch(getCount());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
    }
  }, [didUploadSalesRMList]);

  useEffect(() => {
    if (didAddNewSalesRM) {
      ToastCenter.success("Successfully added SalesRM");
      imageDropzoneRef.current.clearImage();
      dispatch(resetAddNewSalesRMStates());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
      dispatch(getCount());
    }
  }, [didAddNewSalesRM]);

  useEffect(() => {
    if (didDeleteNewSalesRM) {
      ToastCenter.success("Successfully deleted SalesRM");
      imageDropzoneRef.current.clearImage();
      dispatch(resetDeleteNewSalesRMStates());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
      dispatch(getCount());
    }
  }, [didDeleteNewSalesRM]);

  useEffect(() => {
    if (didEditNewSalesRM) {
      ToastCenter.success("Successfully updated SalesRM");
      imageDropzoneRef.current.clearImage();
      dispatch(resetSalesRMNewEditStates());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
      dispatch(getCount());
    }
  }, [didEditNewSalesRM]);

  useEffect(() => {
    if (didSwapNewSalesRM) {
      ToastCenter.success("Successfully updated SalesRM");
      imageDropzoneRef.current.clearImage();
      dispatch(resetSalesRMNewSwapStates());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
      dispatch(getCount());
    }
  }, [didSwapNewSalesRM]);

  const handleUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    setBulkUploadDialog(true);
  };
  const handleNewUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    // dispatch(addNewSalesRM({ file: data }));
    setNewUploadDialog(true);
  };
  const handleEditUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    // dispatch(editNewSalesRM({ file: data }));
    setEditDialog(true);
  };
  const handleSwapUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    // dispatch(editNewSalesRM({ file: data }));
    setSwapDialog(true);
  };
  const handleDeleteUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    setDeleteDialog(true);
  };
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const handleBulkUploadClose = () => {
    setBulkUploadDialog(false);
  };
  const handleEditClose = () => {
    setEditDialog(false);
  };
  const handleSwapClose = () => {
    setSwapDialog(false);
  };
  const handleNewUploadClose = () => {
    setNewUploadDialog(false);
  };
  const handleDeleteConfirm = () => {
    setDeleteDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(deleteNewSalesRM({ file: data }));
  };
  const handleBulkUploadConfirm = () => {
    setBulkUploadDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(uploadSalesRMList({ file: data }));
  };
  const handleEditConfirm = () => {
    setEditDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(editNewSalesRM({ file: data }));
  };
  const handleSwapConfirm = () => {
    setSwapDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(swapNewSalesRM({ file: data }));
  };
  const handleNewUploadConfirm = () => {
    setNewUploadDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(addNewSalesRM({ file: data }));
  };
  const handleImageDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    // setValue('image', { imageData: imageData, name: name });
  };
  return (
    <Box sx={{ mt: "24px" }}>
      <Box
        sx={{
          // mt: "40px",
          mb: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
        <Text type="medium" sx={{ color: colors.red }}>
          Warning : Please do not upload/edit/delete Sales RM during business
          hours (9 AM - 7 PM).{" "}
        </Text>
      </Box>

      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneRef}
        heading=""
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Excel"
      />
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleNewUpload}
        >
          Add New Data
        </Button>
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleEditUpload}
        >
          Edit Data
        </Button> */}
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleSwapUpload}
        >
          Add/Update Data
        </Button>
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDeleteUpload}
        >
          Delete Data
        </Button>
        {/* <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleUpload}
        >
          Bulk Upload Data
        </Button> */}
      </Box>
      {/* <Box
        sx={{
          mt: "40px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
        <Text type="medium" sx={{ color: colors.red }}>
          Warning : Please do not upload/edit/delete Sales RM during business
          hours (9 AM - 5 PM).{" "}
        </Text>
      </Box> */}
      <Box
        sx={{
          mt: "40px",
          // display: "flex",
          // flexDirection: "row",

          alignItems: "center",
        }}
      >
        <Text
          type="largeBold"
          sx={{
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "22px",
            fontFamily: "Graphik",
            textDecoration: "underline",
            fontWeight: 600,
          }}
        >
          Note:
        </Text>

        <Text
          type="medium"
          sx={{
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "25px",
            fontFamily: "Graphik",
            mt: "5px",
          }}
        >
          <b>1. Add/Update/Delete - </b>SalesRM Email, SalesRM Mobile and MFD
          ARN is mandatory while uploading SalesRM sheet.
        </Text>

        {/* <Text
          type="medium"
          sx={{
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "25px",
            fontFamily: "Graphik",
            mt: "5px",
          }}
        >
          <b>2. Edit - </b>SalesRM Email and MFD ARN cannot be edited. If it is
          required then Delete and Add.
        </Text> */}

        <Text
          type="medium"
          sx={{
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "25px",
            fontFamily: "Graphik",
            mt: "5px",
          }}
        >
          <b>2. Lead Mapping -</b> Once uploaded SalesRM sheet successfully.
          Please upload Lead sheet.
        </Text>
      </Box>
      <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete?</DialogTitle>
        <DialogContent>
          This action will permanently delete the data.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={() => handleDeleteConfirm()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={bulkUploadDialog}>
        <DialogTitle>Confirm upload?</DialogTitle>
        <DialogContent>
          This action will permanently replace existing data with uploaded data.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleBulkUploadClose}>Cancel</Button>
          <Button onClick={() => handleBulkUploadConfirm()} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={editDialog}>
        <DialogTitle>Confirm edit?</DialogTitle>
        <DialogContent>
          This action will edit the existing records.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose}>Cancel</Button>
          <Button onClick={() => handleEditConfirm()} autoFocus>
            Edit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={swapDialog}>
        <DialogTitle>Confirm Add/Update?</DialogTitle>
        <DialogContent>This action will add/update the data.</DialogContent>
        <DialogActions>
          <Button onClick={handleSwapClose}>Cancel</Button>
          <Button onClick={() => handleSwapConfirm()} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={newUploadDialog}>
        <DialogTitle>Confirm upload?</DialogTitle>
        <DialogContent>This action will add new records.</DialogContent>
        <DialogActions>
          <Button onClick={handleNewUploadClose}>Cancel</Button>
          <Button onClick={() => handleNewUploadConfirm()} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExcelAddRm;
