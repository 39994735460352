import { Box, Button } from "@mui/material";
import React from "react";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/styles";
import colors from "res/colors";
import Text from "components/Text";
import {
  resendOtp,
  validateOtp,
  resetResendOtpStates,
  resetValidOtpStates,
} from "./store/slice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect } from "react";

interface OtpProps {
  email: string;
}

const OtpForm: React.FC<OtpProps> = ({ email }) => {
  // const classes = useStyles();
  const dispatch = useAppDispatch();
  const [otp, setOtp] = React.useState("");
  const [otpError, setOtpError] = React.useState("");

  const {
    isValidateOtp,
    validateOtpError,
    didValidateOtp,
    generateOtpData,
    newOtp,
  } = useAppSelector((state) => state.login);

  useEffect(() => {
    return () => {
      dispatch(resetResendOtpStates());
      dispatch(resetValidOtpStates());
    };
  }, []);

  useEffect(() => {
    if (didValidateOtp) {
    }
  }, [didValidateOtp]);

  const handleLogin = () => {
    if (!otp || otp.length < 5) {
      setOtpError("Please enter a valid OTP");
      return;
    }
    const valid_otp = {
      referenceId: generateOtpData.referenceId,
      otp: otp,
      emailId: email,
      source: "W",
    };

    dispatch(validateOtp(valid_otp));
  };

  const handleResendOtp = () => {
    //
    const otp_request = {
      emailId: email,
      referenceId: generateOtpData.referenceId,
      source: "W",
    };

    dispatch(resendOtp(otp_request));
  };

  const handleChange = (event: string) => {
    if (event && event.length === 5) {
      setOtpError("");
    }
    setOtp(event);
  };

  const showError = Boolean(otpError);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: "50px",
      }}
    >
      <Text type="large">
        We have sent the 5 digit OTP to your registered email
      </Text>
      <Text type="large">{email}</Text>
      <OtpInput
        value={otp}
        numInputs={5}
        isInputNum
        containerStyle={{
          height: "36px",
          marginTop: "35px",
          marginBottom: "35px",
          // backgroundColor: "red",
          width: "300px",
        }}
        separator={<span style={{ paddingLeft: "20px" }}></span>}
        inputStyle={{
          borderTopWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          fontSize: "35px",
          // width: 65,
          // height: 25,
          // backgroundColor: "green",
        }}
        focusStyle={{ outline: "none" }}
        // separator={<Box sx={classes.otpSpacing}></Box>}
        onChange={handleChange}
        shouldAutoFocus
      />
      {showError ? (
        <Box sx={{ marginTop: "-20px", marginBottom: "15px" }}>
          <Text type="medium" color="red">
            {otpError}
          </Text>
        </Box>
      ) : null}
      <Text type="smallTransparent">
        Don't Recieve the OTP?
        <Button
          sx={{ color: colors.backGroundBlue }}
          variant="text"
          onClick={handleResendOtp}
        >
          Resend Otp
        </Button>
      </Text>
      <Text type="mediumBold" sx={{ mt: "20px", color: "red" }}>
        {validateOtpError}
      </Text>

      <Button
        type="submit"
        variant="contained"
        onClick={handleLogin}
        disabled={isValidateOtp}
        sx={{
          backgroundColor: colors.backGroundBlue,
          height: "56px",
          width: "160px",
          mt: "32px",
        }}
      >
        Login
      </Button>
      {/* <Text type="mediumBold" sx={{ mt: "20px", color: "red" }}>
        {newOtp || generateOtpData.otp}
      </Text> */}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: "50px",
  },
  otpContainerStyle: {
    height: "36px",
    marginTop: "35px",
    marginBottom: "35px",
    // backgroundColor: "red",
    width: "300px",
  },
  otpInputStyle: {
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    fontSize: "35px",
    // width: 65,
    // height: 25,
    // backgroundColor: "green",
  },
  otpFocusStyle: { outline: "none" },
  otpSpacing: { paddingLeft: "20px" },
});

export default OtpForm;
