import * as React from "react";
import Text from "components/Text";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { DateInputProps } from "./types";
const DateInput: React.FC<DateInputProps> = ({
  label,
  error,
  onChange,
  preValue = null,
}) => {
  const [value, setValue] = React.useState<Date | null>(preValue);

  const handleChange = (newValue: Date | null) => {
    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  };

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          label={label}
          inputFormat="MM/dd/yyyy"
          value={value}
          onChange={handleChange}
          renderInput={(params: any) => (
            <TextField {...params} variant="filled" fullWidth disabled />
          )}
        />
      </LocalizationProvider>
      <Text type="error">{error}</Text>
    </>
  );
};

export default DateInput;
