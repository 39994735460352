import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  uploadSalesRMList,
  didUploadSalesRMList,
  didFailToUploadSalesRMList,
  addSalesRM,
  didAddSalesRM,
  didFailToAddSalesRM,
  deleteSalesRM,
  didDeleteSalesRM,
  didFailToDeleteSalesRM,
  loadSalesRMs,
  didFailToLoadSalesRMs,
  didLoadSalesRMs,
  findSalesRM,
  didFindSalesRM,
  didFailToFindSalesRM,
  updateSalesRM,
  didUpdateSalesRM,
  didFailToUpdateSalesRM,
  didFailToGetCount,
  didGetCount,
  getCount,
  addNewSalesRM,
  didAddNewSalesRM,
  didFailToAddNewSalesRM,
  deleteNewSalesRM,
  didDeleteNewSalesRM,
  didFailToDeleteNewSalesRM,
  didEditNewSalesRM,
  didFailToEditNewSalesRM,
  editNewSalesRM,
  didFailToSwapNewSalesRM,
  didSwapNewSalesRM,
  swapNewSalesRM,
} from "./slice";
import ToastCenter from "utils/ToastCenter";

const UPLOAD_ENDPOINT = "v1/admin/content/importRMList";
const ADD_ENDPOINT = "v1/admin/add/salesRM";
const DELETE_ENDPOINT = "v1/admin/delete/salesRM";
const SALESRM_LIST_ENDPOINT = "v1/admin/rm/findRMListByPAge";
const SEARCH_ENDPOINT = "v1/admin/rm/findRM";
const UPDATE_ENDPOINT = "v1/admin/update/salesRM";
const COUNT_ENDPOINT = "v1/admin/content/getRmLeadAndMfdCount";
const EDIT_NEW_ENDPOINT = "v1/admin/content/editRMList";
const DELETE_NEW_ENDPOINT = "v1/admin/content/deleteRMList";
const ADD_NEW_ENDPOINT = "v1/admin/content/importNewRMList";
const SWAP_NEW_ENDPOINT = "v1/admin/content/editRmMfdMapping";
// find sales rm
// update sales rm
function* handleExcelUpload(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    UPLOAD_ENDPOINT,
    params
  );
  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to upload content."
    );
    yield put(didFailToUploadSalesRMList(response.error));
  } else {
    yield put(didUploadSalesRMList());
  }
}

function* handleAdd(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(Request.POST, ADD_ENDPOINT, params);

  if (response.error) {
    yield put(didFailToAddSalesRM(response.error));
  } else {
    yield put(didAddSalesRM());
  }
}

function* handleDelete(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(Request.POST, DELETE_ENDPOINT, params);
  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to delete content."
    );
    yield put(didFailToDeleteSalesRM(response.error));
  } else {
    yield put(didDeleteSalesRM());
  }
}

function* handleLoadSalesRMs(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    SALESRM_LIST_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToLoadSalesRMs(response.error));
  } else {
    yield put(didLoadSalesRMs(response.data.data.salesRMResponseList));
  }
}

function* handleFindSalesRM(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(Request.POST, SEARCH_ENDPOINT, params);
  if (response.error) {
    yield put(didFailToFindSalesRM(response.error));
  } else {
    yield put(didFindSalesRM(response.data.data));
  }
}

function* handleUpdateSalesRM(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(Request.POST, UPDATE_ENDPOINT, params);
  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to update content."
    );
    yield put(didFailToUpdateSalesRM(response.error));
  } else {
    yield put(didUpdateSalesRM());
  }
}
function* handleGetCount() {
  // const params = {
  //   ...action.payload,
  // };

  const response: TResponse = yield call(Request.GET, COUNT_ENDPOINT);
  if (response.error) {
    yield put(didFailToGetCount(response.error));
  } else {
    yield put(didGetCount(response?.data?.data));
  }
}

function* handleNewAdd(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    ADD_NEW_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to upload content."
    );
    yield put(didFailToAddNewSalesRM(response.error));
  } else {
    yield put(didAddNewSalesRM());
  }
}
function* handleNewEdit(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    EDIT_NEW_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to update content."
    );
    yield put(didFailToEditNewSalesRM(response.error));
  } else {
    yield put(didEditNewSalesRM());
  }
}
function* handleNewSwap(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };
  const response: TResponse = yield call(
    Request.uploadExcel,
    SWAP_NEW_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to update content."
    );
    yield put(didFailToSwapNewSalesRM(response.error));
  } else {
    yield put(didSwapNewSalesRM());
  }
}
function* handleNewDelete(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    DELETE_NEW_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to delete content."
    );
    yield put(didFailToDeleteNewSalesRM(response.error));
  } else {
    yield put(didDeleteNewSalesRM());
  }
}

function* watcherUpload() {
  yield takeLatest(uploadSalesRMList.type, handleExcelUpload);
}
function* watcherAdd() {
  yield takeLatest(addSalesRM.type, handleAdd);
}
function* watcherDelete() {
  yield takeLatest(deleteSalesRM.type, handleDelete);
}

function* watcherLoadSalesRMs() {
  yield takeLatest(loadSalesRMs.type, handleLoadSalesRMs);
}

function* watcherFindSalesRM() {
  yield takeLatest(findSalesRM.type, handleFindSalesRM);
}

function* watcherUpdateSalesRM() {
  yield takeLatest(updateSalesRM.type, handleUpdateSalesRM);
}
function* watcherGetCount() {
  yield takeLatest(getCount.type, handleGetCount);
}
function* watcherNewAdd() {
  yield takeLatest(addNewSalesRM.type, handleNewAdd);
}
function* watcherNewDelete() {
  yield takeLatest(deleteNewSalesRM.type, handleNewDelete);
}
function* watcherNewEditSalesRM() {
  yield takeLatest(editNewSalesRM.type, handleNewEdit);
}
function* watcherNewSwapSalesRM() {
  yield takeLatest(swapNewSalesRM.type, handleNewSwap);
}
export function* salesRMSaga() {
  yield all([
    watcherNewSwapSalesRM(),
    watcherNewAdd(),
    watcherNewDelete(),
    watcherNewEditSalesRM(),
    watcherGetCount(),
    watcherUpload(),
    watcherAdd(),
    watcherDelete(),
    watcherLoadSalesRMs(),
    watcherFindSalesRM(),
    watcherUpdateSalesRM(),
  ]);
}
