import React from "react";
import TextField from "@mui/material/TextField";
import colors from "res/colors";

import { InputProps } from "./types";

const Input: React.FC<InputProps> = ({
  error,
  variant = "filled",
  placeholder = "",
  label = "",
  ...textInputProps
}) => {
  const showError = Boolean(error);
  return (
    <TextField
      fullWidth
      error={showError}
      label={label}
      helperText={error}
      variant={variant}
      {...textInputProps}
    />
  );
};

export default Input;
