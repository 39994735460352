import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useImperativeHandle,
  ForwardRefRenderFunction,
} from "react";
import { useDropzone } from "react-dropzone";
import { ImageDropzonProps, ImageDropzonHandle } from "./types";

import { Box } from "@mui/system";
import { Button } from "@mui/material";

const baseStyle = {
  display: "flex",
  borderWidth: 2,
  borderRadius: "10px",
  borderStyle: "dashed",
  borderColor: "#A6D3D6",
  backgroundColor: "#edfeff",
  color: "#bdbdbd",
  transition: "border .3s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ImageDropZone: ForwardRefRenderFunction<
  ImageDropzonHandle,
  ImageDropzonProps
> = ({ heading = "", onDropped, defaultImage = "", error = "" }, ref) => {
  const [files, setFiles] = useState<Array<{ url: string; name: string }>>([]);

  useImperativeHandle(ref, () => ({
    getImage() {
      if (files.length < 1) {
        return null;
      }
      return files;
    },
    clearImage() {
      setFiles([]);
    },
  }));

  const onDrop = useCallback((acceptedFiles) => {
    const _imgData = acceptedFiles.map((file: any) => {
      return Object.assign(file, {
        url: URL.createObjectURL(file),
      });
    });
    setFiles(_imgData);
    if (onDropped != undefined && _imgData.length > 0) {
      onDropped(_imgData);
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: "image/jpeg, image/png",
    maxFiles: 5,
    // maxSize:10000
  });

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  // clean up
  useEffect(
    () => () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  const renderThumbs = () => {
    if (files.length > 0)
      return (
        <div>
          <Box sx={{ pb: "16px", mt: "10px" }}>
            <div>Preview</div>
          </Box>
          <div style={{ display: "flex", flexDirection: "row" }}>
            {files.map((file: any) => (
              <img
                src={file.url}
                style={{ width: "150px", height: "150px" }}
                alt={file.name}
              />
            ))}
          </div>
        </div>
      );

    if (defaultImage)
      return (
        <div>
          <Box sx={{ pb: "16px", mt: "10px" }}></Box>
          <div>Preview</div>
          <img
            src={defaultImage}
            style={{ width: "150px", height: "150px" }}
            alt={"default"}
          />
        </div>
      );
    return null;
  };

  return (
    <section>
      <Box sx={{ pb: "16px" }}>
        <div>{heading}</div>
      </Box>
      <div {...getRootProps({ style })}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            py: "24px",
            pl: "32px",

            width: "100%",
          }}
        >
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                ml: "24px",
              }}
            >
              <Box>
                <div>
                  <Choose />
                  or drag it here
                </div>
              </Box>

              <div>Max. Image size 1 mb | Resolution 780w X 1270h </div>
            </Box>
          </div>
        </Box>

        <input {...getInputProps()} />
      </div>
      <aside>{renderThumbs()}</aside>
      <div>{error}</div>
    </section>
  );
};
export default React.forwardRef(ImageDropZone);

const Choose = () => {
  return (
    <Button
      variant="text"
      sx={{
        fontFamily: "Graphik",
        fontSize: "18px",
        lineHeight: "20px",
        fontWeight: 500,
        pr: 1,
        pl: 0,
        color: "#000AD2",
      }}
    >
      Choose
    </Button>
  );
};
