import { Box, Button, Card, Divider } from "@mui/material";
import ActivityOverlay from "components/ActivityOverlay";
import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import AddSchemeBanner from "pages/BannerManagement/AddSchemeBanner/AddSchemeBanner";
import { useEffect, useState } from "react";
import AddLoginBanner from "./AddLoginBanner/AddLoginBanner";
import LoginBannerContents from "./AddLoginBanner/UpdateLoginBannerSection/LoginBannerContents";
import SchemeBannerContents from "./AddSchemeBanner/UpdateSchemeBannerSection/SchemeBannerContents";
import UploadLoginBanner from "./UploadLoginBanner/UploadLoginBanner";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { loadSchemeNames } from "pages/SchemeManagement/store/slice";
const BannerManagement = () => {
  const dispatch = useAppDispatch();
  const [option, setOption] = useState("Add Login Banner");
  const {
    isUploadingLoginBanner,
    isUpdateLoginBanner,
    isLoadingLoginBanner,
    isLoadingSchemeBanner,
    isDeleteLoginBanner,
    isUploadingSchemeBanner,
    isUpdateSchemeBanner,
    isDeleteSchemeBanner,
  } = useAppSelector((state) => state.bannerManagement);
  const handleEventClick = (displayComponent: string) => {
    setOption(displayComponent);
  };

  useEffect(() => {
    dispatch(loadSchemeNames());
  }, []);

  const renderContent = () => {
    if (option === "Add Login Banner") {
      return <AddLoginBanner />;
    } else if (option === "Add Scheme Banner") {
      return <AddSchemeBanner />;
    }
  };
  const renderBannerContents = () => {
    if (option === "Add Login Banner") {
      return <LoginBannerContents />;
    } else if (option === "Add Scheme Banner") {
      return <SchemeBannerContents />;
    }
  };
  const addSalesRmOptions = [
    { label: "Add Login Banner" },
    { label: "Add Scheme Banner" },
  ];
  return (
    <Box
      sx={{ minHeight: "vh", width: "65%", ml: "20%", pt: "50px", pb: "50px" }}
    >
      <Text type="headingBold">Banner Management</Text>
      <Card
        sx={{
          px: "40px",
          py: "40px",
          mt: "20px",
          mb: "48px",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ mt: "16px" }}>
          <ToggleOption
            options={addSalesRmOptions}
            onClick={handleEventClick}
            selected={option}
          />
        </Box>
        {renderContent()}
        {/* <Text type="largeBold">Select Banner Count</Text> */}
        {/* <UploadLoginBanner /> */}
      </Card>
      {renderBannerContents()}
      {isUploadingLoginBanner ||
      isUpdateLoginBanner ||
      isLoadingSchemeBanner ||
      isLoadingLoginBanner ||
      isDeleteLoginBanner ||
      isUploadingSchemeBanner ||
      isUpdateSchemeBanner ||
      isDeleteSchemeBanner ? (
        <ActivityOverlay />
      ) : null}
    </Box>
  );
};

export default BannerManagement;
