import Container from "@mui/material/Container";

import SideMenu from "./SideMenu";
import DashBoardRoutes from "./DashBoardRoutes";

import { Box } from "@mui/material";

const DashBoard = () => {
  return (
    <>
      <SideMenu />
      <DashBoardRoutes />
    </>
  );
};

export default DashBoard;
