import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SalesRMState = {
  isUploadingSalesRMList: boolean;
  uploadSalesRMListError: string;
  didUploadSalesRMList: boolean;

  isAddingSalesRM: boolean;
  addSalesRMError: string;
  didAdddSalesRM: boolean;

  isAddingNewSalesRM: boolean;
  addNewSalesRMError: string;
  didAddNewSalesRM: boolean;

  isDeletingSalesRM: boolean;
  didDeleteSalesRM: boolean;
  deleteSalesRMError: string;

  isDeletingNewSalesRM: boolean;
  didDeleteNewSalesRM: boolean;
  deleteNewSalesRMError: string;

  isLoadingSalesRMs: boolean;
  salesRMsLoadingError: string;
  salesRMs: Array<any>;

  isUpdatingSalesRM: boolean;
  didUpdateSalesRM: boolean;
  updateSalesRMError: string;

  isEditingNewSalesRM: boolean;
  didEditNewSalesRM: boolean;
  EditNewSalesRMError: string;

  isSwapingNewSalesRM: boolean;
  didSwapNewSalesRM: boolean;
  swapNewSalesRMError: string;

  isSearchingSalesRM: boolean;
  searchSalesRMError: string;
  searchSalesRMResult: Array<any>;

  isCountLoading: boolean;
  countData: any;
  countError: string;
};

const INITIAL_STATE: SalesRMState = {
  isUploadingSalesRMList: false,
  uploadSalesRMListError: "",
  didUploadSalesRMList: false,

  isAddingSalesRM: false,
  addSalesRMError: "",
  didAdddSalesRM: false,

  isAddingNewSalesRM: false,
  addNewSalesRMError: "",
  didAddNewSalesRM: false,

  isDeletingSalesRM: false,
  didDeleteSalesRM: false,
  deleteSalesRMError: "",

  isDeletingNewSalesRM: false,
  didDeleteNewSalesRM: false,
  deleteNewSalesRMError: "",

  isLoadingSalesRMs: false,
  salesRMsLoadingError: "",
  salesRMs: [],

  isUpdatingSalesRM: false,
  didUpdateSalesRM: false,
  updateSalesRMError: "",

  isEditingNewSalesRM: false,
  didEditNewSalesRM: false,
  EditNewSalesRMError: "",

  isSwapingNewSalesRM: false,
  didSwapNewSalesRM: false,
  swapNewSalesRMError: "",

  isSearchingSalesRM: false,
  searchSalesRMError: "",
  searchSalesRMResult: [],

  isCountLoading: false,
  countData: {},
  countError: "",
};

const salesRM = createSlice({
  name: "salesRM",
  initialState: INITIAL_STATE,
  reducers: {
    uploadSalesRMList: (state, _action: PayloadAction<any>) => {
      state.isUploadingSalesRMList = true;
    },
    didUploadSalesRMList: (state) => {
      state.isUploadingSalesRMList = false;
      state.didUploadSalesRMList = true;
    },
    didFailToUploadSalesRMList: (state, action: PayloadAction<any>) => {
      state.isUploadingSalesRMList = false;
      state.uploadSalesRMListError = action.payload;
    },

    addSalesRM: (state, _action: PayloadAction<any>) => {
      state.isAddingSalesRM = true;
    },
    didAddSalesRM: (state) => {
      state.isAddingSalesRM = false;
      state.didAdddSalesRM = true;
    },
    didFailToAddSalesRM: (state, action: PayloadAction<any>) => {
      state.isAddingSalesRM = false;
      state.addSalesRMError = action.payload;
    },
    addNewSalesRM: (state, _action: PayloadAction<any>) => {
      state.isAddingNewSalesRM = true;
    },
    didAddNewSalesRM: (state) => {
      state.isAddingNewSalesRM = false;
      state.didAddNewSalesRM = true;
    },
    didFailToAddNewSalesRM: (state, action: PayloadAction<any>) => {
      state.isAddingNewSalesRM = false;
      state.addNewSalesRMError = action.payload;
    },

    deleteSalesRM: (state, _action: PayloadAction<any>) => {
      state.isDeletingSalesRM = true;
    },

    didDeleteSalesRM: (state) => {
      state.isDeletingSalesRM = false;
      state.didDeleteSalesRM = true;
    },
    didFailToDeleteSalesRM: (state, action: PayloadAction<any>) => {
      state.isDeletingSalesRM = false;
      state.deleteSalesRMError = action.payload;
    },

    deleteNewSalesRM: (state, _action: PayloadAction<any>) => {
      state.isDeletingNewSalesRM = true;
    },

    didDeleteNewSalesRM: (state) => {
      state.isDeletingNewSalesRM = false;
      state.didDeleteNewSalesRM = true;
    },
    didFailToDeleteNewSalesRM: (state, action: PayloadAction<any>) => {
      state.isDeletingNewSalesRM = false;
      state.deleteNewSalesRMError = action.payload;
    },

    loadSalesRMs: (state, _action: PayloadAction<any>) => {
      state.isLoadingSalesRMs = true;
      state.salesRMs = [];
      state.salesRMsLoadingError = "";
    },
    didFailToLoadSalesRMs: (state, _action: PayloadAction<any>) => {
      state.isLoadingSalesRMs = false;
      state.salesRMsLoadingError = _action.payload;
      state.salesRMs = [];
    },
    didLoadSalesRMs: (state, action: PayloadAction<any>) => {
      state.isLoadingSalesRMs = false;
      state.salesRMs = action.payload;
    },

    updateSalesRM: (state, _action: PayloadAction<any>) => {
      state.isUpdatingSalesRM = true;
    },
    didUpdateSalesRM: (state) => {
      state.isUpdatingSalesRM = false;
      state.didUpdateSalesRM = true;
    },
    didFailToUpdateSalesRM: (state, action: PayloadAction<string>) => {
      state.isUpdatingSalesRM = false;
      state.updateSalesRMError = action.payload;
    },

    editNewSalesRM: (state, _action: PayloadAction<any>) => {
      state.isEditingNewSalesRM = true;
    },
    didEditNewSalesRM: (state) => {
      state.isEditingNewSalesRM = false;
      state.didEditNewSalesRM = true;
    },
    didFailToEditNewSalesRM: (state, action: PayloadAction<string>) => {
      state.isEditingNewSalesRM = false;
      state.EditNewSalesRMError = action.payload;
    },

    swapNewSalesRM: (state, _action: PayloadAction<any>) => {
      state.isSwapingNewSalesRM = true;
    },
    didSwapNewSalesRM: (state) => {
      state.isSwapingNewSalesRM = false;
      state.didSwapNewSalesRM = true;
    },
    didFailToSwapNewSalesRM: (state, action: PayloadAction<string>) => {
      state.isSwapingNewSalesRM = false;
      state.swapNewSalesRMError = action.payload;
    },

    findSalesRM: (state, _action: PayloadAction<any>) => {
      state.isSearchingSalesRM = true;
      state.searchSalesRMResult = [];
      state.searchSalesRMError = "";
    },
    didFindSalesRM: (state, action: PayloadAction<any>) => {
      state.isSearchingSalesRM = false;
      state.searchSalesRMResult = action.payload;
    },
    didFailToFindSalesRM: (state, action: PayloadAction<string>) => {
      state.isSearchingSalesRM = false;
      state.searchSalesRMError = action.payload;
    },
    getCount: (state) => {
      state.isCountLoading = true;
      state.countData = {};
      state.countError = "";
    },
    didGetCount: (state, action: PayloadAction<any>) => {
      state.isCountLoading = true;
      state.countData = action.payload;
    },
    didFailToGetCount: (state, action: PayloadAction<string>) => {
      state.isCountLoading = true;
      state.countError = action.payload;
    },

    resetUploadSalesRMListStates: (state) => {
      state.isUploadingSalesRMList = false;
      state.didUploadSalesRMList = false;
      state.uploadSalesRMListError = "";
    },
    resetAddSalesRMStates: (state) => {
      state.isAddingSalesRM = false;
      state.didAdddSalesRM = false;
      state.addSalesRMError = "";
    },
    resetDeleteSalesRMStates: (state) => {
      state.isDeletingSalesRM = false;
      state.didDeleteSalesRM = false;
      state.deleteSalesRMError = "";
    },
    resetSalesRMUpdateStates: (state) => {
      state.isUpdatingSalesRM = false;
      state.didUpdateSalesRM = false;
      state.updateSalesRMError = "";
    },
    resetSalesRMSearch: (state) => {
      state.isSearchingSalesRM = false;
      state.searchSalesRMResult = [];
      state.searchSalesRMError = "";
    },
    resetAddNewSalesRMStates: (state) => {
      state.isAddingNewSalesRM = false;
      state.didAddNewSalesRM = false;
      state.addNewSalesRMError = "";
    },
    resetDeleteNewSalesRMStates: (state) => {
      state.isDeletingNewSalesRM = false;
      state.didDeleteNewSalesRM = false;
      state.deleteNewSalesRMError = "";
    },
    resetSalesRMNewEditStates: (state) => {
      state.isEditingNewSalesRM = false;
      state.didEditNewSalesRM = false;
      state.EditNewSalesRMError = "";
    },
    resetSalesRMNewSwapStates: (state) => {
      state.isSwapingNewSalesRM = false;
      state.didSwapNewSalesRM = false;
      state.swapNewSalesRMError = "";
    },
  },
});

export const {
  resetSalesRMNewSwapStates,
  swapNewSalesRM,
  didSwapNewSalesRM,
  didFailToSwapNewSalesRM,
  resetAddNewSalesRMStates,
  resetDeleteNewSalesRMStates,
  resetSalesRMNewEditStates,
  editNewSalesRM,
  didEditNewSalesRM,
  didFailToEditNewSalesRM,
  deleteNewSalesRM,
  didDeleteNewSalesRM,
  didFailToDeleteNewSalesRM,
  addNewSalesRM,
  didAddNewSalesRM,
  didFailToAddNewSalesRM,
  getCount,
  didGetCount,
  didFailToGetCount,
  resetSalesRMSearch,
  uploadSalesRMList,
  didUploadSalesRMList,
  didFailToUploadSalesRMList,
  addSalesRM,
  didAddSalesRM,
  didFailToAddSalesRM,
  deleteSalesRM,
  didDeleteSalesRM,
  didFailToDeleteSalesRM,
  resetUploadSalesRMListStates,
  resetAddSalesRMStates,
  resetDeleteSalesRMStates,
  loadSalesRMs,
  didFailToLoadSalesRMs,
  didLoadSalesRMs,
  findSalesRM,
  didFindSalesRM,
  didFailToFindSalesRM,
  updateSalesRM,
  didUpdateSalesRM,
  didFailToUpdateSalesRM,
  resetSalesRMUpdateStates,
} = salesRM.actions;
export default salesRM.reducer;
