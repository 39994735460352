import * as yup from "yup";
export const contentValidationSchema = yup.object().shape({
  eventName: yup.string().min(5).required("Please enter a valid title"),
  about: yup.string().min(5).required("Please enter a valid about"),
  whatsInIt: yup.string().min(5).required("Please enter a valid whatsInIt"),
  //   date: yup.string().min(5).required("Please enter a valid date"),
  //   startTime: yup.string().min(5).required("Please enter a valid startTime"),
  //   endTime: yup.string().min(5).required("Please enter a valid endTime"),
  totalSeats: yup.string().min(1).required("Please enter a valid totalSeats"),

  eventLink: yup.string().min(5).required("Please enter a valid eventLink"),
  // password: yup.string().min(3).required("Please enter a valid password"),

  eventBy: yup.string().min(5).required("Please enter a valid eventBy"),
  organizerEmail: yup
    .string()
    .min(5)
    .required("Please enter a valid organizerEmail"),
  designation: yup.string().min(2).required("Please enter a valid designation"),

  learners: yup.string().min(1).required("Please enter a valid learners"),
  courses: yup.string().min(1).required("Please enter a valid courses"),
  education: yup.string().min(1).required("Please enter a valid education"),
  experience: yup.string().min(1).required("Please enter a valid experience"),

  eventDate: yup.string().min(1).required("Please enter a valid event date"),
  eventStartTime: yup
    .string()
    .min(1)
    .required("Please enter a valid event start time"),
  eventEndTime: yup
    .string()
    .min(1)
    .required("Please enter a valid event end time"),
  notificationTitle: yup.string().when("isShowNotification", {
    is: (val: boolean) => val === true,
    then: yup.string().min(3).max(1000).required("Please enter a valid title"),
  }),
  // notificationDescription: yup.string().when("isShowNotification", {
  //   is: (val: boolean) => val === true,
  //   then: yup.string().max(1000).required("Please enter a valid description"),
  // }),
  notificationShortDescription: yup.string().when("isShowNotification", {
    is: (val: boolean) => val === true,
    then: yup
      .string()
      .max(1000)
      .required("Please enter a valid short description"),
  }),
});
