import axios from "axios";
import { store } from "store";
import { setAppBaseURL } from "store/auth";
import { TResponse } from "types";
import Request from "./Request";
const INVESCO_TOKEN_ENDPOINT = "v1/admin/invesco/login";

export const setBaseURL = () => {
  // const baseURL = "https://invescoifa-dev.robosoftin.com/api/";
  // const baseURL = "https://mfpartneruat-admin.invescomutualfund.com/api/"; //dev
  // const baseURL = "https://mfpartner-admin.invescomutualfund.com/api/"; //new

  const baseURL = "https://mfpartneruat.invescomutualfund.com/api/"; //uat
  // const baseURL = "https://mfpartner.invescomutualfund.com/api/"; //Prod configured
  store.dispatch(setAppBaseURL(baseURL));
  axios.defaults.baseURL = baseURL;
};

let token: any = null;
export const setHeaderAccessToken = (_token: any) => {
  token = _token;
  axios.defaults.headers.common["access-token"] = token;
};
export const getAccessToken = () => token;

export const getInvescoToken = async () => {
  const invesoHeaderResponse: TResponse = await Request.POST(
    INVESCO_TOKEN_ENDPOINT,
    {}
  );
  if (invesoHeaderResponse && invesoHeaderResponse.headers) {
    setHeaderInvescoToken(invesoHeaderResponse.headers["invesco-token"]);
  }
};
export const setHeaderInvescoToken = (_token: any) => {
  axios.defaults.headers.common["invesco-token"] = _token;
};
