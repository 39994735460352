import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ImageDropzone from "components/ImageDropZone";
import { useRef } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import Warning from "@mui/icons-material/Warning";
import Text from "components/Text";
import {
  deleteSalesRMClusterHead,
  resetDeleteSalesRMClusterHeadStates,
  resetUploadSalesRMClusterHeadListStates,
  uploadSalesRMClusterHeadList,
} from "./store/slice";
const ExcelAddClusterHead = () => {
  const dispatch = useAppDispatch();
  const {
    didUploadSalesRMClusterHeadList,
    uploadSalesRMClusterHeadListError,
    didDeleteSalesRMClusterHead,
    deleteSalesRMClusterHeadError,
  } = useAppSelector((state) => state.salesRMClusterHead);

  const imageDropzoneRef = useRef<any>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [bulkUploadDialog, setBulkUploadDialog] = useState(false);

  useEffect(() => {
    if (didUploadSalesRMClusterHeadList) {
      ToastCenter.success("Successfully added SalesRM-Cluster Head");
      imageDropzoneRef.current.clearImage();

      dispatch(resetUploadSalesRMClusterHeadListStates());
    }
  }, [didUploadSalesRMClusterHeadList]);

  useEffect(() => {
    if (didDeleteSalesRMClusterHead) {
      ToastCenter.success("Successfully deleted SalesRM-Cluster Head");
      imageDropzoneRef.current.clearImage();
      dispatch(resetDeleteSalesRMClusterHeadStates());
    }
  }, [didDeleteSalesRMClusterHead]);

  const handleUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    setBulkUploadDialog(true);
  };

  const handleDeleteUpload = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    setDeleteDialog(true);
  };
  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const handleBulkUploadClose = () => {
    setBulkUploadDialog(false);
  };

  const handleDeleteConfirm = () => {
    setDeleteDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(deleteSalesRMClusterHead({ file: data }));
  };
  const handleBulkUploadConfirm = () => {
    setBulkUploadDialog(false);
    const clusterHeadFile = imageDropzoneRef.current.getImage();

    dispatch(uploadSalesRMClusterHeadList({ file: clusterHeadFile }));
  };

  const handleImageDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    // setValue('image', { imageData: imageData, name: name });
  };
  return (
    <Box sx={{ mt: "24px" }}>
      <Box
        sx={{
          // mt: "40px",
          mb: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
        <Text type="medium" sx={{ color: colors.red }}>
          Warning : Please do not upload/edit/delete during business hours (9 AM
          - 7 PM).
        </Text>
      </Box>
      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneRef}
        heading=""
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Excel"
      />
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleUpload}
        >
          Add/Update Data
        </Button>
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDeleteUpload}
        >
          Delete Data
        </Button>
      </Box>
      <Box
        sx={{
          mt: "40px",
          // display: "flex",
          // flexDirection: "row",

          alignItems: "center",
        }}
      >
        <Text
          type="largeBold"
          sx={{
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "22px",
            fontFamily: "Graphik",
            textDecoration: "underline",
            fontWeight: 600,
          }}
        >
          Note:
        </Text>

        <Text
          type="medium"
          sx={{
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "25px",
            fontFamily: "Graphik",
            mt: "5px",
          }}
        >
          <b>1. Lead Mapping -</b> Once uploaded the SalesRM Cluster Head data,
          Please re-upload Lead sheet.
        </Text>
      </Box>
      <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete?</DialogTitle>
        <DialogContent>
          This action will permanently delete the data.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose}>Cancel</Button>
          <Button onClick={() => handleDeleteConfirm()} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={bulkUploadDialog}>
        <DialogTitle>Confirm upload?</DialogTitle>
        <DialogContent>This action will add/update the data..</DialogContent>
        <DialogActions>
          <Button onClick={handleBulkUploadClose}>Cancel</Button>
          <Button onClick={() => handleBulkUploadConfirm()} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExcelAddClusterHead;
