import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SchemesState = {
  schemeNames: Array<any>;
};

const INITIAL_STATE: SchemesState = {
  schemeNames: [],
};

const shemes = createSlice({
  name: "shemes",
  initialState: INITIAL_STATE,
  reducers: {
    loadSchemeNames: (_state) => {},
    didLoadSchemeNames: (state, action: PayloadAction<Array<any>>) => {
      state.schemeNames = action.payload;
    },
  },
});

export const { loadSchemeNames, didLoadSchemeNames } = shemes.actions;

export default shemes.reducer;
