import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TInterviewVideosParams, TInterviewVideoDetailsParams } from "./types";

type InterviewVideosState = {
  isLodingInterviewVideos: boolean;
  interviewVideosError: string;
  interviewVideosData: Array<any>;

  isDeleteInterviewVideos: boolean;
  interviewVideosDeleteError: string;
  interviewVideosDeleted: string;
};

const INITIAL_STATE: InterviewVideosState = {
  isLodingInterviewVideos: false,
  interviewVideosError: "",
  interviewVideosData: [],

  isDeleteInterviewVideos: false,
  interviewVideosDeleteError: "",
  interviewVideosDeleted: "",
};

const interviewVideos = createSlice({
  name: "interviewVideos",
  initialState: INITIAL_STATE,
  reducers: {
    loadInterviewVideos: (
      state,
      _action: PayloadAction<TInterviewVideosParams>
    ) => {
      state.isLodingInterviewVideos = true;
      state.interviewVideosError = "";
      // state.interviewVideosData = [];
    },
    didLoadInterviewVideos: (state, action: PayloadAction<any>) => {
      state.isLodingInterviewVideos = false;
      state.interviewVideosError = "";
      state.interviewVideosData = action.payload;
    },
    didFailToLoadInterviewVideos: (state, action: PayloadAction<any>) => {
      state.isLodingInterviewVideos = false;
      state.interviewVideosError = action.payload;
    },
    deleteInterviewVideos: (state, action: PayloadAction<any>) => {
      state.isDeleteInterviewVideos = true;
      state.interviewVideosDeleteError = "";
      state.interviewVideosDeleted = "";
    },
    didDeleteInterviewVideos: (state, action: PayloadAction<any>) => {
      state.isDeleteInterviewVideos = false;
      state.interviewVideosDeleteError = "";
      state.interviewVideosDeleted = action.payload;
    },
    didFailedToDeleteInterviewVideos: (state, action: PayloadAction<any>) => {
      state.isDeleteInterviewVideos = false;
      state.interviewVideosDeleteError = action.payload;
    },
  },
});

export const {
  loadInterviewVideos,
  didLoadInterviewVideos,
  didFailToLoadInterviewVideos,
  deleteInterviewVideos,
  didDeleteInterviewVideos,
  didFailedToDeleteInterviewVideos,
} = interviewVideos.actions;
export default interviewVideos.reducer;
