import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import { TProductTraningParams, TProductTraningDetailsParams } from "./types";
import ToastCenter from "utils/ToastCenter";
import Request from "networking/Request";
import {
  loadProductTraining,
  didLoadProductTraining,
  didFailToLoadProductTraining,
  deleteProductTraining,
  didDeleteProductTraining,
  didFailedToDeleteProductTraining,
} from "./slice";

const PRODUCT_TRANING = "v1/admin/producttraining/fetchAll";
const PRODUCT_TRAINING_DELETE = "v1/admin/content/delete";

function* handleLoadProductTraining(
  action: PayloadAction<TProductTraningParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    PRODUCT_TRANING,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadProductTraining(response.error));
  } else {
    yield put(didLoadProductTraining(response.data.data.resultList));
  }

  //
  //
}

function* handleDeleteProductTraining(
  action: PayloadAction<TProductTraningParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    PRODUCT_TRAINING_DELETE,
    action.payload
  );

  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailedToDeleteProductTraining(response.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteProductTraining(action?.payload?.id));
  }
}

function* watcherLoadProductTraining() {
  yield takeLatest(loadProductTraining.type, handleLoadProductTraining);
}

function* watcherDeleteProductTraining() {
  yield takeLatest(deleteProductTraining.type, handleDeleteProductTraining);
}

export function* productTrainingMaterialSaga() {
  yield all([watcherLoadProductTraining(), watcherDeleteProductTraining()]);
}
