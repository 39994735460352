import { Box, Card, SelectChangeEvent } from "@mui/material";
import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import { useState, useEffect } from "react";

import Roles from "./Roles";
import UserList from "./UserList";
import Users from "./Users";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { userLists } from "./store/slice";
// import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { isLoadingUserList, didUserListLoaded, loadUserListError } =
    useAppSelector((state) => state.userManagement);

  useEffect(() => {
    dispatch(
      userLists({
        pageNumber: "0",
        size: "100",
      })
    );
  }, []);

  const [userManagementOption, setUserManagementOption] = useState("users");
  const [role, setRole] = useState("admin");
  const [items, setItems] = useState([]);
  const [itemName, setItemName] = useState("");

  const userManagementOptions = [{ label: "users" }, { label: "roles" }];

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value as string);
  };

  const handleClick = (displayComponent: string) => {
    setUserManagementOption(displayComponent);
  };

  const renderUserOption = () => {
    if (userManagementOption === "users") {
      return <Users />;
    }
    return <Roles />;
  };
  return (
    <Box sx={{ ml: "20%", minHeight: "vh", py: "50px", width: "65%" }}>
      <Text type="headingBold">User Management</Text>
      <Box sx={{ display: "flex", flexDirection: "row", mt: "20px" }}>
        <ToggleOption
          options={userManagementOptions}
          onClick={handleClick}
          selected={userManagementOption}
        />
      </Box>
      <Card
        sx={{
          py: "40px",
          px: "40px",
          borderRadius: "16px",
          mt: "20px",
          mb: "50px",
        }}
      >
        {renderUserOption()}
      </Card>
      <Text type="subHeadingBold">List of Users</Text>
      {loadUserListError}
      <UserList userList={didUserListLoaded} />
      {isLoadingUserList && <ActivityOverlay />}
    </Box>
  );
};
export default UserManagement;
