import React from "react";
import TrainingMaterialsAction from "./TrainingMaterialsAction";
import { Divider, Grid } from "@mui/material";
import { ProductNotetemProps } from "./types";
import Text from "components/Text";
import { Box } from "@mui/system";
import moment from "moment";

const TrainingMaterialsContentItem: React.FC<ProductNotetemProps> = ({
  data,
  index,
  handleContentDelete,
  handleContentEdit,
  handleContentView,
}) => {
  return (
    <div>
      <Grid container columnSpacing={"40px"}>
        <Grid item xs={2}>
          <Text type="mediumBold">Training Material</Text>
        </Grid>
        <Grid item xs={3}>
          <Text type="medium">{data.title}</Text>
        </Grid>
        <Grid item xs={2}>
          <Text type="medium">
            {moment(data.updatedOn).format("DD-MMM-YYYY")}
          </Text>
        </Grid>
        <Grid item xs={2}>
          <Box
            component="img"
            sx={{
              height: 100,
              width: 100,
              maxHeight: { xs: 100, md: 50 },
              maxWidth: { xs: 100, md: 65 },
            }}
            src={data.thumbnailImage}
            alt={data.thumbnailImage}
          />
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <TrainingMaterialsAction
              data={data}
              index={index}
              onDelete={handleContentDelete}
              onEdit={handleContentEdit}
              onView={handleContentView}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: "21px" }} />
    </div>
  );
};
export default TrainingMaterialsContentItem;
