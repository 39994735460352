import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, TextareaAutosize } from "@mui/material";
import FormInput from "components/FormInput";
import FormSelect, { SelectOption } from "components/FormSelect";
import Input from "components/Input";
import Select from "components/Select";
import { leadDataValidationSchema } from "pages/LeadManagement/LeadDataValidationSchema";
import { leadDataProps } from "pages/LeadManagement/types";
import { ChangeEvent, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ActivityOverlay from "components/ActivityOverlay";
import { addLead, loadLeads, resetAddLeadStates } from "../../store/slice";

import ToastCenter from "utils/ToastCenter";
import { getCount } from "pages/SalesRMManagement/store/slice";

const ManualAdd = () => {
  const dispatch = useAppDispatch();
  const { isAddingLead, didAdddLead, addLeadError } = useAppSelector(
    (state) => state.leadManagement
  );
  const { schemeNames } = useAppSelector((state) => state.schemes);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [mobile, setMobile] = useState("");
  const [address, setAddress] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (didAdddLead) {
      ToastCenter.success("Successfully added lead");
      dispatch(resetAddLeadStates());
      dispatch(
        loadLeads({
          pageNumber: 0,
          size: 1000,
        })
      );
      dispatch(getCount());
    }
  }, [didAdddLead]);

  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };
  const handleAddress = (event: ChangeEvent<HTMLInputElement>) => {
    setAddress(event.target.value);
  };
  const handleDescription = (event: ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  };
  const handleMobile = (event: ChangeEvent<HTMLInputElement>) => {
    setMobile(event.target.value);
  };

  const formMethods = useForm<leadDataProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(leadDataValidationSchema),
  });

  const onSubmit = (data: leadDataProps) => {
    const params = {
      name: data.name,
      email: data.email,
      mobileNumber: data.mobile,
      location: data.location,
      address: data.address,
      fundDescription: data.description,
    };
    dispatch(addLead(params));
  };

  const handleSelectScheme = (option: SelectOption) => {
    setDescription(option.value);
  };
  return (
    <div>
      <FormProvider {...formMethods}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <FormInput
            name="name"
            variant="filled"
            label="Name"
            sx={{ mr: "16px" }}
            onChange={handleName}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <FormInput
            name="email"
            variant="filled"
            label="Email"
            onChange={handleEmail}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
          <FormInput
            name="mobile"
            variant="filled"
            label="Mobile"
            sx={{ mr: "16px" }}
            onChange={handleMobile}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <FormInput
            name="location"
            variant="filled"
            label="Location"
            onChange={handleLocation}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
        <Box sx={{ mt: "24px", mr: "8px" }}>
          {/* <TextareaAutosize
            placeholder="Address"
            minRows={3}
            name="address"
            onChange={handleAddress}
            style={{ width: "100%" }}
          /> */}
          <FormInput
            name="address"
            variant="filled"
            label="Address"
            onChange={handleAddress}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>

        <Box sx={{ mt: "24px", mr: "8px" }}>
          <FormSelect
            name="description"
            options={schemeNames}
            placeHolder="Funds Input"
            onOptionSelect={handleSelectScheme}
          />
        </Box>
      </FormProvider>
      <Button
        variant="contained"
        sx={{
          mt: "32px",
          fontSize: "15px",
          lineHeight: "20px",
          fontWeight: 500,
          px: "28px",
          py: "8px",
        }}
        onClick={formMethods.handleSubmit(onSubmit)}
      >
        Add
      </Button>
      <Box sx={{ mr: "16px" }}>{addLeadError}</Box>

      {isAddingLead && <ActivityOverlay />}
    </div>
  );
};
export default ManualAdd;
