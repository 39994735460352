/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CheckBox from "components/CheckBox";
import { SelectOption } from "components/FormSelect";
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import ImageDropzone from "components/ImageDropZone";
import Text from "components/Text";
import { useState, useRef, useEffect } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import ActivityOverlay from "components/ActivityOverlay";
import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { resetUpdateContent, updateContent } from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import MultiSelect from "components/MultiSelect";
import moment from "moment";
import DateInput from "components/DateInput";
import Editor from "components/Editor";
export interface EditContentrProps {
  content: any;
  onCancel: () => void;
}

const EditProductNote: React.FC<EditContentrProps> = ({
  content,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { isUpdateContent, updateContentError, didUpdateContent } =
    useAppSelector((state) => state.contentManagement);

  const { schemeNames } = useAppSelector((state) => state.schemes);

  const [isTrending, setIsTrending] = useState(false);
  const [isRecomended, setIsRecomended] = useState(false);
  const [thumbnailImageError, setThumbnailImageError] = useState("");
  const [detailImageError, setDetailsImageError] = useState("");
  const imageDropzoneThumbnailRef = useRef<any>(null);
  const imageDropzoneRef = useRef<any>(null);
  const [isProductNote, setIsProductNote] = useState(false);
  const [selectedSchemes, setSelectedSchemes] = useState([]);
  const [isShowNotification, setIsShowNotification] = useState(false);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("title", content.title);
    formMethods.setValue("category", content.category);
    formMethods.setValue("thumbnail_file", "");
    formMethods.setValue("material_file", "");
    setIsTrending(content.trending);
    setIsRecomended(content.recommended);
    setIsProductNote(!isProductNote);
    formMethods.setValue("schemes", content?.schemes);
    const allSchems = content?.schemes ? content?.schemes.split("$") : [];
    setSelectedSchemes(allSchems);
    if (didUpdateContent) {
      ToastCenter.success("Successfully updated Product Note");
      formMethods.setValue("schemes", "");
      formMethods.setValue("thumbnail_file", null);
      formMethods.setValue("material_file", null);
      setIsTrending(false);
      setIsRecomended(false);
      setIsShowNotification(false);
      // imageDropzoneThumbnailRef.current.clearImage();
      formMethods.setValue("notificationTitle", "");
      formMethods.setValue("notificationShortDescription", "");
      formMethods.setValue("notificationDescription", "");
      imageDropzoneRef.current.clearImage();
      formMethods.setValue("contentUploadDate", "");
      formMethods.setValue("title", "");
      formMethods.setValue("category", "");
      onCancel();
    }
    return () => {
      dispatch(resetUpdateContent());
    };
  }, [didUpdateContent]);

  const onSubmit = (data: TContentForm) => {
    let showError = false;

    // if (!data.thumbnail_file) {
    //   showError = true;
    //   setThumbnailImageError("Please set thumbnail image");
    // }

    // if (!data.material_file) {
    //   showError = true;
    //   setDetailsImageError("Please set detail content");
    // }

    if (showError) {
      return;
    }

    const params = {
      thumbnail_file: data.thumbnail_file,
      material_file: data.material_file,
      imageRequest: {
        id: content.id,
        trending: isTrending,
        recommended: isRecomended,
        title: data.title,
        category: data.category,
        contentCategories: "PRODUCT_NOTE",
        schemes: data.schemes || "",
        contentUploadDate: data.contentUploadDate || null,
        enableNotification: isShowNotification,
        userType: ["MFD", "MFDRM", "SALESRM", "RIA"],
        notificationTitle: data?.notificationTitle ?? "",
        notificationShortDescription: data?.notificationShortDescription ?? "",
        notificationDescription: data?.notificationDescription ?? "",
      },
    };

    dispatch(updateContent(params));
  };

  const handleNotificationCheckBoxChange = (checked: boolean) => {
    setIsShowNotification(checked);
    formMethods.setValue("isShowNotification", checked, {
      shouldValidate: true,
    });
  };

  const handleImageDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("material_file", imageData);
  };
  const handleTrendingCheckBoxChange = (checked: boolean) => {
    setIsTrending(checked);
  };
  const handleRecomendedCheckBoxChange = (checked: boolean) => {
    setIsRecomended(checked);
  };
  const handleThumbnailDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    formMethods.setValue("thumbnail_file", imageData);
  };
  const onSelectSchemes = (item: Array<string>) => {
    formMethods.setValue("schemes", item.join("$"));
  };

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", my: "24px" }}>
        {/* <ImageDropzone
          onDropped={handleThumbnailDrop}
          ref={imageDropzoneThumbnailRef}
          heading="Upload Thumbnail Image"
          icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
          type="Image"
          error={thumbnailImageError}
        />
        <Box sx={{ width: "100%", height: "50px" }} /> */}
        <ImageDropzone
          onDropped={handleImageDrop}
          ref={imageDropzoneRef}
          heading="Upload PDF file here"
          icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
          type="PDF"
          error={detailImageError}
        />
        <Box sx={{ width: "100%", height: "50px" }} />
        <FormProvider {...formMethods}>
          <FormInput
            variant="filled"
            name="title"
            InputProps={{
              disableUnderline: true,
            }}
            label="Add Title"
          />
          <Box sx={{ height: "20px" }} />
          <DateInput
            label="Content Date"
            // error={formMethods.formState.errors.eventDate?.message}
            onChange={(newValue: Date | null) => {
              if (newValue) {
                formMethods.setValue(
                  "contentUploadDate",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }
            }}
            preValue={
              content?.contentUploadDate
                ? moment(content?.contentUploadDate).format("YYYY-MM-DD")
                : ""
            }
          />

          <Box sx={{ width: "100%", height: "20px" }} />
          <FormSelect
            label="Select Type"
            labelId="type"
            id="type"
            options={[
              {
                title: "Equity",
                value: "Equity",
              },
              {
                title: "Fixed Income",
                value: "Fixed Income",
              },
              {
                title: "Other",
                value: "Other",
              },
            ]}
            name="category"
          />

          <Box sx={{ width: "100%", height: "20px" }} />
          <MultiSelect
            label="Select Related Schemes"
            labelId="schemes"
            id="schemes"
            options={schemeNames}
            onOptionSelect={onSelectSchemes}
            defaultValue={selectedSchemes}
          />
        </FormProvider>
      </Box>

      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBox
            type="Trending"
            onClick={handleTrendingCheckBoxChange}
            checked={isTrending}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="Recomended"
            onClick={handleRecomendedCheckBoxChange}
            checked={isRecomended}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={"15px"}>
        <Grid item xs={6}>
          <CheckBox
            type="ShowNotification"
            checked={isShowNotification}
            onClick={handleNotificationCheckBoxChange}
          />
        </Grid>
      </Grid>
      {isShowNotification ? (
        <>
          <Text type="mediumBold">Notification Details</Text>
          <FormProvider {...formMethods}>
            <FormInput
              variant="filled"
              name="notificationTitle"
              InputProps={{
                disableUnderline: true,
              }}
              label="Add Title (max 1000 characters) *"
              sx={{ mt: "20px " }}
              inputProps={{ maxLength: 1000 }}
            />

            <FormInput
              variant="filled"
              name="notificationShortDescription"
              label="Short descriptions (Max. 1000 characters) *"
              sx={{ my: "30px " }}
              inputProps={{ maxLength: 1000 }}
            />
            <Editor
              placeholder="Descriptions (Max. 1000 characters)"
              onChange={(text) => {
                formMethods.setValue("notificationDescription", text, {
                  shouldValidate: true,
                });
              }}
              reset={didUpdateContent}
            />
            {formMethods.formState.errors?.notificationDescription?.message && (
              <Text type="error">
                {formMethods.formState.errors?.notificationDescription?.message}
              </Text>
            )}
            <Box sx={{ width: "100%", height: "20px" }} />
          </FormProvider>
          <Box sx={{ mt: "40px", mb: "21px" }}>
            <Divider variant="fullWidth" />
          </Box>
        </>
      ) : null}
      <Text type="error">{updateContentError}</Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
            right: "300px",
          }}
        >
          Submit
        </Button>

        <Button
          variant="contained"
          onClick={onCancel}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Cancel
        </Button>
      </Box>
      {isUpdateContent && <ActivityOverlay />}
    </Box>
  );
};

export default EditProductNote;
