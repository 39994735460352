import React from "react";
import { Divider, Grid } from "@mui/material";
import Text from "components/Text";
import { Box } from "@mui/system";
import moment from "moment";
import { EventItemProps } from "./types";
import EventAction from "./EventAction";

const InfoGraphicsContentItem: React.FC<EventItemProps> = ({
  data,
  index,
  handleContentDelete,
  handleContentEdit,
  handleContentView,
}) => {
  return (
    <div>
      <Grid container columnSpacing={"40px"}>
        <Grid item xs={2}>
          <Text type="mediumBold">{data.eventType}</Text>
        </Grid>
        <Grid item xs={3}>
          <Text type="medium">{data.eventName}</Text>
        </Grid>
        <Grid item xs={2}>
          <Text type="medium">
            {" "}
            {moment(data.createdOn).format("DD-MMM-YYYY")}
          </Text>
        </Grid>
        <Grid item xs={2}>
          <Text type="medium"> {data.eventBy}</Text>
        </Grid>

        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <EventAction
              data={data}
              index={index}
              onDelete={handleContentDelete}
              onEdit={handleContentEdit}
              onView={handleContentView}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: "21px" }} />
    </div>
  );
};
export default InfoGraphicsContentItem;
