/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Button, Grid } from "@mui/material";
import ActivityOverlay from "components/ActivityOverlay";
import CheckBox from "components/CheckBox";
import FormInput from "components/FormInput";
// import FormSelect from "components/FormSelect";
import Text from "components/Text";
import React, { useState, useRef, useEffect } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import { ReactComponent as UploadDetailIcon } from "res/images/uploadDetailIcon.svg";
import ImageDropzone from "components/ImageDropZone";

import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "../validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { resetUpdateContent, updateContent } from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import MultiSelect from "components/MultiSelect";
import Editor from "components/Editor";

export interface EditContentrProps {
  content: any;
  onCancel: () => void;
}

const EditInfoGraphics: React.FC<EditContentrProps> = ({
  content,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { isUpdateContent, updateContentError, didUpdateContent } =
    useAppSelector((state) => state.contentManagement);
  const { schemeNames } = useAppSelector((state) => state.schemes);

  const [thumbnailImageError, setThumbnailImageError] = useState("");
  const [detailsImageError, setDetailsImageError] = useState("");
  const [isTrending, setIsTrending] = useState(false);
  const [isRecomended, setIsRecomended] = useState(false);
  const [selectedSchemes, setSelectedSchemes] = useState([]);
  const [isShowNotification, setIsShowNotification] = useState(false);

  const imageDropzoneDetailImageRef = useRef<any>(null);
  const imageDropzoneThumbnailImageRef = useRef<any>(null);
  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("thumbnail_file", "");
    formMethods.setValue("detail_file", "");
    formMethods.setValue("title", content?.title);
    setIsTrending(content?.trending);
    setIsRecomended(content?.recommended);
    formMethods.setValue("schemes", content?.schemes);
    const allSchems = content?.schemes ? content?.schemes.split("$") : [];
    setSelectedSchemes(allSchems);
    if (didUpdateContent) {
      ToastCenter.success("Successfully updated infographic content");
      formMethods.setValue("schemes", "");
      formMethods.setValue("thumbnail_file", null);
      formMethods.setValue("detail_file", null);
      setIsTrending(false);
      setIsRecomended(false);
      setIsShowNotification(false);
      imageDropzoneDetailImageRef.current.clearImage();
      imageDropzoneThumbnailImageRef.current.clearImage();
      formMethods.setValue("title", "");
      formMethods.setValue("notificationTitle", "");
      formMethods.setValue("notificationShortDescription", "");
      formMethods.setValue("notificationDescription", "");
      // dispatch(resetStates());
      onCancel();
    }
    return () => {
      dispatch(resetUpdateContent());
    };
  }, [didUpdateContent]);

  const onSubmit = (data: TContentForm) => {
    let hasError = false;
    // if (!data.detail_file) {
    //   setThumbnailImageError("Please set thumbnail image");
    //   hasError = true;
    // }
    // if (!data.thumbnail_file) {
    //   setDetailsImageError("Please set detailed image ");
    //   hasError = true;
    // }
    if (hasError) {
      return;
    }
    const params = {
      thumbnail_file: data.thumbnail_file,
      detail_file: data.detail_file,
      imageRequest: {
        id: content.id,
        trending: isTrending,
        recommended: isRecomended,
        title: data.title,
        schemes: data.schemes || "",
        contentCategories: "INFOGRAPHICS",
        enableNotification: isShowNotification,
        userType: ["MFD", "MFDRM", "SALESRM", "RIA"],
        notificationTitle: data?.notificationTitle ?? "",
        notificationShortDescription: data?.notificationShortDescription ?? "",
        notificationDescription: data?.notificationDescription ?? "",
      },
    };

    dispatch(updateContent(params));
    // signInCallBack(data.email);
  };

  const handleNotificationCheckBoxChange = (checked: boolean) => {
    setIsShowNotification(checked);
    formMethods.setValue("isShowNotification", checked, {
      shouldValidate: true,
    });
  };

  const handleDetailPageImageDrop = (imageData: {
    url: string;
    name: string;
  }) => {
    setDetailsImageError("");
    formMethods.setValue("detail_file", imageData);
  };
  const handleThumbnailImageDrop = (imageData: {
    url: string;
    name: string;
  }) => {
    setThumbnailImageError("");
    formMethods.setValue("thumbnail_file", imageData);
  };
  const handleTrendingCheckBoxChange = (checked: boolean) => {
    setIsTrending(checked);
  };
  const handleRecomendedCheckBoxChange = (checked: boolean) => {
    setIsRecomended(checked);
  };
  const onSelectSchemes = (item: Array<string>) => {
    formMethods.setValue("schemes", item.join("$"));
  };

  return (
    <Box sx={{ mt: "40px" }}>
      <ImageDropzone
        onDropped={handleThumbnailImageDrop}
        ref={imageDropzoneThumbnailImageRef}
        heading="Upload Thumbnail Image"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={thumbnailImageError}
        resolution={"540w X 560h"}
      />
      <Box sx={{ width: "100%", height: "50px" }} />
      <ImageDropzone
        onDropped={handleDetailPageImageDrop}
        ref={imageDropzoneDetailImageRef}
        heading="Upload detail page image"
        icon={<UploadDetailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={detailsImageError}
      />

      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ pb: "16px" }}>
        <Text type="mediumBold">Add Content Title</Text>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", my: "24px" }}>
        <FormProvider {...formMethods}>
          <FormInput
            variant="filled"
            name="title"
            InputProps={{
              disableUnderline: true,
            }}
            label="Add Title"
          />

          <Box sx={{ width: "100%", height: "20px" }} />
          <MultiSelect
            label="Select Related Schemes"
            labelId="schemes"
            id="schemes"
            options={schemeNames}
            onOptionSelect={onSelectSchemes}
            defaultValue={selectedSchemes}
          />
        </FormProvider>
      </Box>
      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBox
            type="Trending"
            checked={isTrending}
            onClick={handleTrendingCheckBoxChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="Recomended"
            checked={isRecomended}
            onClick={handleRecomendedCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={"15px"}>
        <Grid item xs={6}>
          <CheckBox
            type="ShowNotification"
            checked={isShowNotification}
            onClick={handleNotificationCheckBoxChange}
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      {isShowNotification ? (
        <>
          <Text type="mediumBold">Notification Details</Text>
          <FormProvider {...formMethods}>
            <FormInput
              variant="filled"
              name="notificationTitle"
              InputProps={{
                disableUnderline: true,
              }}
              label="Add Title (max 1000 characters) *"
              sx={{ mt: "20px " }}
              inputProps={{ maxLength: 1000 }}
            />

            <FormInput
              variant="filled"
              name="notificationShortDescription"
              label="Short descriptions (Max. 1000 characters) *"
              sx={{ my: "30px " }}
              inputProps={{ maxLength: 1000 }}
            />
            <Editor
              placeholder="Descriptions (Max. 1000 characters)"
              onChange={(text) => {
                formMethods.setValue("notificationDescription", text, {
                  shouldValidate: true,
                });
              }}
              reset={didUpdateContent}
            />
            {formMethods.formState.errors?.notificationDescription?.message && (
              <Text type="error">
                {formMethods.formState.errors?.notificationDescription?.message}
              </Text>
            )}
            <Box sx={{ width: "100%", height: "20px" }} />
          </FormProvider>
          <Box sx={{ mt: "40px", mb: "21px" }}>
            <Divider variant="fullWidth" />
          </Box>
        </>
      ) : null}
      <Text type="error">{updateContentError}</Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            right: "300px",
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>

        <Button
          variant="contained"
          onClick={onCancel}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Cancel
        </Button>
      </Box>
      {isUpdateContent && <ActivityOverlay />}
    </Box>
  );
};
export default EditInfoGraphics;
