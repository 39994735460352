import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LoginState = {
  isSendingNotification: boolean;
  notificationError: string;
  didSentNotification: boolean;

  isLoadingPresentation: boolean;
  presentationsList: Array<any>;
  presentationError: string;

  isLoadingOnePager: boolean;
  onePagerList: Array<any>;
  onePagerError: string;

  isLoadingMarketInsights: boolean;
  marketInsightsList: Array<any>;
  marketInsightsError: string;

  isLoadingFactsheet: boolean;
  factsheetList: Array<any>;
  factsheetError: string;
};

const INITIAL_STATE: LoginState = {
  isSendingNotification: false,
  notificationError: "",
  didSentNotification: false,

  isLoadingPresentation: false,
  presentationsList: [],
  presentationError: "",

  isLoadingOnePager: false,
  onePagerList: [],
  onePagerError: "",

  isLoadingMarketInsights: false,
  marketInsightsList: [],
  marketInsightsError: "",

  isLoadingFactsheet: false,
  factsheetList: [],
  factsheetError: "",
};

const notification = createSlice({
  name: "notification",
  initialState: INITIAL_STATE,
  reducers: {
    sentNotification: (state, _action: PayloadAction<any>) => {
      state.isSendingNotification = true;
      state.notificationError = "";
    },
    didSentNotification: (state) => {
      state.isSendingNotification = false;
      state.didSentNotification = true;
    },
    didFailToSentNotification: (state, action: PayloadAction<string>) => {
      state.isSendingNotification = false;
      state.notificationError = action.payload;
    },
    resetNotificationStates: (state) => {
      state.isSendingNotification = false;
      state.didSentNotification = false;
      state.notificationError = "";
    },
    //addition of API calls for presentation ,onepager and marketinsight motification purpose
    loadPresentation: (state, action: PayloadAction<any>) => {
      state.isLoadingPresentation = true;
      state.presentationsList = [];
      state.presentationError = "";
    },
    didLoadPresentation: (state, action: PayloadAction<any>) => {
      state.isLoadingPresentation = false;
      state.presentationsList = action.payload;
    },
    didFailToLoadPresentation: (state, action: PayloadAction<any>) => {
      state.isLoadingPresentation = false;
      state.presentationError = action.payload;
    },

    loadOnePager: (state, action: PayloadAction<any>) => {
      state.isLoadingOnePager = true;
      state.onePagerList = [];
      state.onePagerError = "";
    },
    didLoadOnePager: (state, action: PayloadAction<any>) => {
      state.isLoadingOnePager = false;
      state.onePagerList = action.payload;
    },
    didFailToLoadOnePager: (state, action: PayloadAction<any>) => {
      state.isLoadingOnePager = false;
      state.onePagerError = action.payload;
    },

    loadMarketInsights: (state, action: PayloadAction<any>) => {
      state.isLoadingMarketInsights = true;
      state.marketInsightsList = [];
      state.marketInsightsError = "";
    },
    didLoadMarketInsights: (state, action: PayloadAction<any>) => {
      state.isLoadingMarketInsights = false;
      state.marketInsightsList = action.payload;
    },
    didFailToLoadMarketInsights: (state, action: PayloadAction<any>) => {
      state.isLoadingMarketInsights = false;
      state.marketInsightsError = action.payload;
    },
    loadFactsheet: (state, action: PayloadAction<any>) => {
      state.isLoadingFactsheet = true;
      state.factsheetList = [];
      state.factsheetError = "";
    },
    didLoadLoadFactsheet: (state, action: PayloadAction<any>) => {
      state.isLoadingFactsheet = false;
      state.factsheetList = action.payload;
    },
    didFailToLoadFactsheet: (state, action: PayloadAction<any>) => {
      state.isLoadingFactsheet = false;
      state.factsheetError = action.payload;
    },
  },
});

export const {
  sentNotification,
  didSentNotification,
  didFailToSentNotification,
  resetNotificationStates,
  loadPresentation,
  didLoadPresentation,
  didFailToLoadPresentation,
  loadOnePager,
  didLoadOnePager,
  didFailToLoadOnePager,
  loadMarketInsights,
  didLoadMarketInsights,
  didFailToLoadMarketInsights,
  loadFactsheet,
  didLoadLoadFactsheet,
  didFailToLoadFactsheet,
} = notification.actions;

export default notification.reducer;
