import * as React from "react";
import Text from "components/Text";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TimePicker from "@mui/lab/TimePicker";
import { TimeInputProps } from "./types";
const TimeInput: React.FC<TimeInputProps> = ({
  label,
  onChange,
  error,
  preValue = null,
}) => {
  const [value, setValue] = React.useState<Date | null>(preValue);

  const handleChange = (newValue: Date | null) => {
    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  };

  return (
    <>
      {" "}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          label={label}
          value={value}
          onChange={handleChange}
          renderInput={(params: any) => (
            <TextField variant="filled" {...params} fullWidth disabled />
          )}
        />
      </LocalizationProvider>
      <Text type="error">{error}</Text>
    </>
  );
};

export default TimeInput;
