import { toast } from "react-toastify";
class ToastCenter {
  success(message: string) {
    toast.success(message);
  }
  error(message: string) {
    toast.error(message);
  }
  info(message: string) {
    toast.info(message);
  }
}

export default new ToastCenter();
