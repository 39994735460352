import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import ToastCenter from "utils/ToastCenter";
import {
  didFailToUploadFundManager,
  uploadFundManager,
  didUploadFundManager,
  didFailToLoadFundManagers,
  didLoadFundManagers,
  loadFundManagers,
  didFailToUpdateFundManager,
  didUpdateFundManager,
  updateFundManager,
  deleteFundManager,
  didDeleteFundManager,
  didFailedToDeleteFundManager,
} from "./slice";

const UPLOAD_FUND_MANAGER_ENDPOINT = "v1/admin/content/fundManager";
const UPDATE_FUND_MANAGER_ENDPOINT = "v1/admin/editFundManagerDetails";
const LOAD_FUND_MANAGER_ENDPOINT = "v1/admin/fetchFundManagersList";
const DELETE_FUND_MANAGER_ENDPOINT = "v1/admin/deleteFundManager";

function* handleFundManagerUpload(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadBanner,
    UPLOAD_FUND_MANAGER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to upload fund manager image.");
    yield put(didFailToUploadFundManager(response.error));
  } else {
    yield put(didUploadFundManager());
  }
}
function* handleFundManagerUpdate(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadBanner,
    UPDATE_FUND_MANAGER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to update fund manager image.");
    yield put(didFailToUpdateFundManager(response.error));
  } else {
    yield put(didUpdateFundManager());
  }
}

function* handleLoadFundManagers() {
  const response: TResponse = yield call(
    Request.GET,
    LOAD_FUND_MANAGER_ENDPOINT
  );
  if (response.error) {
    ToastCenter.error("Unable to load fund manager details.");
    yield put(didFailToLoadFundManagers(response?.error));
  } else {
    yield put(didLoadFundManagers(response?.data?.data?.fundManagersList));
  }
}
function* handleDeleteFundManagers(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    `${DELETE_FUND_MANAGER_ENDPOINT}?fundManagerId=${action.payload}`
  );

  if (response.error) {
    ToastCenter.error("Unable to delete fund manager.");
    yield put(didFailedToDeleteFundManager(response.error));
  } else {
    ToastCenter.success("Successfully deleted fund manager.");
    yield put(didDeleteFundManager(action?.payload));
  }
}

function* watcherFundManagerUpload() {
  yield takeLatest(uploadFundManager.type, handleFundManagerUpload);
}
function* watcherFundManagerUpdate() {
  yield takeLatest(updateFundManager.type, handleFundManagerUpdate);
}
function* watcherLoadFundManagers() {
  yield takeLatest(loadFundManagers.type, handleLoadFundManagers);
}
function* watcherDeleteFundManagers() {
  yield takeLatest(deleteFundManager.type, handleDeleteFundManagers);
}

export function* fundManagerSaga() {
  yield all([
    watcherFundManagerUpload(),
    watcherLoadFundManagers(),
    watcherFundManagerUpdate(),
    watcherDeleteFundManagers(),
  ]);
}
