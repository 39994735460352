import { useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import theme from "theme";
import AppRoute from "routes/AppRoute";
import { Provider } from "react-redux";
import { store } from "store";
import {
  setBaseURL,
  getInvescoToken,
  setHeaderAccessToken,
} from "networking/NetworkManager";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isMobile } from "react-device-detect";
import NavBar from "components/NavBar";
import LocalStore from "utils/LocalStore";

setBaseURL();
getInvescoToken();

function App() {
  useEffect(() => {
    if (LocalStore.getAccessToken()) {
      setHeaderAccessToken(LocalStore.getAccessToken());
    }
  }, []);
  if (isMobile) {
    return (
      <div style={{ display: "flex" }}>
        <NavBar />
        <div style={{ marginTop: 100, marginLeft: 20 }}>
          {" "}
          Device is not supported
        </div>
      </div>
    );
  }
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppRoute />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick
          transition={Slide}
        />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
