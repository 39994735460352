import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  CardMedia,
  Card,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";
import { ContentActionProps } from "./types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import colors from "res/colors";
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditProductVideo from "./EditProductVideo";
const ProductVideosAction: React.FC<ContentActionProps> = ({
  onDelete,
  onEdit,
  onView,
  data,
  index,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewProductVideos, setProductVideos] = useState(false);
  const [edit, setEdit] = useState(false);
  const [assetURL, setAssetURL] = useState("");

  const handleConfirmDelete = (data: any) => {
    setDeleteDialog(false);
    onDelete(data, index);
  };

  const handleClose = () => {
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    setDeleteDialog(true);
  };

  const handleProductVideos = () => {
    setProductVideos(true);
  };
  const handleProductVideosClose = () => {
    setProductVideos(false);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditClose = () => {
    setEdit(false);
  };

  useEffect(() => {
    let urlString = data?.assetUrl;
    let URLArray = data?.assetUrl?.split("shorts/");
    if (URLArray?.length > 1) {
      //this is for short URL
      let tempURL = URLArray[1]?.split("?");
      urlString = `https://www.youtube.com/watch?v=${tempURL[0]}`;
    }
    setAssetURL(urlString || "");
  }, [data]);
  const editRender = () => {
    return (
      <Dialog open={edit} maxWidth="xl">
        <DialogActions>
          <Button onClick={handleEditClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Update Product Video</DialogTitle>
        <DialogContent>
          <EditProductVideo content={data} onCancel={handleEditClose} />
        </DialogContent>
      </Dialog>
    );
  };

  const viewRender = () => {
    return (
      <Dialog open={viewProductVideos} maxWidth="xl">
        <DialogActions>
          <Button onClick={handleProductVideosClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Product Videos</DialogTitle>
        <DialogContent>
          <Card sx={{ maxWidth: "600px" }}>
            <Box sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                // height="600"
                width="200"
                image={data.thumbnailImage}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {data.title}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  {data.creatorName} {data.creatorDesignation}
                </Typography>
                <Typography variant="body2">{data.description}</Typography>
              </CardContent>
              <ReactPlayer url={assetURL} controls={true} width={"600px"} />
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Link component="button" onClick={() => handleProductVideos()}>
        View
      </Link>
      <IconButton
        color="default"
        size="small"
        sx={{}}
        onClick={() => handleEdit()}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        color="default"
        size="small"
        sx={{}}
        onClick={() => handleDelete()}
      >
        <DeleteForeverIcon />
      </IconButton>
      <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete?</DialogTitle>
        <DialogContent>
          This action will permanently delete the content.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleConfirmDelete(data)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {viewRender()}
      {editRender()}
    </Box>
  );
};

export default ProductVideosAction;
