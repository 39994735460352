/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Button, Grid } from "@mui/material";
import ActivityOverlay from "components/ActivityOverlay";
import CheckBox from "components/CheckBox";
import FormInput from "components/FormInput";
// import FormSelect from "components/FormSelect";
import Text from "components/Text";
import React, { useState, useRef, useEffect } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import { ReactComponent as UploadDetailIcon } from "res/images/uploadDetailIcon.svg";
import ImageDropzone from "components/ImageDropZone";

import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "../validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import MultiSelect from "components/MultiSelect";
import {
  resetUpdateLoginBanner,
  updateLoginBanner,
} from "pages/BannerManagement/store/slice";

export interface EditContentrProps {
  content: any;
  onCancel: () => void;
}

const EditLoginBanner: React.FC<EditContentrProps> = ({
  content,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { didUpdateLoginBanner } = useAppSelector(
    (state) => state.bannerManagement
  );

  const [bannerImageError, setBannerImageError] = useState("");

  const imageDropzoneDetailImageRef = useRef<any>(null);
  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("banner", "");
    formMethods.setValue("description", content?.description);
    if (didUpdateLoginBanner) {
      ToastCenter.success("Successfully updated login banner.");
      formMethods.setValue("banner", null);
      formMethods.setValue("description", "");
      imageDropzoneDetailImageRef.current.clearImage();
      // dispatch(resetStates());
      onCancel();
    }
    return () => {
      dispatch(resetUpdateLoginBanner());
    };
  }, [didUpdateLoginBanner]);

  const onSubmit = (data: TContentForm) => {
    // let hasError = false;
    // if (!data.banner) {
    //   setBannerImageError("Please set banner image ");
    //   hasError = true;
    // }
    // if (hasError) {
    //   return;
    // }
    const params = {
      banner: data?.banner || null,
      imageRequest: {
        bannerId: content?.bannerId || null,
        description: data?.description,
      },
    };

    dispatch(updateLoginBanner(params));
    // signInCallBack(data.email);
  };

  const handleBannerImageDrop = (imageData: { url: string; name: string }) => {
    setBannerImageError("");
    formMethods.setValue("banner", imageData);
  };
  return (
    <Box sx={{ mt: "40px", width: "700px" }}>
      <ImageDropzone
        onDropped={handleBannerImageDrop}
        ref={imageDropzoneDetailImageRef}
        heading={`Upload Banner Image`}
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={bannerImageError}
        resolution={"180w X 180h"}
      />
      <Box sx={{ mt: "10px" }} />
      <FormProvider {...formMethods}>
        <FormInput
          variant="filled"
          name={`description`}
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Description"
          multiline
          rows={2}
        />
      </FormProvider>
      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      {/* <Text type="error">{updateContentError}</Text> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            // right: "200px",
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>

        <Button
          variant="contained"
          onClick={onCancel}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Cancel
        </Button>
      </Box>
      {/* {isUpdateContent && <ActivityOverlay />} */}
    </Box>
  );
};
export default EditLoginBanner;
