import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  Divider,
  Collapse,
} from "@mui/material";
import { Box } from "@mui/system";
import FormInput from "components/FormInput";
import FormSelect, { SelectOption } from "components/FormSelect";
import { ChangeEvent, useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import colors from "res/colors";
import { userDataProps } from "./types";
import { userDataValidationSchema } from "./UserDataValidationSchema";
import Text from "components/Text";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  addUser,
  addAdmin,
  resetStatesUser,
  resetStatesAdmin,
  userLists,
} from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";

const Users = () => {
  const dispatch = useAppDispatch();
  const {
    isAddingUser,
    isAddingAdmin,
    didAddUser,
    didAddAdmin,
    addUserError,
    addAdminError,
  } = useAppSelector((state) => state.userManagement);

  useEffect(() => {
    if (didAddUser) {
      ToastCenter.success("Successfully added user");
      formMethods.setValue("name", "");
      formMethods.setValue("email", "");
      formMethods.setValue("deleteAccess", "");
      formMethods.setValue("readAccess", "");
      formMethods.setValue("writeAccess", "");
      dispatch(
        userLists({
          pageNumber: "0",
          size: "100",
        })
      );
      dispatch(resetStatesUser());
    }
    if (didAddAdmin) {
      ToastCenter.success("Successfully added admin");
      formMethods.setValue("name", "");
      formMethods.setValue("email", "");
      dispatch(
        userLists({
          pageNumber: "0",
          size: "100",
        })
      );
      dispatch(resetStatesAdmin());
    }
  }, [didAddUser, didAddAdmin]);

  const [userType, setUserType] = useState("admin");

  const [checked, setChecked] = useState(false);
  const [checkeAdmin, setCheckeAdmin] = useState(true);
  const handleUserTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUserType((event.target as HTMLInputElement).value);
    setChecked((prev) => !prev);
    setCheckeAdmin((prev) => !prev);
  };

  const formMethods = useForm<userDataProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(userDataValidationSchema),
  });

  const onSubmit = (data: userDataProps) => {
    const params = {
      name: data.name,
      emailId: data.email,
      readAccess: data.readAccess,
      writeAccess: data.writeAccess,
      deleteAccess: data.deleteAccess,
      accessTo: data.accessTo,
    };
    const paramsAdmin = {
      name: data.name,
      emailId: data.email,
    };
    if (userType === "admin") {
      dispatch(addAdmin(paramsAdmin));
    }
    if (userType === "user") {
      dispatch(addUser(params));
    }
  };

  const Access = [
    {
      title: "YES",
      value: "true",
    },
    {
      title: "NO",
      value: "false",
    },
  ];

  const saleModule = [{ title: "SALE RM MODULE", value: "SALES_RM_MODULE" }];
  return (
    <div>
      <Box sx={{ mb: "30px" }}>
        <Text type="error">{addUserError}</Text>
        <Text type="error">{addAdminError}</Text>
      </Box>
      <FormControl component="fieldset">
        <RadioGroup
          row
          aria-label="userType"
          name="row-radio-buttons-group"
          onChange={handleUserTypeChange}
          defaultValue="admin"
        >
          <FormControlLabel value="admin" control={<Radio />} label="Admin" />
          <FormControlLabel value="user" control={<Radio />} label="User" />
        </RadioGroup>
      </FormControl>
      <Collapse in={checked}>
        <FormProvider {...formMethods}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <FormInput
              variant="filled"
              label="Name"
              sx={{ mr: "16px" }}
              InputProps={{
                disableUnderline: true,
              }}
              name={"name"}
            />
            <FormInput
              variant="filled"
              label="Email"
              InputProps={{
                disableUnderline: true,
              }}
              name={"email"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "24px",
              alignContent: "space-between",
            }}
          >
            <FormSelect
              options={saleModule}
              placeHolder="Access To"
              name={"accessTo"}
            />

            <Box sx={{ mr: "16px" }}></Box>
            <FormSelect
              placeHolder="Read Access"
              options={Access}
              name={"readAccess"}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "24px",
              alignContent: "space-between",
            }}
          ></Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <FormSelect
              options={Access}
              placeHolder="Write Access"
              name={"writeAccess"}
            />
            <Box sx={{ mr: "16px" }}></Box>
            <FormSelect
              options={Access}
              placeHolder="Delete Access"
              name={"deleteAccess"}
            />
          </Box>
        </FormProvider>

        <Box sx={{ mt: "40px", mb: "20px" }}>
          <Divider />
        </Box>
      </Collapse>
      <Collapse in={checkeAdmin}>
        <FormProvider {...formMethods}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <FormInput
              variant="filled"
              label="Name"
              sx={{ mr: "16px" }}
              InputProps={{
                disableUnderline: true,
              }}
              name={"name"}
            />
            <FormInput
              variant="filled"
              label="Email"
              InputProps={{
                disableUnderline: true,
              }}
              name={"email"}
            />
          </Box>
        </FormProvider>

        <Box sx={{ mt: "40px", mb: "20px" }}>
          <Divider />
        </Box>
      </Collapse>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          sx={{ py: "12px", px: "20px" }}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          Submit
        </Button>
        {isAddingUser && <ActivityOverlay />}
        {isAddingAdmin && <ActivityOverlay />}
      </Box>
    </div>
  );
};

export default Users;
