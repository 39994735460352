import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TDigitalPostersParams, TDigitalPosterDetailsDelete } from "./types";

type DigitalPostersState = {
  isLodingDigitalPosters: boolean;
  digitalPostersError: string;
  digitalPostersContentData: Array<any>;

  isDeleteDigitalPoster: boolean;
  deleteDigitalPosterError: string;
  deleteDigitalPoster: any;

};

const INITIAL_STATE: DigitalPostersState = {
  isLodingDigitalPosters: false,
  digitalPostersError: "",
  digitalPostersContentData: [],

  isDeleteDigitalPoster: false,
  deleteDigitalPosterError: "",
  deleteDigitalPoster: {},

};

const digitalPosters = createSlice({
  name: "digitalPosters",
  initialState: INITIAL_STATE,
  reducers: {
    loadDigitalPosters: (
      state,
      _action: PayloadAction<TDigitalPostersParams>
    ) => {
      state.isLodingDigitalPosters = true;
      state.digitalPostersError = "";
    },
    didLoadDigitalPosters: (state, action: PayloadAction<any>) => {
      state.isLodingDigitalPosters = false;
      state.digitalPostersError = "";
      state.digitalPostersContentData = action.payload;
    },
    didFailToLoadDigitalPosters: (state, action: PayloadAction<any>) => {
      state.isLodingDigitalPosters = false;
      state.digitalPostersError = action.payload;
    },

    deleteDigitalPosters: (
      state,
      _action: PayloadAction<TDigitalPosterDetailsDelete>
    ) => {
      state.isDeleteDigitalPoster = true;
      state.deleteDigitalPosterError = "";
      const temp = [...state.digitalPostersContentData];
      temp.splice(_action.payload.index, 1);
      state.digitalPostersContentData = temp;
    },
    didDeleteDigitalPosters: (state, action: PayloadAction<any>) => {
      state.isDeleteDigitalPoster = false;
      state.deleteDigitalPosterError = "";
    },
    didFailToDeleteDigitalPosters: (state, action: PayloadAction<any>) => {
      state.isDeleteDigitalPoster = false;
      state.deleteDigitalPosterError = action.payload;
    },
  },
});

export const {
  loadDigitalPosters,
  didLoadDigitalPosters,
  didFailToLoadDigitalPosters,
  deleteDigitalPosters,
  didDeleteDigitalPosters,
  didFailToDeleteDigitalPosters,
} = digitalPosters.actions;
export default digitalPosters.reducer;
