import { Box, Grid } from "@mui/material";
import { FC, useEffect } from "react";
import { assignedLeadListProps, assignedLeadListType } from "./types";
import AssignedLeadListItem from "./AssignedLeadListItem";
import {
  loadLeads,
  resetDeleteLeadStates,
  resetUpdateLeadStates,
} from "../../../store/slice";
import Text from "components/Text";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ActivityOverlay from "components/ActivityOverlay";
import ToastCenter from "utils/ToastCenter";
import colors from "res/colors";

const AssignedLeadList = ({ leads, isFilterApplied }: any) => {
  const dispatch = useAppDispatch();
  const {
    isLoadingLeads,
    // leads,
    loadLeadsError,
    // didUpdateLead,
    // didDeleteLead,
  } = useAppSelector((state) => state.leadManagement);

  // useEffect(() => {
  //   if (didUpdateLead || didDeleteLead) {
  //     if (didUpdateLead) {
  //       dispatch(resetUpdateLeadStates());
  //       ToastCenter.success("Successfully updated Lead");
  //     }
  //     if (didDeleteLead === true) {
  //       dispatch(resetDeleteLeadStates());
  //       ToastCenter.success("Successfully delete Lead");
  //     }
  //     dispatch(
  //       loadLeads({
  //         pageNumber: 0,
  //         size: 10,
  //       })
  //     );
  //   }
  // }, [didUpdateLead, didDeleteLead]);

  const handleDelete = (data: assignedLeadListType) => {};
  const handleEdit = (data: assignedLeadListType) => {};
  return (
    <Box sx={{ mt: "24px" }}>
      <Grid container direction="row" sx={{ pl: "20px" }}>
        <Grid xs={4}>
          <Text type="listMediumBold">Name</Text>
        </Grid>
        <Grid xs={0.4} />
        <Grid xs={2.4}>
          <Text type="listMediumBold"> Assigned To</Text>
        </Grid>
        <Grid xs={2.55}>
          <Text type="listMediumBold">Assigned On</Text>
        </Grid>
        <Grid xs={1}>
          <Text type="listMediumBold">Status</Text>
        </Grid>
        <Grid xs={0.5}>
          <Box>{isFilterApplied && `(${leads?.length})`}</Box>
        </Grid>
      </Grid>

      {leads.map((user: any, index: any) => {
        return (
          <AssignedLeadListItem
            data={user}
            key={`${user.email}+${index}`}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        );
      })}
      <Box sx={{ mb: "20px" }} />
      <Text
        type="medium"
        sx={{
          fontSize: "16px",
          letterSpacing: "0em",
          lineHeight: "20px",
          fontFamily: "Graphik",
          ml: "18px",
          color: colors.red,
        }}
      >
        {" "}
        {loadLeadsError}
      </Text>
      {isLoadingLeads && <ActivityOverlay />}
    </Box>
  );
};

export default AssignedLeadList;
