import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { Button, Card, Divider, Grid, IconButton, Link } from "@mui/material";
import colors from "res/colors";
import Text from "components/Text";
import { Box } from "@mui/system";
import LoginBannerContentItem from "./LoginBannerContentItem";
import {
  deleteLoginBanner,
  loadLoginBanner,
} from "pages/BannerManagement/store/slice";

const LoginBannerContents = () => {
  const dispatch = useAppDispatch();
  const [loginBannerList, setLoginBannerList] = useState([]);
  const { didUpdateLoginBanner, loginBannerData, loginBannerDeleted } =
    useAppSelector((state) => state.bannerManagement);
  useEffect(() => {
    dispatch(loadLoginBanner());
  }, [didUpdateLoginBanner]);

  useEffect(() => {
    if (loginBannerData && loginBannerData?.length > 0) {
      setLoginBannerList(loginBannerData);
    }
  }, [loginBannerData]);
  useEffect(() => {
    if (loginBannerDeleted) {
      const data = loginBannerList?.filter(
        (ele: any) => ele?.bannerId !== loginBannerDeleted
      );
      setLoginBannerList(data);
    }
  }, [loginBannerDeleted]);

  const handleContentDelete = (rowData: any, index: number) => {
    // const deleteData = {
    //   bannerId: rowData.bannerId,
    // };
    dispatch(deleteLoginBanner(rowData.bannerId));
  };
  const handleContentEdit = (rowData: any) => {};
  const handleContentView = (rowData: any) => {};

  return (
    <div>
      <Box>
        <Box
          sx={{
            pt: "20px",
            pb: "16px",
          }}
        >
          <Text type="subHeadingBold">Updated Content</Text>
        </Box>

        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "16px",
            pt: "42px",
            pb: "20px",
            px: "32px",
          }}
        >
          <Grid container columnSpacing={"40px"}>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Section"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Description"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{" Upload Date"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Banner Image"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Action"}</Text>
            </Grid>
          </Grid>
          <Box sx={{ mt: "16px", mb: "20px" }}>
            <Divider />
          </Box>
          {loginBannerList?.map((rowData: any, index: number) => {
            return (
              <LoginBannerContentItem
                key={rowData.id}
                data={rowData}
                index={index}
                handleContentDelete={handleContentDelete}
                handleContentEdit={handleContentEdit}
                handleContentView={handleContentView}
              />
            );
          })}
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.lightButtonBlue,
                color: colors.backGroundBlue,
              }}
            >
              See More
            </Button>
          </Box> */}
        </Card>
      </Box>
    </div>
  );
};

export default LoginBannerContents;
