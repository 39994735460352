import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SipPermanceState = {
  isUploadingSipPermanceList: boolean;
  uploadSipPermanceListError: string;
  didUploadSipPermanceList: boolean;

  isloadingSipPermanceList: boolean;
  sipPermanceListError: string;
  sipPermanceList: Array<any>;

  isDeletingSip: boolean;
  didDeleteSip: boolean;
  deleteSipError: string;
};

const INITIAL_STATE: SipPermanceState = {
  isUploadingSipPermanceList: false,
  uploadSipPermanceListError: "",
  didUploadSipPermanceList: false,

  isloadingSipPermanceList: false,
  sipPermanceListError: "",
  sipPermanceList: [],

  isDeletingSip: false,
  didDeleteSip: false,
  deleteSipError: "",
};

const sipPerformance = createSlice({
  name: "sipPerformance",
  initialState: INITIAL_STATE,
  reducers: {
    uploadSipPermanceList: (state, _action: PayloadAction<any>) => {
      state.isUploadingSipPermanceList = true;
    },
    didUploadSipPermanceList: (state) => {
      state.isUploadingSipPermanceList = false;
      state.didUploadSipPermanceList = true;
    },
    didFailToUploadSipPermanceList: (state, action: PayloadAction<any>) => {
      state.isUploadingSipPermanceList = false;
      state.uploadSipPermanceListError = action.payload;
    },
    loadSipPermanceList: (state) => {
      state.isloadingSipPermanceList = true;
      state.sipPermanceListError = "";
      state.sipPermanceList = [];
    },
    didLoadSipPermanceList: (state, action: PayloadAction<any>) => {
      state.isloadingSipPermanceList = false;
      state.sipPermanceList = action.payload;
    },
    didFailToLoadSipPermanceList: (state, action: PayloadAction<any>) => {
      state.isloadingSipPermanceList = false;
      state.sipPermanceListError = action.payload;
    },

    deleteSipPerformance: (state, _action: PayloadAction<any>) => {
      state.isDeletingSip = true;
      state.didDeleteSip = false;
      state.deleteSipError = "";
    },

    didDeleteSipPermanceList: (state) => {
      state.isDeletingSip = false;
      state.didDeleteSip = true;
    },
    didFailToDeleteSipPermanceList: (state, action: PayloadAction<any>) => {
      state.isDeletingSip = false;
      state.deleteSipError = action.payload;
    },
    resetSipDeleteStates: (state) => {
      state.isDeletingSip = false;
      state.didDeleteSip = false;
      state.deleteSipError = "";
    },

    resetUploadSipPermanceList: (state) => {
      state.isUploadingSipPermanceList = false;
      state.didUploadSipPermanceList = false;
      state.uploadSipPermanceListError = "";
    },
  },
});

export const {
  deleteSipPerformance,
  didDeleteSipPermanceList,
  didFailToDeleteSipPermanceList,
  resetSipDeleteStates,
  loadSipPermanceList,
  didLoadSipPermanceList,
  didFailToLoadSipPermanceList,
  uploadSipPermanceList,
  didUploadSipPermanceList,
  didFailToUploadSipPermanceList,
  resetUploadSipPermanceList,
} = sipPerformance.actions;
export default sipPerformance.reducer;
