import { Box, Card, Divider, Grid, IconButton } from "@mui/material";
import Text from "components/Text";
import { FC, useState } from "react";
import { SIPList } from "./types";
// import { salesRmProps } from "../types";
import moment from "moment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
export interface RmListItemProps {
  data: SIPList;
  index: any;
}
const SipPerformanceListItem: FC<RmListItemProps> = ({ data, index }) => {
  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const renderExpandIcon = () => {
    if (expanded) return <ExpandLessIcon />;
    return <ExpandMoreIcon />;
  };

  const renderExpandedView = () => {
    if (expanded) {
      return (
        <div>
          <Box sx={{ pt: "20px" }}>
            <Divider />
          </Box>
          <Grid container direction="row" sx={{ mt: "16px" }}>
            {/* <Grid xs={1}></Grid> */}
            <Grid xs={3.4}>
              <Text type="listMediumBold">Scheme Market Value</Text>
              <Box sx={{ pt: "3px" }} />
              <Text type="listMedium">{data?.schemeMarketValue}</Text>
            </Grid>
            {/* <Grid xs={2.4}>
              <Text type="listMediumBold">Period</Text>
              <Box sx={{ pt: "3px" }} />
              <Text type="listMedium">{data?.period}</Text>
            </Grid> */}
            <Grid xs={2.3} sx={{ pl: "6px" }}>
              <Text type="listMediumBold">Scheme XIRR</Text>
              <Box sx={{ pt: "3px" }} />
              <Text type="listMedium">{data?.schemeXirr}</Text>
            </Grid>
            <Grid xs={2.3}>
              <Text type="listMediumBold">BenchMark1 XIRR</Text>
              <Box sx={{ pt: "3px" }} />
              <Text type="listMedium">{data?.benchMarkOneXirr}</Text>
            </Grid>
            <Grid xs={2}>
              <Text type="listMediumBold">BenchMark2 XIRR</Text>
              <Box sx={{ pt: "3px" }} />
              <Text type="listMedium">{data?.benchMarkTwoXirr}</Text>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  return (
    <Box>
      <Card sx={{ px: "22px", mb: "12px", pt: "20px", pb: "10px" }}>
        <Grid>
          <Grid container direction="row">
            {/* <Grid xs={1}>
              <Text type="listMediumBold">{index + 1}</Text>
            </Grid> */}
            <Grid xs={3.4}>
              <Text type="listMediumBold">{data.title}</Text>
            </Grid>
            <Grid xs={2.2} sx={{ pl: "6px" }}>
              <Text type="listMedium">{data.period}</Text>
            </Grid>
            <Grid xs={2.3} sx={{ pl: "8px" }}>
              <Text type="listMedium">{data.investedAmount}</Text>
            </Grid>
            <Grid xs={1.8} sx={{ pl: "12px" }}>
              <Text type="listMedium">
                {moment(data.createdOn).format("DD-MMM-YYYY")}
              </Text>
            </Grid>
            <Grid xs={1.8} sx={{ pl: "16px" }}>
              <Text type="listMedium">
                {moment(data.updatedOn).format("DD-MMM-YYYY")}
              </Text>
            </Grid>
            <Grid xs={0.5}>
              <Box
                sx={{
                  mt: "-10px",
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                }}
              >
                <IconButton onClick={handleExpand}>
                  {renderExpandIcon()}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        {renderExpandedView()}
      </Card>
    </Box>
  );
};

export default SipPerformanceListItem;
