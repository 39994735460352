import { Box, Button, Card, Divider } from "@mui/material";
import Input from "components/Input";
import Select from "components/Select";
import { ChangeEvent, useState } from "react";
import colors from "res/colors";

const AddScheme = () => {
  const [exitLoad, setExitLoad] = useState("");
  const [expenseRatio, setExpenseRatio] = useState("");

  const handleExitLoad = (event: ChangeEvent<HTMLInputElement>) => {
    setExitLoad(event.target.value);
  };

  const handleExpenseRatio = (event: ChangeEvent<HTMLInputElement>) => {
    setExitLoad(event.target.value);
  };

  return (
    <Box>
      <Box sx={{ mt: "24px" }}>
        <Select
          options={[{ title: "1", value: "1" }]}
          placeHolder="Scheme Type"
        />
      </Box>
      <Box sx={{ mt: "24px", display: "flex", flexDirection: "row" }}>
        <Input
          variant="filled"
          label="Exit load"
          sx={{ mr: "16px" }}
          onChange={handleExitLoad}
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Input
          variant="filled"
          label="Expense ratio"
          onChange={handleExpenseRatio}
          InputProps={{
            disableUnderline: true,
          }}
        />
      </Box>
      <Box sx={{ mt: "40px", mb: "24px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",

            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

export default AddScheme;
