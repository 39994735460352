import { Box } from "@mui/material";
import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import { useState, useEffect } from "react";
import AddLead from "./AddLead";
import AssignLead from "./AssignLead";
import ActivityOverlay from "components/ActivityOverlay";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { loadLeads } from "./store/slice";
import { getCount, loadSalesRMs } from "../SalesRMManagement/store/slice";
const LeadManagement = () => {
  const dispatch = useAppDispatch();
  const { isUploadingLeads, isDeletingLeadExcel, isLoadingAutoAssign } =
    useAppSelector((state) => state.leadManagement);

  const [leadManagementOption, setLeadManagementOption] = useState("add lead");

  const leadManagementType = [{ label: "add lead" }, { label: "assign lead" }];

  useEffect(() => {
    dispatch(
      loadSalesRMs({
        pageNumber: 1,
        endPageNumber: 100,
      })
    );
    dispatch(
      loadLeads({
        pageNumber: 0,
        size: 1000,
      })
    );
    dispatch(getCount());
  }, []);

  const handleLeadManagementType = (displayComponent: string) => {
    setLeadManagementOption(displayComponent);
  };

  const renderLeadOption = () => {
    if (leadManagementOption === "add lead") {
      return <AddLead />;
    }
    return <AssignLead />;
  };
  return (
    <Box sx={{ ml: "20%", minHeight: "vh", py: "50px", width: "65%" }}>
      <Text type="headingBold">Lead Management</Text>
      <Box sx={{ display: "flex", flexDirection: "row", mt: "36px" }}>
        <ToggleOption
          options={leadManagementType}
          onClick={handleLeadManagementType}
          selected={leadManagementOption}
        />
      </Box>

      {renderLeadOption()}
      {(isUploadingLeads || isDeletingLeadExcel || isLoadingAutoAssign) && (
        <ActivityOverlay />
      )}
    </Box>
  );
};
export default LeadManagement;
