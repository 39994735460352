import * as yup from "yup";
export const contentValidationSchema = yup.object().shape({
  title: yup.string().min(5).required("Please enter a valid title"),
  category: yup.string().required("Please enter a valid type"),
  notificationTitle: yup.string().when("isShowNotification", {
    is: (val: boolean) => val === true,
    then: yup.string().min(3).max(1000).required("Please enter a valid title"),
  }),
  // notificationDescription: yup.string().when("isShowNotification", {
  //   is: (val: boolean) => val === true,
  //   then: yup.string().max(1000).required("Please enter a valid description"),
  // }),
  notificationShortDescription: yup.string().when("isShowNotification", {
    is: (val: boolean) => val === true,
    then: yup
      .string()
      .max(1000)
      .required("Please enter a valid short description"),
  }),
});
