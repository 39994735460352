import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  CardMedia,
  Card,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";
import { ContentActionProps } from "./types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import colors from "res/colors";
import { useState } from "react";
import EditProductNote from "./EditProductNote";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import useWindowDimensions from "hooks/useWindowDimensions";

const ProductNoteAction: React.FC<ContentActionProps> = ({
  onDelete,
  onEdit,
  onView,
  data,
  index,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewProductNote, setProductNote] = useState(false);
  const [edit, setEdit] = useState(false);
  const { height, width } = useWindowDimensions();
  const handleConfirmDelete = (data: any) => {
    setDeleteDialog(false);
    onDelete(data, index);
  };

  const handleClose = () => {
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    setDeleteDialog(true);
  };

  const handleProductNote = () => {
    setProductNote(true);
  };
  const handleProductNoteClose = () => {
    setProductNote(false);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditClose = () => {
    setEdit(false);
  };
  const editRender = () => {
    return (
      <Dialog open={edit} maxWidth="xl">
        <DialogActions>
          <Button onClick={handleEditClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Update Product Note</DialogTitle>
        <DialogContent>
          <EditProductNote content={data} onCancel={handleEditClose} />
        </DialogContent>
      </Dialog>
    );
  };
  const viewRender = () => {
    return (
      <Dialog open={viewProductNote} maxWidth="lg">
        <DialogActions>
          <Button onClick={handleProductNoteClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Product Note</DialogTitle>
        <DialogContent>
          <Card>
            <object
              width={width * 0.7}
              height={height * 0.8}
              data={data?.assetUrl}
              type="application/pdf"
            >
              {" "}
            </object>
          </Card>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }}
    >
      <Link component="button" onClick={() => handleProductNote()}>
        View
      </Link>
      <IconButton
        color="default"
        size="small"
        sx={{}}
        onClick={() => handleEdit()}
      >
        <EditIcon />
      </IconButton>
      <IconButton
        color="default"
        size="small"
        sx={{}}
        onClick={() => handleDelete()}
      >
        <DeleteForeverIcon />
      </IconButton>
      <Dialog open={deleteDialog}>
        <DialogTitle>Confirm Delete?</DialogTitle>
        <DialogContent>
          This action will permanently delete the content.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleConfirmDelete(data)} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      {viewRender()}
      {editRender()}
    </Box>
  );
};

export default ProductNoteAction;
