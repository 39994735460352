import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  CardMedia,
  Card,
  Typography,
  CardContent,
  Grid,
} from "@mui/material";
import { ContentActionProps } from "./types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import colors from "res/colors";
import { useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditEventManagement from "./EditEventManagement";

const DigitalContentAction: React.FC<ContentActionProps> = ({
  onDelete,
  onEdit,
  onView,
  data,
  index,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [viewEvent, setEvent] = useState(false);
  const [edit, setEdit] = useState(false);
  const handleConfirmDelete = (data: any) => {
    setDeleteDialog(false);
    onDelete(data, index);
  };

  const handleClose = () => {
    setDeleteDialog(false);
  };

  const handleDelete = () => {
    setDeleteDialog(true);
  };

  const handleInfoView = () => {
    setEvent(true);
  };
  const handleInfoViewClose = () => {
    setEvent(false);
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const handleEditClose = () => {
    setEdit(false);
  };

  const editRender = () => {
    return (
      <Dialog open={edit} maxWidth="xl">
        <DialogActions>
          <Button onClick={handleEditClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Update Event</DialogTitle>
        <DialogContent>
          {/* <EditInforGraphics content={data} onCancel={handleEditClose} /> */}
          <EditEventManagement eventData={data} onCancel={handleEditClose} />
        </DialogContent>
      </Dialog>
    );
  };

  const viewRender = () => {
    return (
      <Dialog open={viewEvent}>
        <DialogActions>
          <Button onClick={handleInfoViewClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>Event</DialogTitle>
        <DialogContent>
          <Card sx={{ maxWidth: 600 }}>
            <Box sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                // height="600"
                image={data?.eventImageUrl}
              />
              <Typography gutterBottom variant="h6" component="div">
                {data?.eventName}
              </Typography>
            </Box>
          </Card>
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {/* <Link component="button" onClick={() => onView(data)}> */}
        <Link component="button" onClick={() => handleInfoView()}>
          View
        </Link>
        <IconButton
          color="default"
          size="small"
          sx={{}}
          onClick={() => handleEdit()}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          color="default"
          size="small"
          sx={{}}
          onClick={() => handleDelete()}
        >
          <DeleteForeverIcon />
        </IconButton>
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            This action will permanently delete the content.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleConfirmDelete(data)} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {viewRender()}
      {editRender()}
    </div>
  );
};

export default DigitalContentAction;
