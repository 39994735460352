import {
  configureStore,
  combineReducers,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { all } from "redux-saga/effects";
// import LocalStore from "utils/LocalStore";

import bannerManagementReducer from "pages/BannerManagement/store/slice";
import fundManagerReducer from "pages/FundManager/store/slice";
import sipPerformanceReducer from "pages/SipPerformance/store/slice";
import salesRMReducer from "pages/SalesRMManagement/store/slice";
import salesRMClusterHeadReducer from "pages/SalesrmClusterHead/store/slice";
import leadManagementReducer from "pages/LeadManagement/store/slice";
import contentManagementReducer from "pages/ContentManagement/store/slice";
import loginReducer from "pages/Login/store/slice";
import digitalPosterReducer from "pages/ContentManagement/DigitalPoster/store/slice";
import infographicsReducer from "pages/ContentManagement/InfoGraphics/store/slice";
import interviewvideosReducer from "pages/ContentManagement/InterviewVideos/store/slice";
import marketinsightsReducer from "pages/ContentManagement/MarketInsights/store/slice";
import onepagerReducer from "pages/ContentManagement/OnePager/store/slice";
import presentationReducer from "pages/ContentManagement/Presentation/store/slice";
import productnoteReducer from "pages/ContentManagement/ProductNote/store/slice";
import producttrainingReducer from "pages/ContentManagement/ProductTraining/store/slice";
import productVideosgReducer from "pages/ContentManagement/ProductVideos/store/slice";
import trainingMaterialsReducer from "pages/ContentManagement/TrainingMaterials/store/slice";
import schemesReducer from "pages/SchemeManagement/store/slice";
import userManagementReducer from "pages/UserManagement/store/slice";
import authReducer from "./auth";
import notificationReducer from "pages/NotificationManagment/store/slice";
import eventsReducer from "pages/EventManagement/store/slice";

import { salesRMSaga } from "pages/SalesRMManagement/store/saga";
import { leadManagementSaga } from "pages/LeadManagement/store/saga";
import { contentManagementSaga } from "pages/ContentManagement/store/saga";
import { loginSaga } from "pages/Login/store/saga";
import { digitalPosterSaga } from "pages/ContentManagement/DigitalPoster/store/saga";
import { infographicsSaga } from "pages/ContentManagement/InfoGraphics/store/saga";
import { interviewVideosSaga } from "pages/ContentManagement/InterviewVideos/store/saga";
import { marketInsightSaga } from "pages/ContentManagement/MarketInsights/store/saga";
import { onepagerSaga } from "pages/ContentManagement/OnePager/store/saga";
import { presentationSaga } from "pages/ContentManagement/Presentation/store/saga";
import { productNoteSaga } from "pages/ContentManagement/ProductNote/store/saga";
import { productTrainingMaterialSaga } from "pages/ContentManagement/ProductTraining/store/saga";
import { productVideosSaga } from "pages/ContentManagement/ProductVideos/store/saga";
import { trainingMaterialSaga } from "pages/ContentManagement/TrainingMaterials/store/saga";
import { schemesSaga } from "pages/SchemeManagement/store/saga";
import { userManagementSaga } from "pages/UserManagement/store/saga";
import { notificationSaga } from "pages/NotificationManagment/store/saga";
import { eventsSaga } from "pages/EventManagement/store/saga";
import { sipPerformanceSaga } from "pages/SipPerformance/store/saga";
import { bannerManagementSaga } from "pages/BannerManagement/store/saga";
import { fundManagerSaga } from "pages/FundManager/store/saga";
import { salesRMSagaClusterHead } from "pages/SalesrmClusterHead/store/saga";
const sagaMiddleware = createSagaMiddleware();

const combinedReducer = combineReducers({
  fundManager: fundManagerReducer,
  bannerManagement: bannerManagementReducer,
  sipPerformance: sipPerformanceReducer,
  salesRM: salesRMReducer,
  salesRMClusterHead: salesRMClusterHeadReducer,
  leadManagement: leadManagementReducer,
  contentManagement: contentManagementReducer,
  login: loginReducer,
  digitalPosterManagement: digitalPosterReducer,
  infographicsManagement: infographicsReducer,
  interviewVideosManagement: interviewvideosReducer,
  marketinsightsManagement: marketinsightsReducer,
  onePagerManagement: onepagerReducer,
  presentationManagement: presentationReducer,
  productnoteManagement: productnoteReducer,
  producttrainingManagement: producttrainingReducer,
  productVideosgManagement: productVideosgReducer,
  trainingMaterialsManagement: trainingMaterialsReducer,
  schemes: schemesReducer,
  userManagement: userManagementReducer,
  auth: authReducer,
  notification: notificationReducer,
  events: eventsReducer,
});

export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  //   if (action.type === "user/logout") {
  //     // LocalStore.setDidRegister(false);

  //     // setHeaderRenewToken('')
  //     // setHeaderAccessToken('')
  //     state = {} as RootState;
  //   }
  return combinedReducer(state, action);
};
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware
    ),
});

function* rootSaga() {
  yield all([
    fundManagerSaga(),
    bannerManagementSaga(),
    sipPerformanceSaga(),
    eventsSaga(),
    notificationSaga(),
    salesRMSaga(),
    salesRMSagaClusterHead(),
    leadManagementSaga(),
    contentManagementSaga(),
    loginSaga(),
    digitalPosterSaga(),
    infographicsSaga(),
    interviewVideosSaga(),
    marketInsightSaga(),
    onepagerSaga(),
    presentationSaga(),
    productNoteSaga(),
    productTrainingMaterialSaga(),
    productVideosSaga(),
    trainingMaterialSaga(),
    schemesSaga(),
    userManagementSaga(),
  ]);
}
sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
