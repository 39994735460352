import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";
import { setHeaderAccessToken } from "networking/NetworkManager";
import LocalStore from "utils/LocalStore";
import {
  generateOtp,
  didGenerateOtp,
  didFailToGenerateOtp,
  resendOtp,
  didResendOtp,
  didFailToResendOtp,
  validateOtp,
  didValidateOtp,
  didFailToValidateOtp,
} from "./slice";
import { setDidLogin } from "store/auth";

const LOGIN_OTP = "v1/admin/generateOtp";
const RESEND_OTP = "v1/admin/resendOtp";
const VALIDATE_OTP = "v1/admin/validateOtp";

function* handleGenerateOtp(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    LOGIN_OTP,
    action.payload
  );
  //
  if (response.error) {
    yield put(didFailToGenerateOtp(response.error));
  } else {
    yield put(didGenerateOtp(response.data.data));
  }
}

function* handleValidateOtp(action: PayloadAction<any>) {
  //

  const response: TResponse = yield call(
    Request.POST,
    VALIDATE_OTP,
    action.payload
  );
  if (response.error) {
    yield put(didFailToValidateOtp(response.error));
  } else {
    LocalStore.saveAccessToken(response.headers["access-token"]);
    LocalStore.saveRefreshToken(response.headers["access-token"]);
    LocalStore.saveUser(response.data.data);
    setHeaderAccessToken(response.headers["access-token"]);
    // setHeaderAccessToken(response.headers["refresh-token"]);
    yield put(didValidateOtp());
    yield put(setDidLogin(true));
    /*
    accessTo: "ALL"
admin: true
deleteAccess: true
emailId: "kishankavoor1@gmail.com"
lastLoginDateTime: "2022-04-06T04:32:42.000+0000"
readAccess: true
writeAccess: true
    */
  }
}

function* handleResendOtp(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    RESEND_OTP,
    action.payload
  );

  if (response.error) {
    yield put(didFailToResendOtp(response.error));
  } else {
    yield put(didResendOtp(response.data.data.otp));
  }
}

function* watcherGenerateOtp() {
  yield takeLatest(generateOtp.type, handleGenerateOtp);
}

function* watcherResendOtp() {
  yield takeLatest(resendOtp.type, handleResendOtp);
}
function* watcherValidateOtp() {
  yield takeLatest(validateOtp.type, handleValidateOtp);
}

export function* loginSaga() {
  yield all([watcherGenerateOtp(), watcherResendOtp(), watcherValidateOtp()]);
}
