import { useEffect, useState } from "react";
import { FormControl, Select } from "@mui/material";
import { Box, Divider, Button, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { TSelectProps } from "./types";
import { SelectOption } from "components/FormSelect/types";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import Text from "components/Text";
import colors from "res/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
const MultiSelect: React.FC<TSelectProps> = ({
  label,
  options,
  value = "",
  placeHolder = "",
  onOptionSelect,
  errorMessage = "",
  defaultValue = [],
}) => {
  const [selectedItems, setSelectedItems] =
    useState<Array<string>>(defaultValue);
  const [showSelect, setShowSelect] = useState(false);
  const _selectOptions = options.map((item: SelectOption) => (
    <MenuItem value={item.value} key={item.value}>
      <Checkbox checked={selectedItems.indexOf(item.title) > -1} />
      <ListItemText primary={item.title} />
    </MenuItem>
    // <option value={item.value} key={item.value} disabled={item.disabled}>

    // </option>
  ));
  useEffect(() => {
    if (defaultValue?.length > 0) {
      setSelectedItems(defaultValue);
    }
  }, [defaultValue]);
  if (placeHolder) {
    _selectOptions.unshift(
      <MenuItem value={""} key={"empty"}>
        {placeHolder}
      </MenuItem>
    );
  }

  const handleChange = (event: { target: { value: any } }) => {
    // const option = options.find((opt) => opt.value === event.target.value);
    // if (option && onOptionSelect) {
    //   onOptionSelect(option);
    // }
    // setCurrentValue(option?.value);
    const {
      target: { value },
    } = event;

    const selected = typeof value === "string" ? value.split(",") : value;
    setSelectedItems(selected);
    if (onOptionSelect) onOptionSelect(selected);
  };
  const showError = Boolean(errorMessage);
  return (
    <FormControl sx={{ width: "100%" }}>
      <Text type="extraSmall" gutterBottom component="div">
        {label}
      </Text>
      <Select
        open={showSelect}
        onOpen={() => setShowSelect(true)}
        onClose={() => setShowSelect(false)}
        onChange={handleChange}
        error={showError}
        displayEmpty
        value={selectedItems}
        renderValue={(selected) => {
          let values =
            selected.length < 3
              ? selected.join(", ")
              : selected.slice(0, 3).join(", ");
          return selected?.length > 3 ? values + ", ..." : values;
        }}
        multiple
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <Button onClick={() => setShowSelect(false)}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </Box>
        {_selectOptions}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            // paddingLeft: "100px",
            // paddingRight: "100px",
            paddingBottom: "10px",
          }}
        >
          <Button
            onClick={() => setShowSelect(false)}
            variant="contained"
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              py: "10px",
              px: "30px",
              fontWeight: 500,
              backgroundColor: colors.backGroundBlue,
            }}
          >
            Submit
          </Button>

          <Button
            onClick={() => setShowSelect(false)}
            variant="contained"
            sx={{
              fontSize: "14px",
              lineHeight: "24px",
              py: "10px",
              px: "30px",
              fontWeight: 500,
              backgroundColor: colors.backGroundBlue,
            }}
          >
            Cancel
          </Button>
        </Box> */}
      </Select>
      {errorMessage ? (
        <Text type="mediumBold" color="red">
          {errorMessage}
        </Text>
      ) : null}
    </FormControl>
  );
};
export default MultiSelect;
