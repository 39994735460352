import React from "react";
import { useFormContext } from "react-hook-form";
import Select from "../Select";
import { FormSelectProps, SelectOption } from "./types";
export const FormSelect: React.FC<FormSelectProps> = ({
  name,
  onOptionSelect,
  errorMessage,
  ...inputProps
}) => {
  const formContext = useFormContext();
  const {
    setValue,
    getValues,
    // control,
    formState: { errors },
  } = formContext;
  const handleSelection = (option: SelectOption) => {
    setValue(name, option.value, { shouldValidate: true });
    if (onOptionSelect) {
      onOptionSelect(option);
    }
  };

  return (
    <Select
      {...inputProps}
      errorMessage={errorMessage ? errorMessage : errors[name]?.message}
      onOptionSelect={handleSelection}
      value={getValues(name)}
    />
  );
};

export default FormSelect;
