import { Box, Button, Card, Divider } from "@mui/material";
import Text from "components/Text/Text";
import React, { useEffect, useRef, useState } from "react";
import ImageDropzone from "components/ImageDropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import colors from "res/colors";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  loadFundManagers,
  resetFundManagerStates,
  resetFundManagerUpdateStates,
  updateFundManager,
  uploadFundManager,
} from "./store/slice";
import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";
import FundManagerContents from "./FundManagerContents";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "components/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import { TContentForm } from "./types";

export interface EditContentrProps {
  content: any;
  onCancel: () => void;
}

const EditFundManager: React.FC<EditContentrProps> = ({
  content,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const [imageError, setImageError] = useState("");
  const [imageDetails, setimageDetails] = useState<any>("");
  const imageDropzoneBannerRef = useRef<any>(null);
  const handleImageDrop = (imageData: { url: string; name: string }) => {
    setImageError("");
    setimageDetails(imageData);
  };
  const { didUpdateFundManager } = useAppSelector((state) => state.fundManager);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("name", content?.fundManagerName);
    if (didUpdateFundManager) {
      ToastCenter.success("Successfully updated fund manager.");
      formMethods.setValue("name", "");
      imageDropzoneBannerRef.current.clearImage();
      setimageDetails("");
      onCancel();
      dispatch(resetFundManagerUpdateStates());
    }
  }, [didUpdateFundManager]);

  const handleOnUpload = (data: TContentForm) => {
    // let hasError = false;

    // if (!imageDetails) {
    //   setImageError("Please upload fund manager image");
    //   hasError = true;
    // }
    // if (hasError) {
    //   return;
    // }
    const params = {
      image: imageDetails,
      imageRequest: {
        fundManagerId: content?.fundManagerId,
        fundManagerName: data?.name,
      },
    };

    dispatch(updateFundManager(params));
  };

  return (
    <Box sx={{ mt: "20px", width: "700px" }}>
      <Box
        sx={{
          pb: "16px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text type="mediumBold">Fund Manager Name</Text>
        {/* <Text
          type="mediumBold"
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "20px",
            fontFamily: "Graphik",
            color: colors.radicalRed,
          }}
        >
          *
        </Text> */}
      </Box>
      <FormProvider {...formMethods}>
        <FormInput
          variant="filled"
          name="name"
          InputProps={{
            disableUnderline: true,
          }}
          label="Name"
        />
      </FormProvider>
      <Box sx={{ mt: "20px" }} />
      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneBannerRef}
        heading={`Upload Fund Manager Image`}
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={imageError}
        required
        resolution={"500w X 500h | File types: .jpg, .jpeg, .png"}
      />
      {imageDetails && <Text type="medium">{imageDetails?.name}</Text>}
      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box
        sx={{
          mt: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(handleOnUpload)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",

            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          onClick={onCancel}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );
};
export default EditFundManager;
