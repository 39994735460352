import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import { TProductNotesParams, TProductNoteDetailsParams } from "./types";
import ToastCenter from "utils/ToastCenter";
import Request from "networking/Request";
import {
  loadProductNotes,
  didLoadProductNotes,
  didFailToLoadProductNotes,
  deleteProductNotes,
  didDeleteProductNotes,
  didFailedToDeleteProductNotes,
} from "./slice";

const PRODUCT_NOTES_ENDPOINT = "v1/admin/productnote/fetchAll";
const PRODUCT_NOTE_DELETED = "v1/admin/content/delete";

function* handleLoadProductNotes(action: PayloadAction<TProductNotesParams>) {
  const response: TResponse = yield call(
    Request.POST,
    PRODUCT_NOTES_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadProductNotes(response.error));
  } else {
    yield put(didLoadProductNotes(response.data.data.resultList));
  }
}

function* handleDeleteProductNote(action: PayloadAction<TProductNotesParams>) {
  const response: TResponse = yield call(
    Request.POST,
    PRODUCT_NOTE_DELETED,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailedToDeleteProductNotes(response?.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteProductNotes(action?.payload?.id));
  }
}

function* watcherLoadProductNotes() {
  yield takeLatest(loadProductNotes.type, handleLoadProductNotes);
}

function* watcherDeleteProductNotes() {
  yield takeLatest(deleteProductNotes.type, handleDeleteProductNote);
}

export function* productNoteSaga() {
  yield all([watcherLoadProductNotes(), watcherDeleteProductNotes()]);
}
