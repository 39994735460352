import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SalesRMClusterHeadState = {
  isUploadingSalesRMClusterHeadList: boolean;
  uploadSalesRMClusterHeadListError: string;
  didUploadSalesRMClusterHeadList: boolean;

  isDeletingSalesRMClusterHead: boolean;
  didDeleteSalesRMClusterHead: boolean;
  deleteSalesRMClusterHeadError: string;

  isLoadingSalesRMClusterHeads: boolean;
  salesRMClusterHeadsLoadingError: string;
  salesRMClusterHeads: Array<any>;

  salesRMClusterHeadsCount: number;
};

const INITIAL_STATE: SalesRMClusterHeadState = {
  isUploadingSalesRMClusterHeadList: false,
  uploadSalesRMClusterHeadListError: "",
  didUploadSalesRMClusterHeadList: false,

  isDeletingSalesRMClusterHead: false,
  didDeleteSalesRMClusterHead: false,
  deleteSalesRMClusterHeadError: "",

  isLoadingSalesRMClusterHeads: false,
  salesRMClusterHeadsLoadingError: "",
  salesRMClusterHeads: [],

  salesRMClusterHeadsCount: 0,
};

const salesRMClusterHead = createSlice({
  name: "salesRMClusterHead",
  initialState: INITIAL_STATE,
  reducers: {
    uploadSalesRMClusterHeadList: (state, _action: PayloadAction<any>) => {
      state.isUploadingSalesRMClusterHeadList = true;
    },
    didUploadSalesRMClusterHeadList: (state) => {
      state.isUploadingSalesRMClusterHeadList = false;
      state.didUploadSalesRMClusterHeadList = true;
    },
    didFailToUploadSalesRMClusterHeadList: (
      state,
      action: PayloadAction<any>
    ) => {
      state.isUploadingSalesRMClusterHeadList = false;
      state.uploadSalesRMClusterHeadListError = action.payload;
    },

    deleteSalesRMClusterHead: (state, _action: PayloadAction<any>) => {
      state.isDeletingSalesRMClusterHead = true;
    },

    didDeleteSalesRMClusterHead: (state) => {
      state.isDeletingSalesRMClusterHead = false;
      state.didDeleteSalesRMClusterHead = true;
    },
    didFailToDeleteSalesRMClusterHead: (state, action: PayloadAction<any>) => {
      state.isDeletingSalesRMClusterHead = false;
      state.deleteSalesRMClusterHeadError = action.payload;
    },

    loadSalesRMClusterHeads: (state, _action: PayloadAction<any>) => {
      state.isLoadingSalesRMClusterHeads = true;
      state.salesRMClusterHeads = [];
      state.salesRMClusterHeadsLoadingError = "";
      state.salesRMClusterHeadsCount = 0;
    },
    didFailToLoadSalesRMClusterHeads: (state, _action: PayloadAction<any>) => {
      state.isLoadingSalesRMClusterHeads = false;
      state.salesRMClusterHeadsLoadingError = _action.payload;
      state.salesRMClusterHeads = [];
      state.salesRMClusterHeadsCount = 0;
    },
    didLoadSalesRMClusterHeads: (state, action: PayloadAction<any>) => {
      state.isLoadingSalesRMClusterHeads = false;
      state.salesRMClusterHeads = action.payload?.clusterHeadDetailsModelList;
      state.salesRMClusterHeadsCount = action.payload?.totalCount;
    },

    resetUploadSalesRMClusterHeadListStates: (state) => {
      state.isUploadingSalesRMClusterHeadList = false;
      state.didUploadSalesRMClusterHeadList = false;
      state.uploadSalesRMClusterHeadListError = "";
    },

    resetDeleteSalesRMClusterHeadStates: (state) => {
      state.isDeletingSalesRMClusterHead = false;
      state.didDeleteSalesRMClusterHead = false;
      state.deleteSalesRMClusterHeadError = "";
    },
  },
});

export const {
  resetDeleteSalesRMClusterHeadStates,
  deleteSalesRMClusterHead,
  didDeleteSalesRMClusterHead,
  didFailToDeleteSalesRMClusterHead,
  uploadSalesRMClusterHeadList,
  didUploadSalesRMClusterHeadList,
  didFailToUploadSalesRMClusterHeadList,
  resetUploadSalesRMClusterHeadListStates,
  loadSalesRMClusterHeads,
  didFailToLoadSalesRMClusterHeads,
  didLoadSalesRMClusterHeads,
} = salesRMClusterHead.actions;
export default salesRMClusterHead.reducer;
