import * as yup from "yup";

const PHONE_REG_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const rmDataValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .min(5)
    .max(32)
    .required("Please enter a valid email"),

  name: yup.string().required("Kindly enter your Name"),
  // employeeId: yup.string().required("Kindly enter your Employee ID"),
  mobile: yup
    .string()
    .min(10, "Invalid Phone Number")
    .required()
    .matches(PHONE_REG_EXP, "Please enter valid 10 digit Mobile Number"),

  // arn: yup.string().required("Please enter a valid ARN"),
});
