import React from "react";
// import "@pathofdev/react-tag-input/build/index.css";
import ReactTagInput from "@pathofdev/react-tag-input";
import "./TagInput.css";
const inR = () => {
 
};
const TagInput = () => {
  const [tags, setTags] = React.useState<Array<string>>([]);
  return (
    <ReactTagInput
      tags={tags}
      onChange={(newTags) => setTags(newTags)}
      placeholder="Enter distributors ARN number"
      // onClick={inR}
    />
  );
};
export default TagInput;
