import {
  Box,
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Text from "components/Text";
import { FC, useState } from "react";
import colors from "res/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import { RmList } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { salesRmProps } from "../types";
import { yupResolver } from "@hookform/resolvers/yup";
import { rmDataValidationSchema } from "../RmDataValidationSchema";
import FormInput from "components/FormInput";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  updateSalesRM,
  loadSalesRMs,
  resetSalesRMUpdateStates,
} from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
export interface RmListItemProps {
  data: RmList;
  onDelete: (data: RmList) => void;
  onEdit: (data: RmList) => void;
}
const RmListItem: FC<RmListItemProps> = ({ data, onDelete, onEdit }) => {
  const [expanded, setExpanded] = useState(false);
  const [viewMFDList, setViewMFDList] = useState(false);
  const [dialog, setDialog] = useState("");
  const dispatch = useAppDispatch();

  const { didUpdateSalesRM } = useAppSelector((state) => state.salesRM);

  useEffect(() => {
    formMethods.setValue("address", data.address1);
    formMethods.setValue("city", data.city);
    formMethods.setValue("email", data.email);
    formMethods.setValue("name", data.name);
    formMethods.setValue("mobile", data.mobileNum);
    formMethods.setValue("pincode", data.pinCode);
    formMethods.setValue("state", data.state);

    if (didUpdateSalesRM) {
      handleClose();
      // ToastCenter.success("Successfully updated SalesRM");
      // handleClose();
      // dispatch(resetSalesRMUpdateStates());
      // dispatch(
      //   loadSalesRMs({
      //     pageNumber: 1,
      //     endPageNumber: 100,
      //   })
      // );
    }
  }, [didUpdateSalesRM, data]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const renderExpandIcon = () => {
    if (expanded) return <ExpandLessIcon />;
    return <ExpandMoreIcon />;
  };
  const handleClose = () => {
    setDialog("");
  };

  const handleButtonClick = (type: string) => {
    setDialog(type);
  };

  // const handleConfirmEdit = (data: RmList) => {
  //   setDialog("");
  //
  //   onEdit(data);
  // };

  const handleConfirmDelete = (data: any) => {
    setDialog("");
    onDelete(data);
  };

  const handleMFDView = () => {
    setViewMFDList(true);
  };
  const handleMFDViewClose = () => {
    setViewMFDList(false);
  };

  const formMethods = useForm<salesRmProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(rmDataValidationSchema),
  });

  const onSubmit = (rmdata: salesRmProps) => {
    const params = {
      email: rmdata.email,
      mobileNum: rmdata.mobile,
      name: rmdata.name,
      // status: "active",
      address1: rmdata.address,
      state: rmdata.state,
      city: rmdata.city,
      pinCode: rmdata.pincode,
    };
    dispatch(updateSalesRM(params));
  };

  const renderDialog = () => {
    if (dialog === "delete") {
      return (
        <Dialog open={dialog === "delete"}>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            This action will permanently delete the data.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleConfirmDelete(data)} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (dialog === "edit") {
      return (
        <Dialog open={dialog === "edit"}>
          <DialogTitle>Edit data</DialogTitle>
          <DialogContent>
            <FormProvider {...formMethods}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormInput
                  variant="filled"
                  label="Name"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"name"}
                />
                {/* <FormInput
                  variant="filled"
                  label="Employee Id"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"employeeId"}
                /> */}
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
                <FormInput
                  variant="filled"
                  label="Mobile Number"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"mobile"}
                />
                <FormInput
                  variant="filled"
                  label="Email"
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  name={"email"}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
                <FormInput
                  variant="filled"
                  label="Address"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"address"}
                />
                <FormInput
                  variant="filled"
                  label="State"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"state"}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
                <FormInput
                  variant="filled"
                  label="City"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"city"}
                />
                <FormInput
                  variant="filled"
                  label="Pincode"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"pincode"}
                />
              </Box>
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ mr: "8px" }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={formMethods.handleSubmit(onSubmit)} autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };
  const renderExpandedView = () => {
    if (expanded) {
      return (
        <div>
          <Grid container direction="row" sx={{ mt: "16px" }}>
            <Grid xs={4.5}>
              <Text type="listSmallBold">Email Id</Text>
              <Text type="listSmall">{data.email}</Text>
            </Grid>
            <Grid xs={2.5}>
              <Text type="listSmallBold">Mapped Distributors</Text>
              <Button
                sx={{
                  fontSize: "13px",
                  py: 0,
                  px: 0,
                  lineHeight: "14px",
                  fontFamily: "Graphik",
                }}
                onClick={() => {
                  setViewMFDList(true);
                }}
              >
                View List
              </Button>
            </Grid>
          </Grid>

          <Box sx={{ py: "20px" }}>
            <Divider />
          </Box>

          {/** Hiding the edit and delete button as per the requirement */}
          {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Box>
              <IconButton
                sx={{ mr: "10px" }}
                onClick={() => handleButtonClick("edit")}
              >
                <EditOutlinedIcon sx={{ color: colors.backGroundBlue }} />
              </IconButton> */}

          {/* <IconButton>   //This part is hidded earlier
                <DoNotDisturbAltOutlinedIcon
                  sx={{ color: colors.backGroundBlue, mx: "25px" }}
                />
              </IconButton> */}
          {/* <Button
                variant="outlined"
                onClick={() => handleButtonClick("delete")}
                sx={{
                  backgroundColor: colors.white,
                  color: colors.backGroundBlue,

                  borderColor: colors.backGroundBlue,
                }}
              >
                Delete User
              </Button>
              {renderDialog()}
            </Box>
          </Box> */}
        </div>
      );
    }
  };

  const mfdViewListRender = () => {
    return (
      <Dialog open={viewMFDList} fullWidth maxWidth="lg">
        <DialogActions>
          <Button onClick={handleMFDViewClose}>
            <Grid item xs={8}>
              <CloseOutlinedIcon />
            </Grid>
          </Button>
        </DialogActions>
        <DialogTitle>MFDLIST</DialogTitle>
        <DialogContent>
          {data?.mfd?.map((item: any, index: number) => {
            return (
              <Card
                sx={{
                  mb: "12px",
                  pb: "10px",
                  pt: "5px",
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <Grid
                    container
                    direction="row"
                    sx={{ pl: "10px", mb: "16px", pr: "10px" }}
                  >
                    <Grid xs={1}>
                      <Text type="listMediumBold">Sl. No.</Text>
                    </Grid>
                    <Grid xs={3}>
                      <Text type="listMediumBold">Name</Text>
                    </Grid>
                    <Grid xs={2}>
                      <Text type="listMediumBold">ARN</Text>
                    </Grid>
                    <Grid xs={3.2}>
                      <Text type="listMediumBold">Address</Text>
                    </Grid>
                    <Grid xs={0.3}>
                      <Box></Box>
                    </Grid>
                    <Grid xs={2}>
                      <Text type="listMediumBold">Mobile No.</Text>
                    </Grid>

                    <Grid xs={0.5}>
                      <Box></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Grid
                    container
                    direction="row"
                    sx={{ pl: "10px", mb: "16px", pr: "10px" }}
                  >
                    <Grid xs={1}>
                      <Text type="listMedium">{index + 1}</Text>
                    </Grid>
                    <Grid xs={3}>
                      <Text type="listMedium">{item?.name}</Text>
                    </Grid>
                    <Grid xs={2}>
                      <Text type="listMedium">{item?.arnNumber}</Text>
                    </Grid>
                    <Grid xs={3.2}>
                      <Text type="listMedium">{item?.address}</Text>
                    </Grid>
                    <Grid xs={0.3}>
                      <Box></Box>
                    </Grid>
                    <Grid xs={2}>
                      <Text type="listMedium">{item?.mobile}</Text>
                    </Grid>

                    <Grid xs={0.5}>
                      <Box></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ pr: "40px", pb: "15px", pl: "95px" }}>
                  <Divider />
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Grid
                    container
                    direction="row"
                    sx={{ pl: "10px", mb: "16px", pr: "10px" }}
                  >
                    <Grid xs={1}>
                      <Box></Box>
                    </Grid>
                    <Grid xs={3}>
                      <Text type="listMediumBold">City</Text>
                    </Grid>
                    <Grid xs={2}>
                      <Text type="listMediumBold">State</Text>
                    </Grid>
                    <Grid xs={3.5}>
                      <Text type="listMediumBold">Status</Text>
                    </Grid>
                    <Grid xs={0.5}>
                      <Box></Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ position: "relative" }}>
                  <Grid
                    container
                    direction="row"
                    sx={{ pl: "10px", mb: "16px", pr: "10px" }}
                  >
                    <Grid xs={1}>
                      <Box></Box>
                    </Grid>
                    <Grid xs={3}>
                      <Text type="listMedium">{item?.city}</Text>
                    </Grid>
                    <Grid xs={2} sx={{ ml: "2px" }}>
                      <Text type="listMedium">{item?.state}</Text>
                    </Grid>
                    <Grid xs={3.5}>
                      <Text type="listMedium">{item?.status}</Text>
                    </Grid>
                    <Grid xs={0.5}>
                      <Box></Box>
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            );
          })}
        </DialogContent>
      </Dialog>
    );
  };
  return (
    <Box>
      <Card sx={{ px: "20px", mb: "12px", pt: "20px", pb: "10px" }}>
        <Grid>
          <Grid container direction="row">
            <Grid xs={4.5}>
              <Text type="listMediumBold">{data.name}</Text>
            </Grid>
            {/* <Grid xs={2.5}>
              <Text type="listMedium">{data.employeeId}</Text>
            </Grid> */}
            <Grid xs={5}>
              <Text type="listMedium">{data.mobileNum}</Text>
            </Grid>
            <Grid xs={1.5}>
              <Text type="listMedium">{data.status}</Text>
            </Grid>
            <Grid xs={0.5}>
              <Box
                sx={{
                  mt: "-10px",
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                }}
              >
                <IconButton onClick={handleExpand}>
                  {renderExpandIcon()}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {renderExpandedView()}
        {viewMFDList && mfdViewListRender()}
      </Card>
    </Box>
  );
};

export default RmListItem;
