import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { loadProductTraining, deleteProductTraining } from "./store/slice";
import { Button, Card, Divider, Grid, IconButton, Link } from "@mui/material";
import colors from "res/colors";
import Text from "components/Text";
import { Box } from "@mui/system";
import ProductTrainingContentItem from "./ProductTrainingContentItem";

const ProductTrainingContents = () => {
  const dispatch = useAppDispatch();

  const {
    productTrainingError,
    productTrainingData,
    isDeleteProductTraining,
    productTrainingDeleted,
  } = useAppSelector((state) => state.producttrainingManagement);
  const [productData, setProductData] = useState([]);

  const { didUpdateContent } = useAppSelector(
    (state) => state.contentManagement
  );
  useEffect(() => {
    dispatch(
      loadProductTraining({
        pageNo: 1,
        size: 100,
        userId: "ARN-3343",
      })
    );
  }, [didUpdateContent]);

  useEffect(() => {
    if (productTrainingData && productTrainingData?.length > 0) {
      setProductData(productTrainingData);
    }
  }, [productTrainingData]);
  useEffect(() => {
    if (productTrainingDeleted) {
      const data = productData?.filter(
        (ele: any) => ele?.id !== productTrainingDeleted
      );
      setProductData(data);
    }
  }, [productTrainingDeleted]);

  const handleContentDelete = (rowData: any, index: number) => {
    const deleteData = {
      id: rowData.id,
      contentCategories: "PRODUCT_TRAINING",
      index: index,
    };
    dispatch(deleteProductTraining(deleteData));
  };
  const handleContentEdit = (rowData: any) => {};
  const handleContentView = (rowData: any) => {};

  return (
    <div>
      <Box>
        <Box
          sx={{
            pt: "48px",
            pb: "16px",
          }}
        >
          <Text type="subHeadingBold">Updated Content</Text>
        </Box>

        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "16px",
            pt: "42px",
            pb: "20px",
            px: "32px",
          }}
        >
          <Grid container columnSpacing={"40px"}>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Section"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Post Title"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{" Upload Date"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{" Upload File"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Action"}</Text>
            </Grid>
          </Grid>
          <Box sx={{ mt: "16px", mb: "20px" }}>
            <Divider />
          </Box>
          {productData?.map((rowData: any, index: number) => {
            return (
              <ProductTrainingContentItem
                key={rowData.id}
                data={rowData}
                index={index}
                handleContentDelete={handleContentDelete}
                handleContentEdit={handleContentEdit}
                handleContentView={handleContentView}
              />
            );
          })}
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.lightButtonBlue,
                color: colors.backGroundBlue,
              }}
            >
              See More
            </Button>
          </Box> */}
        </Card>
      </Box>
    </div>
  );
};

export default ProductTrainingContents;
