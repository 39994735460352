import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  uploadContent,
  didUploadContent,
  didFailToUploadContent,
  updateContent,
  didUpdateContent,
  didFailToUpdateContent,
} from "./slice";
import ToastCenter from "utils/ToastCenter";

const UPLOAD_ENDPOINT = "v1/admin/content/upload";
const UPDATE_ENDPOINT = "v1/admin/content/update";

// find sales rm
// update sales rm
function* handleUpload(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadContent,
    UPLOAD_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToUploadContent(response.error));
  } else {
    yield put(didUploadContent());
  }
}

function* handleUpdate(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadContent,
    UPDATE_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to update content.");
    yield put(didFailToUpdateContent(""));
  } else {
    yield put(didUpdateContent());
  }
}

function* watcherUpload() {
  yield takeLatest(uploadContent.type, handleUpload);
}

function* watcherUpdate() {
  yield takeLatest(updateContent.type, handleUpdate);
}
export function* contentManagementSaga() {
  yield all([watcherUpload(), watcherUpdate()]);
}
