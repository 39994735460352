import Warning from "@mui/icons-material/Warning";
import { Box, Card } from "@mui/material";
import ToggleOption from "components/ToggleOption";
import { useState } from "react";
import colors from "res/colors";
import AssignedLead from "./AssignedLead";
import ManualAdd from "./ManualAdd";
import UploadLead from "./UploadLead";
import Text from "components/Text";

const AddLead = () => {
  const [assignLeadType, setAssignLeadType] = useState("upload");

  const assignLeadOptions = [
    { label: "upload" },
    //  { label: "manually" }
  ];

  const handleAssignLeadOption = (displayComponent: string) => {
    setAssignLeadType(displayComponent);
  };

  const renderLeadOption = () => {
    if (assignLeadType === "upload") {
      return <UploadLead />;
    }
    return <ManualAdd />;
  };
  return (
    <Box>
      <Card sx={{ py: "40px", px: "40px", borderRadius: "16px", mt: "20px" }}>
        <Box sx={{ display: "flex", flexDirection: "row", mb: "25px" }}>
          <ToggleOption
            options={assignLeadOptions}
            onClick={handleAssignLeadOption}
            selected={assignLeadType}
          />
        </Box>
        {/* <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
          <Text type="medium" sx={{ color: colors.red }}>
            Warning : Please do not upload Leads during business hours (9 AM - 5
            PM).
          </Text>
        </Box> */}
        {renderLeadOption()}
      </Card>
      <AssignedLead />
    </Box>
  );
};
export default AddLead;
