import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  uploadSalesRMClusterHeadList,
  didUploadSalesRMClusterHeadList,
  didFailToUploadSalesRMClusterHeadList,
  loadSalesRMClusterHeads,
  didFailToLoadSalesRMClusterHeads,
  didLoadSalesRMClusterHeads,
  deleteSalesRMClusterHead,
  didDeleteSalesRMClusterHead,
  didFailToDeleteSalesRMClusterHead,
} from "./slice";
import ToastCenter from "utils/ToastCenter";

const UPLOAD_ENDPOINT = "v1/admin/importClusterHeadDetails";
const DELETE_NEW_ENDPOINT = "v1/admin/deleteClusterHeadDetails";
const SALESRM_CLUSTER_HEAD_LIST_ENDPOINT =
  "v1/admin/fetchClusterHeadListByPage";

function* handleExcelUpload(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    UPLOAD_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to upload content."
    );
    yield put(didFailToUploadSalesRMClusterHeadList(response.error));
  } else {
    yield put(didUploadSalesRMClusterHeadList());
  }
}

function* handleLoadSalesRMClusterHeads(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    SALESRM_CLUSTER_HEAD_LIST_ENDPOINT,
    params
  );

  if (response.error) {
    yield put(didFailToLoadSalesRMClusterHeads(response.error));
  } else {
    yield put(didLoadSalesRMClusterHeads(response.data.data));
  }
}

function* handleSalesRMClusterHeadDelete(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    DELETE_NEW_ENDPOINT,
    params
  );

  if (response.error) {
    ToastCenter.error(
      response.error ? response.error : "Unable to delete content."
    );
    yield put(didFailToDeleteSalesRMClusterHead(response.error));
  } else {
    yield put(didDeleteSalesRMClusterHead());
  }
}

function* watcherUpload() {
  yield takeLatest(uploadSalesRMClusterHeadList.type, handleExcelUpload);
}

function* watcherLoadSalesRMClusterHeads() {
  yield takeLatest(loadSalesRMClusterHeads.type, handleLoadSalesRMClusterHeads);
}

function* watcherSalesRMClusterHeadDelete() {
  yield takeLatest(
    deleteSalesRMClusterHead.type,
    handleSalesRMClusterHeadDelete
  );
}

export function* salesRMSagaClusterHead() {
  yield all([
    watcherSalesRMClusterHeadDelete(),
    watcherLoadSalesRMClusterHeads(),
    watcherUpload(),
  ]);
}
