import * as yup from "yup";
export const contentValidationSchema = yup.object().shape({
  // title: yup.string().min(5).required("Please enter a valid title"),
  description: yup
    .string()
    // .min(15)
    .required("Please enter a valid description"),
  //   thumbnail_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
  //   detail_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
  //   material_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
});
