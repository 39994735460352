import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { Button, Card, Divider, Grid, IconButton, Link } from "@mui/material";
import colors from "res/colors";
import Text from "components/Text";
import { Box } from "@mui/system";
import SchemeBannerContentItem from "./SchemeBannerContentItem";
import {
  deleteSchemeBanner,
  loadSchemeBanner,
} from "pages/BannerManagement/store/slice";

const SchemeBannerContents = () => {
  const dispatch = useAppDispatch();
  const schemeBanner = [
    {
      description: "Hellooo Hi How are you",
      assetUrl:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNy2ueFURNixpZ4_f5euO0bt7woLgx2hVTEw&usqp=CAU",
      updatedOn: "10/11/2022",
    },
  ];
  const [schemeBannerList, setSchemeBannerList] = useState([]);
  const { didUpdateSchemeBanner, schemeBannerData, schemeBannerDeleted } =
    useAppSelector((state) => state.bannerManagement);
  useEffect(() => {
    dispatch(loadSchemeBanner({ schemeDetailFlag: false }));
  }, [didUpdateSchemeBanner]);

  useEffect(() => {
    if (schemeBannerData && schemeBannerData?.length > 0) {
      setSchemeBannerList(schemeBannerData);
    }
  }, [schemeBannerData]);
  useEffect(() => {
    if (schemeBannerDeleted) {
      const data = schemeBannerList?.filter(
        (ele: any) => ele?.bannerId !== schemeBannerDeleted
      );
      setSchemeBannerList(data);
    }
  }, [schemeBannerDeleted]);

  const handleContentDelete = (rowData: any, index: number) => {
    // const deleteData = {
    //   id: rowData.bannerId,
    // };
    dispatch(deleteSchemeBanner(rowData.bannerId));
  };

  const handleContentEdit = (rowData: any) => {};
  const handleContentView = (rowData: any) => {};

  return (
    <div>
      <Box>
        <Box
          sx={{
            pt: "20px",
            pb: "16px",
          }}
        >
          <Text type="subHeadingBold">Updated Content</Text>
        </Box>

        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "16px",
            pt: "42px",
            pb: "20px",
            px: "32px",
          }}
        >
          <Grid container columnSpacing={"40px"}>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Section"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Title"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{" Upload Date"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Banner Image"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Action"}</Text>
            </Grid>
          </Grid>
          <Box sx={{ mt: "16px", mb: "20px" }}>
            <Divider />
          </Box>
          {schemeBannerList?.map((rowData: any, index: number) => {
            return (
              <SchemeBannerContentItem
                key={rowData.id}
                data={rowData}
                index={index}
                handleContentDelete={handleContentDelete}
                handleContentEdit={handleContentEdit}
                handleContentView={handleContentView}
              />
            );
          })}
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.lightButtonBlue,
                color: colors.backGroundBlue,
              }}
            >
              See More
            </Button>
          </Box> */}
        </Card>
      </Box>
    </div>
  );
};

export default SchemeBannerContents;
