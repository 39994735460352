import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  createEvent,
  deleteEvent,
  didCreateEvent,
  didDeleteEvent,
  didFailToCreateEvent,
  didFailToDeleteEvent,
  didFailToLoadEvent,
  didFailToUpdateEvent,
  didLoadEvent,
  didUpdateEvent,
  loadEvent,
  updateEvent,
} from "./slice";
import ToastCenter from "utils/ToastCenter";

const CREATE_EVENT_ENDPOINT = "v1/admin/events/create";
const ALL_EVENT_ENDPOINT = "v1/admin/events/allEvent";
const DELETE_EVENT_ENDPOINT = "v1/admin/events/deleteEvent";
const EDIT_EVENT_ENDPOINT = "/v1/admin/events/editEvent";

function* handleCreateEvent(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.createEvent,
    CREATE_EVENT_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToCreateEvent(response.error));
  } else {
    yield put(didCreateEvent());
  }
}
function* handleLoadEvent(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    ALL_EVENT_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadEvent(response.error));
  } else {
    yield put(didLoadEvent(response.data.data));
  }

  //
}
function* handleDeleteEvent(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    DELETE_EVENT_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to delete content");

    yield put(didFailToDeleteEvent(response.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteEvent(action?.payload?.uniqueReference));
  }

  //
}
function* handleUpdateEvent(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.createEvent,
    EDIT_EVENT_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to update content.");
    // yield put(didFailToUpdateEvent(response.error));
    yield put(didFailToUpdateEvent(""));
  } else {
    yield put(didUpdateEvent());
  }

  //
}

function* watcherCreateEvent() {
  yield takeLatest(createEvent.type, handleCreateEvent);
}
function* watcherLoadEvent() {
  yield takeLatest(loadEvent.type, handleLoadEvent);
}
function* watcherDeleteEvent() {
  yield takeLatest(deleteEvent.type, handleDeleteEvent);
}
function* watcherUpdateEvent() {
  yield takeLatest(updateEvent.type, handleUpdateEvent);
}
export function* eventsSaga() {
  yield all([
    watcherCreateEvent(),
    watcherLoadEvent(),
    watcherDeleteEvent(),
    watcherUpdateEvent(),
  ]);
}
