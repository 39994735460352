import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SalesRMState = {
  isUploadingContent: boolean;
  uploadContentError: string;
  didUploadContent: boolean;

  isUpdateContent: boolean;
  updateContentError: string;
  didUpdateContent: boolean;
};

const INITIAL_STATE: SalesRMState = {
  isUploadingContent: false,
  uploadContentError: "",
  didUploadContent: false,

  isUpdateContent: false,
  updateContentError: "",
  didUpdateContent: false,
};

const contentManagement = createSlice({
  name: "contentManagement",
  initialState: INITIAL_STATE,
  reducers: {
    uploadContent: (state, _action: PayloadAction<any>) => {
      state.isUploadingContent = true;
    },
    didUploadContent: (state) => {
      state.isUploadingContent = false;
      state.didUploadContent = true;
    },
    didFailToUploadContent: (state, action: PayloadAction<any>) => {
      state.isUploadingContent = false;
      state.uploadContentError = action.payload;
    },
    resetStates: (state) => {
      state.isUploadingContent = false;
      state.didUploadContent = false;
      state.uploadContentError = "";
    },

    updateContent: (state, _action: PayloadAction<any>) => {
      state.isUpdateContent = true;
      state.didUpdateContent = false;
    },
    didUpdateContent: (state) => {
      state.isUpdateContent = false;
      state.didUpdateContent = true;
    },
    didFailToUpdateContent: (state, action: PayloadAction<any>) => {
      state.isUpdateContent = false;
      state.updateContentError = action.payload;
    },
    resetUpdateContent: (state) => {
      state.updateContentError = "";
      state.isUpdateContent = false;
      state.didUpdateContent = false;
    },
  },
});

export const {
  resetUpdateContent,
  uploadContent,
  didUploadContent,
  didFailToUploadContent,
  updateContent,
  didUpdateContent,
  didFailToUpdateContent,
  resetStates,
} = contentManagement.actions;
export default contentManagement.reducer;
