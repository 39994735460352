import { Box, Card } from "@mui/material";

import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import UpdatedContent from "components/UpdatedContent";
import React, { useEffect } from "react";

import Webinars from "./Webinars";
import { useAppSelector } from "hooks/useAppSelector";
import ActivityOverlay from "components/ActivityOverlay";
import { useAppDispatch } from "hooks";
import { loadEvent } from "./store/slice";
import EventContents from "./EventContents";

const EventManagement = () => {
  const {
    isCreatingEvent,
    isLoadingEvent,
    loadEventError,
    allEvent,
    isDeletingEvent,
    didCreateEvent,
  } = useAppSelector((state) => state.events);
  const [eventtOption, setEventOption] = React.useState("Webinars");
  const dispatch = useAppDispatch();
  const eventManagementOptions = [
    { label: "Webinars" },
    { label: "Training" },
    // { label: "Events" },
  ];

  useEffect(() => {
    dispatch(
      loadEvent({
        pageNumber: 0,
        size: 100,
      })
    );
  }, [didCreateEvent]);

  const rows: any = [];

  const columns: any = [];
  const handleContentDelete = (rowData: any) => {};
  const handleContentEdit = (rowData: any) => {};
  const handleContentView = (rowData: any) => {};

  const handleEventOption = (eventOptionSelected: string) => {
    setEventOption(eventOptionSelected);
  };

  const renderEventOptions = () => {
    return <Webinars eventType={eventtOption.toUpperCase()} />;
  };

  const handleEventClick = (displayComponent: string) => {
    setEventOption(displayComponent);
  };

  return (
    <Box
      sx={{
        pt: "50px",
        ml: "20%",
        minHeight: "vh",
        pb: "40px",
        pl: "20px",
        width: "65%",
      }}
    >
      <Text type="headingBold">Schedule Events</Text>
      <Box sx={{ pt: "32px", pb: "20px" }}>
        <ToggleOption
          options={eventManagementOptions}
          onClick={handleEventOption}
          selected={eventtOption}
        />
      </Box>
      <Card sx={{ flexGrow: 1, px: "40px", py: "40px", borderRadius: "16px" }}>
        {renderEventOptions()}
      </Card>
      {/* <UpdatedContent rows={rows} columns={columns} pageSize={5} /> */}
      <EventContents />
      {(isCreatingEvent || isDeletingEvent) && <ActivityOverlay />}
    </Box>
  );
};
export default EventManagement;
