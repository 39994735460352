export default Object.freeze({
  login: "/login",
  dashboard: "/",
  content_management: "/content-management",
  event_management: "/event-management",
  sales_rm_management: "/salesrm-management",
  user_management: "/user-management",
  lead_management: "/lead-management",
  salesrm_cluster_Head: "/salesrm-cluster-head",
  broker_structre_management: "/brokre-structre-management",
  notification_management: "/notification-management",
  scheme_management: "/scheme-management",
  sip_performance: "/sip-performance",
  login_banner: "/login-banner",
  fund_manager: "/fund-manager",
});
