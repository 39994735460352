import { Box, Button, Card, Divider } from "@mui/material";
import Select from "components/Select";
import React, { useEffect, useRef, useState } from "react";
import { SelectOption } from "components/FormSelect";
import { TContentForm } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import ImageDropzone from "components/ImageDropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import FormInput from "components/FormInput";
import colors from "res/colors";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  resetSchemeBannerStates,
  loadSchemeBanner,
  uploadSchemeBanner,
} from "../store/slice";

const AddSchemeBanner = () => {
  const {
    isUploadingSchemeBanner,
    uploadSchemeBannerError,
    didUploadSchemeBanner,
  } = useAppSelector((state) => state.bannerManagement);
  const dispatch = useAppDispatch();
  const { schemeNames } = useAppSelector((state) => state.schemes);
  const [bannerImageError, setBannerImageError] = useState("");
  const imageDropzoneBannerRef = useRef<any>(null);
  const [scheme, setScheme] = React.useState("");
  const [schemeList, setSchemeList] = React.useState<Array<any>>([]);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    const allSchemes = [
      ...schemeNames,
      {
        title: "Others",
        value: "Others",
      },
    ];
    setSchemeList(allSchemes);
  }, [schemeNames]);

  useEffect(() => {
    if (didUploadSchemeBanner) {
      ToastCenter.success("Successfully added scheme banner.");
      formMethods.setValue("banner", null);
      formMethods.setValue("schemes", "");
      setScheme("");
      imageDropzoneBannerRef.current.clearImage();
      dispatch(resetSchemeBannerStates());
      dispatch(loadSchemeBanner({ schemeDetailFlag: false }));
    }
  }, [didUploadSchemeBanner]);

  const onSubmit = (data: TContentForm) => {
    let hasError = false;

    if (!data.banner) {
      setBannerImageError("Please set banner image");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const params = {
      banner: data.banner,
      imageRequest: {
        title: data?.schemes || null,
      },
    };

    dispatch(uploadSchemeBanner(params));
  };

  const handleBannerDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("banner", imageData);
  };

  const onSelectSchemes = (option: SelectOption) => {
    setScheme(option.value);
    formMethods.setValue("schemes", option.value);
  };
  return (
    <Box sx={{ pt: "30px" }}>
      <ImageDropzone
        onDropped={handleBannerDrop}
        ref={imageDropzoneBannerRef}
        heading="Upload Banner Image"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={bannerImageError}
        resolution={"1032w X 720h"}
      />

      <Box sx={{ height: "20px" }} />
      <Select
        label="Select Related Scheme"
        labelId="schemes"
        id="schemes"
        options={schemeList}
        onOptionSelect={onSelectSchemes}
        value={scheme}
        sx={{ pb: "40px" }}
      />
      <Box sx={{ height: "20px" }} />
      {/* <MultiSelect
          label="Select Related Schemes"
          labelId="schemes"
          id="schemes"
          options={schemeNames}
          onOptionSelect={onSelectSchemes}
        /> */}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",

            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddSchemeBanner;
