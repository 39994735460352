import { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ImageDropzone from "components/ImageDropZone";
import { useRef } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import {
  deleteSipPerformance,
  loadSipPermanceList,
  resetSipDeleteStates,
  resetUploadSipPermanceList,
  uploadSipPermanceList,
} from "./store/slice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import { addNewSalesRM } from "pages/SalesRMManagement/store/slice";
import SipList from "./SipList";

const ExcelAddRm = () => {
  const dispatch = useAppDispatch();
  const {
    isUploadingSipPermanceList,
    didUploadSipPermanceList,
    uploadSipPermanceListError,
    didDeleteSip,
  } = useAppSelector((state) => state.sipPerformance);
  const imageDropzoneRef = useRef<any>(null);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  useEffect(() => {
    if (didUploadSipPermanceList) {
      ToastCenter.success("Successfully added Sip Performance.");
      dispatch(resetUploadSipPermanceList());
      imageDropzoneRef.current.clearImage();
      dispatch(loadSipPermanceList());
    }
  }, [didUploadSipPermanceList]);
  useEffect(() => {
    if (didDeleteSip) {
      ToastCenter.success("Successfully deleted Sip Performance.");
      dispatch(resetSipDeleteStates());
      imageDropzoneRef.current.clearImage();
      dispatch(loadSipPermanceList());
    }
  }, [didDeleteSip]);

  const handleUpload = () => {
    const data = imageDropzoneRef.current.getImage();

    if (!data) {
      return;
    }
    // dispatch(uploadSipPermanceList({ file: data }));
    setUploadDialog(true);
  };
  const handleUploadClose = () => {
    setUploadDialog(false);
  };
  const handleUploadConfirm = () => {
    setUploadDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(uploadSipPermanceList({ file: data }));
  };
  const handleImageDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    // setValue('image', { imageData: imageData, name: name });
  };
  const handleDelete = () => {
    const data = imageDropzoneRef.current.getImage();
    if (!data) {
      return;
    }
    setDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setDeleteDialog(false);
  };
  const handleDeleteConfirm = () => {
    setDeleteDialog(false);
    const data = imageDropzoneRef.current.getImage();
    dispatch(deleteSipPerformance({ file: data }));
  };

  return (
    <Box sx={{ mt: "16px" }}>
      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneRef}
        heading=""
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Excel"
      />
      <Box
        sx={{
          mt: "10px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleUpload}
        >
          Add/Update Data
        </Button>
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            mt: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDelete}
        >
          Delete Data
        </Button>
        <Dialog open={deleteDialog}>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            This action will permanently delete the data.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteClose}>Cancel</Button>
            <Button onClick={() => handleDeleteConfirm()} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Dialog open={uploadDialog}>
        <DialogTitle>Confirm upload?</DialogTitle>
        <DialogContent>This action will add/update the data.</DialogContent>
        <DialogActions>
          <Button onClick={handleUploadClose}>Cancel</Button>
          <Button onClick={() => handleUploadConfirm()} autoFocus>
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ExcelAddRm;
