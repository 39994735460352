import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import ToastCenter from "utils/ToastCenter";
import {
  didFailToUpdateLoginBanner,
  didFailToUploadLoginBanner,
  didUpdateLoginBanner,
  uploadLoginBanner,
  updateLoginBanner,
  didUploadLoginBanner,
  didFailToLoadLoginBanner,
  didLoadLoginBanner,
  loadLoginBanner,
  deleteLoginBanner,
  didDeleteLoginBanner,
  didFailedToDeleteLoginBanner,
  deleteSchemeBanner,
  didDeleteSchemeBanner,
  didFailedToDeleteSchemeBanner,
  didFailToLoadSchemeBanner,
  didFailToUpdateSchemeBanner,
  didFailToUploadSchemeBanner,
  didLoadSchemeBanner,
  didUpdateSchemeBanner,
  didUploadSchemeBanner,
  loadSchemeBanner,
  updateSchemeBanner,
  uploadSchemeBanner,
} from "./slice";

const UPLOAD_LOGIN_BANNER_ENDPOINT = "v1/admin/uploadLoginBanner";
const UPDATE_LOGIN_BANNER_ENDPOINT = "v1/admin/editLoginBanner";
const LOAD_LOGIN_BANNER_ENDPOINT = "v1/admin/fetchLoginBanners";
const DELETE_LOGIN_BANNER_ENDPOINT = "v1/admin/deleteLoginBanner";

const UPLOAD_SCHEME_BANNER_ENDPOINT = "v1/admin/uploadSchemeBanner";
const UPDATE_SCHEME_BANNER_ENDPOINT = "v1/admin/editSchemeBanner";
const LOAD_SCHEME_BANNER_ENDPOINT = "v1/admin/fetchSchemeBanners";
const DELETE_SCHEME_BANNER_ENDPOINT = "v1/admin/deleteSchemeBanner";

/**Login Banner */
function* handleLoginBannerUpload(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadBanner,
    UPLOAD_LOGIN_BANNER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to upload login banner.");
    yield put(didFailToUploadLoginBanner(response.error));
  } else {
    yield put(didUploadLoginBanner());
  }
}

function* handleLoginBannerUpdate(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadBanner,
    UPDATE_LOGIN_BANNER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to update login banner.");
    yield put(didFailToUpdateLoginBanner(""));
  } else {
    yield put(didUpdateLoginBanner());
  }
}

function* handleLoadLoginBanners() {
  const response: TResponse = yield call(
    Request.GET,
    LOAD_LOGIN_BANNER_ENDPOINT
  );
  if (response.error) {
    yield put(didFailToLoadLoginBanner(response?.error));
  } else {
    yield put(didLoadLoginBanner(response?.data?.data?.loginBanners));
  }
}

function* handleDeleteLoginBanners(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    `${DELETE_LOGIN_BANNER_ENDPOINT}?bannerId=${action.payload}`
  );
  if (response.error) {
    ToastCenter.error("Unable to delete login banner.");
    yield put(didFailedToDeleteLoginBanner(response.error));
  } else {
    ToastCenter.success("Successfully deleted login banner.");
    yield put(didDeleteLoginBanner(action?.payload));
  }
}
/**Scheme Banner */
function* handleSchemeBannerUpload(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadBanner,
    UPLOAD_SCHEME_BANNER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to upload scheme banner.");
    yield put(didFailToUploadSchemeBanner(response.error));
  } else {
    yield put(didUploadSchemeBanner());
  }
}

function* handleSchemeBannerUpdate(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.uploadBanner,
    UPDATE_SCHEME_BANNER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to update scheme banner.");
    yield put(didFailToUpdateSchemeBanner(""));
  } else {
    yield put(didUpdateSchemeBanner());
  }
}

function* handleLoadSchemeBanners(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.GET,
    LOAD_SCHEME_BANNER_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadSchemeBanner(response?.error));
  } else {
    yield put(didLoadSchemeBanner(response?.data?.data?.schemeBanners));
  }
}

function* handleDeleteSchemeBanners(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    `${DELETE_SCHEME_BANNER_ENDPOINT}?bannerId=${action.payload}`
  );
  if (response.error) {
    ToastCenter.error("Unable to delete scheme banner.");
    yield put(didFailedToDeleteSchemeBanner(response.error));
  } else {
    ToastCenter.success("Successfully deleted scheme banner.");
    yield put(didDeleteSchemeBanner(action?.payload));
  }
}

function* watcherLoginBannerUpload() {
  yield takeLatest(uploadLoginBanner.type, handleLoginBannerUpload);
}

function* watcherLoginBannerUpdate() {
  yield takeLatest(updateLoginBanner.type, handleLoginBannerUpdate);
}
function* watcherLoginBannerLoad() {
  yield takeLatest(loadLoginBanner.type, handleLoadLoginBanners);
}
function* watcherDeleteLoginBanner() {
  yield takeLatest(deleteLoginBanner.type, handleDeleteLoginBanners);
}
function* watcherSchemeBannerUpload() {
  yield takeLatest(uploadSchemeBanner.type, handleSchemeBannerUpload);
}

function* watcherSchemeBannerUpdate() {
  yield takeLatest(updateSchemeBanner.type, handleSchemeBannerUpdate);
}
function* watcherSchemeBannerLoad() {
  yield takeLatest(loadSchemeBanner.type, handleLoadSchemeBanners);
}
function* watcherDeleteSchemeBanner() {
  yield takeLatest(deleteSchemeBanner.type, handleDeleteSchemeBanners);
}
export function* bannerManagementSaga() {
  yield all([
    watcherSchemeBannerUpload(),
    watcherSchemeBannerUpdate(),
    watcherSchemeBannerLoad(),
    watcherDeleteSchemeBanner(),
    watcherLoginBannerUpload(),
    watcherLoginBannerUpdate(),
    watcherLoginBannerLoad(),
    watcherDeleteLoginBanner(),
  ]);
}
