import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  deleteSipPerformance,
  didDeleteSipPermanceList,
  didFailToDeleteSipPermanceList,
  didFailToLoadSipPermanceList,
  didFailToUploadSipPermanceList,
  didLoadSipPermanceList,
  didUploadSipPermanceList,
  loadSipPermanceList,
  uploadSipPermanceList,
} from "./slice";
import ToastCenter from "utils/ToastCenter";

const UPLOAD_ENDPOINT = "v1/admin/content/importSipPerformanceExcel";
const DELETE_ENDPOINT = "v1/admin/content/deleteSipPerformanceExcel";
const LOAD_SIP_ENDPOINT = "v1/admin/fetchSipPerformanceList";

function* handleExcelUpload(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    UPLOAD_ENDPOINT,
    params
  );
  if (response.error) {
    ToastCenter.error(response.error);
    yield put(didFailToUploadSipPermanceList(response.error));
  } else {
    yield put(didUploadSipPermanceList());
  }
  //
}
function* handleExcelDelete(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.uploadExcel,
    DELETE_ENDPOINT,
    params
  );
  if (response.error) {
    ToastCenter.error(response?.error || "");
    yield put(didFailToDeleteSipPermanceList(response.error));
  } else {
    yield put(didDeleteSipPermanceList());
  }
  //
}
function* handleLoadSip() {
  const response: TResponse = yield call(Request.GET, LOAD_SIP_ENDPOINT);
  if (response.error) {
    // ToastCenter.error(response.error);
    yield put(didFailToLoadSipPermanceList(response.error));
  } else {
    yield put(didLoadSipPermanceList(response?.data?.data?.sipPerformanceLis));
  }
  //
}

function* watcherUpload() {
  yield takeLatest(uploadSipPermanceList.type, handleExcelUpload);
}
function* watcherDelete() {
  yield takeLatest(deleteSipPerformance.type, handleExcelDelete);
}
function* watcherLoadSip() {
  yield takeLatest(loadSipPermanceList.type, handleLoadSip);
}

export function* sipPerformanceSaga() {
  yield all([watcherUpload(), watcherLoadSip(), watcherDelete()]);
}
