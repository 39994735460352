/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Button, Grid } from "@mui/material";

import React, { useState, useRef, useEffect } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import ImageDropzone from "components/ImageDropZone";
import { SelectOption } from "components/FormSelect";
import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "../validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import Select from "components/Select";
import {
  resetUpdateSchemeBanner,
  updateSchemeBanner,
  uploadSchemeBanner,
} from "pages/BannerManagement/store/slice";
export interface EditContentrProps {
  content: any;
  onCancel: () => void;
}

const EditSchemeBanner: React.FC<EditContentrProps> = ({
  content,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { didUpdateSchemeBanner } = useAppSelector(
    (state) => state.bannerManagement
  );

  const { schemeNames } = useAppSelector((state) => state.schemes);
  const [scheme, setScheme] = React.useState("");
  const imageDropzoneBannerRef = useRef<any>(null);
  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("banner", "");
    formMethods.setValue("schemes", content?.title);
    setScheme(content?.title);
    if (didUpdateSchemeBanner) {
      ToastCenter.success("Successfully updated scheme banner.");
      formMethods.setValue("banner", null);
      formMethods.setValue("schemes", "");
      setScheme("");
      imageDropzoneBannerRef.current.clearImage();
      // dispatch(resetStates());
      onCancel();
    }
    return () => {
      dispatch(resetUpdateSchemeBanner());
    };
  }, [didUpdateSchemeBanner]);

  const onSubmit = (data: TContentForm) => {
    // let hasError = false;

    // if (!data.banner) {
    //   setBannerImageError("Please set banner image");
    //   hasError = true;
    // }
    // if (hasError) {
    //   return;
    // }
    const params = {
      banner: data.banner || null,
      imageRequest: {
        title: data?.schemes || null,
        bannerId: content?.bannerId || null,
      },
    };

    dispatch(updateSchemeBanner(params));
  };

  const handleBannerDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("banner", imageData);
  };

  const onSelectSchemes = (option: SelectOption) => {
    setScheme(option.value);
    formMethods.setValue("schemes", option.value);
  };
  return (
    <Box sx={{ mt: "40px", width: "700px" }}>
      <ImageDropzone
        onDropped={handleBannerDrop}
        ref={imageDropzoneBannerRef}
        heading="Upload Banner Image"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        // error={bannerImageError}
        resolution={"1032w X 720h"}
      />

      <Box sx={{ height: "20px" }} />
      <Select
        label="Select Related Scheme"
        labelId="schemes"
        id="schemes"
        options={schemeNames}
        onOptionSelect={onSelectSchemes}
        value={scheme}
        sx={{ pb: "40px" }}
      />

      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      {/* <Text type="error">{updateContentError}</Text> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            // right: "300px",
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>

        <Button
          variant="contained"
          onClick={onCancel}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Cancel
        </Button>
      </Box>
      {/* {isUpdateContent && <ActivityOverlay />} */}
    </Box>
  );
};
export default EditSchemeBanner;
