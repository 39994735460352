import * as yup from "yup";

const PHONE_REG_EXP =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const leadDataValidationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email")
    .min(5)
    .max(32)
    .required("Please enter a valid email"),

  name: yup.string().required("Kindly enter your Name"),
  location: yup.string().required("Kindly enter your Location"),
  // address: yup.string().required("Kindly enter your Address"),
  mobile: yup
    .string()
    .min(10, "Invalid Phone Number")
    .required()
    .matches(PHONE_REG_EXP, "Please enter valid 10 digit Mobile Number"),

  // fundInput: yup.string().required("Kindly select the Fund Input"),
  // schemeType: yup.string().required("Kindly select an option"),
});
