import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import { TInterviewVideosParams, TInterviewVideoDetailsParams } from "./types";
import ToastCenter from "utils/ToastCenter";
import Request from "networking/Request";
import {
  loadInterviewVideos,
  didLoadInterviewVideos,
  didFailToLoadInterviewVideos,
  deleteInterviewVideos,
  didDeleteInterviewVideos,
  didFailedToDeleteInterviewVideos,
} from "./slice";

const INTERVIEW_VIDEOS_ENDPOINT = "v1/admin/interviewVideo/fetchAll";
const INTERVIEW_VIDEOS_DELETED = "v1/admin/content/delete";

function* handleLoadInterviewVideos(
  action: PayloadAction<TInterviewVideosParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    INTERVIEW_VIDEOS_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadInterviewVideos(response.error));
  } else {
    yield put(didLoadInterviewVideos(response.data.data.resultList));
  }
}

function* handleDeleteInterviewVideos(
  action: PayloadAction<TInterviewVideosParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    INTERVIEW_VIDEOS_DELETED,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailedToDeleteInterviewVideos(response.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteInterviewVideos(action?.payload?.id));
  }
}

function* watcherLoadInterviewVideos() {
  yield takeLatest(loadInterviewVideos.type, handleLoadInterviewVideos);
}

function* watcherDeleteInterviewsVideos() {
  yield takeLatest(deleteInterviewVideos.type, handleDeleteInterviewVideos);
}

export function* interviewVideosSaga() {
  yield all([watcherLoadInterviewVideos(), watcherDeleteInterviewsVideos()]);
}
