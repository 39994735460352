import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LoginState = {
  isGeneratingOtp: boolean;
  generateOtpError: string;
  didGenerateOtp: boolean;
  generateOtpData: any;

  isResendOtp: boolean;
  resendOtpError: string;
  newOtp: string;

  isValidateOtp: boolean;
  validateOtpError: string;
  didValidateOtp: boolean;
};

const INITIAL_STATE: LoginState = {
  isGeneratingOtp: false,
  generateOtpError: "",
  didGenerateOtp: false,
  generateOtpData: {},

  isResendOtp: false,
  resendOtpError: "",
  newOtp: "",

  isValidateOtp: false,
  validateOtpError: "",
  didValidateOtp: false,
};

const login = createSlice({
  name: "login",
  initialState: INITIAL_STATE,
  reducers: {
    generateOtp: (state, _action: PayloadAction<any>) => {
      state.isGeneratingOtp = true;
    },
    didGenerateOtp: (state, action: PayloadAction<any>) => {
      state.isGeneratingOtp = false;
      state.didGenerateOtp = true;
      state.generateOtpData = action.payload;
    },
    didFailToGenerateOtp: (state, action: PayloadAction<any>) => {
      state.isGeneratingOtp = false;
      state.generateOtpError = action.payload;
    },

    resendOtp: (state, _action: PayloadAction<any>) => {
      state.isResendOtp = true;
    },
    didResendOtp: (state, action: PayloadAction<string>) => {
      state.isResendOtp = false;
      state.newOtp = action.payload;
    },
    didFailToResendOtp: (state, action: PayloadAction<any>) => {
      state.isResendOtp = false;
      state.resendOtpError = action.payload;
    },

    validateOtp: (state, _action: PayloadAction<any>) => {
      state.isValidateOtp = true;
    },
    didValidateOtp: (state) => {
      state.isValidateOtp = false;
      state.didValidateOtp = true;
    },
    didFailToValidateOtp: (state, action: PayloadAction<string>) => {
      state.isValidateOtp = false;
      state.validateOtpError = action.payload;
    },

    resetStates: (state) => {
      state.isGeneratingOtp = false;
      state.didGenerateOtp = false;
      state.generateOtpError = "";
    },

    resetResendOtpStates: (state) => {
      state.isResendOtp = false;
      state.newOtp = "";
      state.resendOtpError = "";
    },
    resetValidOtpStates: (state) => {
      state.isValidateOtp = false;
      state.didValidateOtp = false;
      state.validateOtpError = "";
    },
  },
});

export const {
  generateOtp,
  didGenerateOtp,
  didFailToGenerateOtp,
  resetStates,
  resendOtp,
  didResendOtp,
  didFailToResendOtp,
  resetResendOtpStates,
  validateOtp,
  didValidateOtp,
  didFailToValidateOtp,
  resetValidOtpStates,
} = login.actions;

export default login.reducer;
