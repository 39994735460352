import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ProductVideosState = {
  isProductVideosContent: boolean;
  productVideosContentError: string;
  productVideosContentData: Array<any>;

  isDeleteProductVideos: boolean;
  productVideosDeleteError: string;
  productVideosDeleted: string;
};

const INITIAL_STATE: ProductVideosState = {
  isProductVideosContent: false,
  productVideosContentError: "",
  productVideosContentData: [],

  isDeleteProductVideos: false,
  productVideosDeleteError: "",
  productVideosDeleted: "",
};

const ProductVideosManagement = createSlice({
  name: "ProductVideosManagement",
  initialState: INITIAL_STATE,
  reducers: {
    loadProductVideos: (state, _action: PayloadAction<any>) => {
      state.isProductVideosContent = true;
    },
    didLoadProductVideos: (state, action: PayloadAction<any>) => {
      state.isProductVideosContent = false;
      state.productVideosContentData = action.payload;
    },
    didFailToLoadProductVideos: (state, action: PayloadAction<any>) => {
      state.isProductVideosContent = false;
      state.productVideosContentError = action.payload;
    },
    deleteProductVideos: (state, action: PayloadAction<any>) => {
      state.isDeleteProductVideos = true;
      state.productVideosDeleteError = "";
      state.productVideosDeleted = "";
    },
    didDeleteProductVideos: (state, action: PayloadAction<any>) => {
      state.isDeleteProductVideos = false;
      state.productVideosDeleteError = "";
      state.productVideosDeleted = action.payload;
    },
    didFailedToDeleteProductVideos: (state, action: PayloadAction<any>) => {
      state.isDeleteProductVideos = false;
      state.productVideosDeleteError = action.payload;
    },
  },
});

export const {
  loadProductVideos,
  didLoadProductVideos,
  didFailToLoadProductVideos,
  deleteProductVideos,
  didDeleteProductVideos,
  didFailedToDeleteProductVideos,
} = ProductVideosManagement.actions;

export default ProductVideosManagement.reducer;
