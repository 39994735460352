import {
  Box, Card, Grid
} from "@mui/material";
import Text from "components/Text";
import { FC } from "react";
import { RmList } from "./types";
// import { salesRmProps } from "../types";
import moment from "moment";
export interface RmListItemProps {
  data: RmList;
}
const ClusterHeadListItem: FC<RmListItemProps> = ({ data }) => {
  return (
    <Box>
      <Card sx={{ px: "20px", mb: "12px", pt: "20px", pb: "10px" }}>
        <Grid>
          <Grid container direction="row">
            <Grid xs={4}>
              <Text type="listMediumBold">{data.email}</Text>
            </Grid>
            <Grid xs={2.6}>
              <Text type="listMedium">{data.location}</Text>
            </Grid>
            <Grid xs={2.7}>
              <Text type="listMedium">
                {moment(data.createdOn).format("DD-MMM-YYYY")}
              </Text>
            </Grid>
            <Grid xs={2.7}>
              <Text type="listMedium">
                {moment(data.updatedOn).format("DD-MMM-YYYY")}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default ClusterHeadListItem;
