import CloseIcon from "@mui/icons-material/Close";
import SearchIconMUI from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Input from "components/Input";
import Text from "components/Text";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useEffect, useState } from "react";
import colors from "res/colors";
import { ReactComponent as SearchIcon } from "res/images/searchIcon.svg";
import { verifyEmail } from "utils/verifyEmail";
import { loadSalesRMClusterHeads } from "../store/slice";
import ClusterHeadListItem from "./ClusterHeadListItem";
const ClusterHeadList = () => {
  const dispatch = useAppDispatch();

  const {
    salesRMClusterHeadsLoadingError,
    salesRMClusterHeads,
    salesRMClusterHeadsCount,
    didUploadSalesRMClusterHeadList,
    didDeleteSalesRMClusterHead,
  } = useAppSelector((state) => state.salesRMClusterHead);
  const [searchResult, setSearchResult] = useState(salesRMClusterHeads);
  const [searchValue, setSearchValue] = useState("");
  const [searchError, setSearchError] = useState("");
  useEffect(() => {
    dispatch(
      loadSalesRMClusterHeads({
        pageNumber: 0,
        size: 1000,
      })
    );
  }, [didUploadSalesRMClusterHeadList, didDeleteSalesRMClusterHead]);

  useEffect(() => {
    setSearchResult(salesRMClusterHeads);
  }, [salesRMClusterHeads]);

  useEffect(() => {
    if (!searchValue) {
      setSearchError("");
      setSearchResult(salesRMClusterHeads);
    }
  }, [searchValue]);

  const handleSearchPress = () => {
    setSearchError("");
    if (searchValue) {
      if (!verifyEmail(searchValue)) {
        setSearchError("Please enter valid email.");
        return;
      }

      const result = salesRMClusterHeads?.filter(
        (ele: any) => ele?.email === searchValue
      );

      if (result?.length < 1) {
        setSearchError("No data found.");
      } else {
        setSearchError("");
      }
      setSearchResult(result);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          mt: "24px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text type={"mediumBold"}>{`Sales RM-Cluster head count: ${
          salesRMClusterHeadsCount ? salesRMClusterHeadsCount : 0
        }`}</Text>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Input
          name="email"
          sx={{
            [`& .MuiFilledInput-root`]: {
              backgroundColor: "#F8F8F8",
            },
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? (
                  <IconButton
                    color="default"
                    size="small"
                    sx={{}}
                    onClick={() => {
                      setSearchValue("");
                      setSearchError("");
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
            placeholder: "Search by Email ID",
          }}
          onChange={(e: any) => {
            setSearchValue(e?.target?.value);
          }}
          value={searchValue}
        />
        <Button
          variant="contained"
          sx={{
            py: "14px",
            px: "30px",
            ml: "10px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleSearchPress}
        >
          <SearchIconMUI /> Search
        </Button>
      </Box>
      {salesRMClusterHeadsLoadingError ? (
        <Box sx={{ mt: "24px", color: colors?.red, ml: "10px", mb: "10px" }}>
          {salesRMClusterHeadsLoadingError}
        </Box>
      ) : null}
      {searchError ? (
        <Box sx={{ mt: "24px", color: colors?.red, ml: "10px", mb: "10px" }}>
          {searchError}
        </Box>
      ) : null}
      <Box sx={{ mb: "50px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container direction="row" sx={{ pl: "18px", mb: "16px" }}>
        <Grid xs={3.9}>
          <Text type="listMediumBold">Email</Text>
        </Grid>
        <Grid xs={2.6}>
          <Text type="listMediumBold">Location</Text>
        </Grid>
        <Grid xs={2.6}>
          <Text type="listMediumBold">Created on</Text>
        </Grid>
        <Grid xs={2.5}>
          <Text type="listMediumBold">Updated on</Text>
        </Grid>
        <Grid xs={0.5}>
          <Box></Box>
        </Grid>
      </Grid>

      {searchResult.map((rowData: any, index: any) => {
        return (
          <ClusterHeadListItem data={rowData} key={rowData.email + index} />
        );
      })}
    </Box>
  );
};

export default ClusterHeadList;
