import { Box, Button, Card } from "@mui/material";
import Text from "components/Text/Text";
import React, { useEffect, useRef, useState } from "react";
import ImageDropzone from "components/ImageDropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import colors from "res/colors";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  loadFundManagers,
  resetFundManagerStates,
  uploadFundManager,
} from "./store/slice";
import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";
import FundManagerContents from "./FundManagerContents";
import { FormProvider, useForm } from "react-hook-form";
import FormInput from "components/FormInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import { TContentForm } from "./types";

const FundManager = () => {
  const dispatch = useAppDispatch();
  const [imageError, setImageError] = useState("");
  const [imageDetails, setimageDetails] = useState<any>("");
  const imageDropzoneBannerRef = useRef<any>(null);
  const handleImageDrop = (imageData: { url: string; name: string }) => {
    setImageError("");
    setimageDetails(imageData);
  };
  const {
    isUploadingFundManager,
    uploadFundManagerError,
    didUploadFundManager,
    isLoadingFundManagers,
    isUpdatingFundManager,
    isDeleteFundManager,
  } = useAppSelector((state) => state.fundManager);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    dispatch(loadFundManagers());
  }, []);
  useEffect(() => {
    if (didUploadFundManager) {
      ToastCenter.success("Successfully added fund manager.");
      formMethods.setValue("name", "");
      imageDropzoneBannerRef.current.clearImage();
      setimageDetails("");
      dispatch(resetFundManagerStates());
    }
  }, [didUploadFundManager]);

  const handleOnUpload = (data: TContentForm) => {
    let hasError = false;

    if (!imageDetails) {
      setImageError("Please upload fund manager image");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const params = {
      image: imageDetails,
      fundManagerName: data?.name,
    };

    dispatch(uploadFundManager(params));
  };

  return (
    <Box
      sx={{ minHeight: "vh", width: "65%", ml: "20%", pt: "50px", pb: "50px" }}
    >
      <Text type="headingBold">Fund Manager</Text>
      <Card
        sx={{
          px: "40px",
          py: "40px",
          mt: "20px",
          mb: "48px",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ pt: "30px" }}>
          <Box
            sx={{
              pb: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Text type="mediumBold">Fund Manager Name</Text>
            <Text
              type="mediumBold"
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                letterSpacing: "0em",
                lineHeight: "20px",
                fontFamily: "Graphik",
                color: colors.radicalRed,
              }}
            >
              *
            </Text>
          </Box>
          <FormProvider {...formMethods}>
            <FormInput
              variant="filled"
              name="name"
              InputProps={{
                disableUnderline: true,
              }}
              label="Name"
            />
          </FormProvider>
          <Box sx={{ mt: "20px" }} />
          <ImageDropzone
            onDropped={handleImageDrop}
            ref={imageDropzoneBannerRef}
            heading={`Upload Fund Manager Image`}
            icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
            type="Image"
            error={imageError}
            required
            resolution={"500w X 500h | File types: .jpg, .jpeg, .png"}
          />
          {imageDetails && <Text type="medium">{imageDetails?.name}</Text>}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mt: "20px",
            }}
          >
            <Button
              variant="contained"
              onClick={formMethods.handleSubmit(handleOnUpload)}
              sx={{
                fontSize: "18px",
                lineHeight: "24px",
                py: "16px",
                px: "48px",

                fontWeight: 500,
                backgroundColor: colors.backGroundBlue,
              }}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Card>
      <FundManagerContents />
      {isUploadingFundManager ||
      isLoadingFundManagers ||
      isUpdatingFundManager ||
      isDeleteFundManager ? (
        <ActivityOverlay />
      ) : null}
    </Box>
  );
};

export default FundManager;
