import { Box, Button, Card, Divider } from "@mui/material";
import Input from "components/Input";
import Select from "components/Select";
import Text from "components/Text";
import { ChangeEvent, useState } from "react";
import colors from "res/colors";
import ImageDropzone from "components/DropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import ToggleOption from "components/ToggleOption";
import AddScheme from "./AddScheme/AddScheme";

const SchemeManagement = () => {
  const [exitLoad, setExitLoad] = useState("");
  const [expenseRatio, setExpenseRatio] = useState("");

  // const handleExitLoad = (event: ChangeEvent<HTMLInputElement>) => {
  //   setExitLoad(event.target.value);
  // };

  // const handleExpenseRatio = (event: ChangeEvent<HTMLInputElement>) => {
  //   setExitLoad(event.target.value);
  // };

  const [option, setOption] = useState("Add Scheme");

  const handleEventClick = (displayComponent: string) => {
    setOption(displayComponent);
  };

  const renderContent = () => {
    if (option === "Add Scheme") {
      return <AddScheme />;
    }
  };
  const addSalesRmOptions = [{ label: "Add Scheme" }];
  return (
    <Box
      sx={{ minHeight: "vh", width: "65%", ml: "20%", pt: "50px", pb: "50px" }}
    >
      <Text type="headingBold">Scheme Management</Text>
      <Card
        sx={{
          px: "40px",
          py: "40px",
          mt: "20px",
          mb: "48px",
          borderRadius: "16px",
        }}
      >
        <Box sx={{ mt: "16px" }}>
          <ToggleOption
            options={addSalesRmOptions}
            onClick={handleEventClick}
            selected={option}
          />
        </Box>
        {renderContent()}
        {/* <Box sx={{ mt: "24px" }}>
          <Select
            options={[{ title: "1", value: "1" }]}
            placeHolder="Scheme Type"
          />
        </Box>
        <Box sx={{ mt: "24px", display: "flex", flexDirection: "row" }}>
          <Input
            variant="filled"
            label="Exit load"
            sx={{ mr: "16px" }}
            onChange={handleExitLoad}
            InputProps={{
              disableUnderline: true,
            }}
          />
          <Input
            variant="filled"
            label="Expense ratio"
            onChange={handleExpenseRatio}
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
        <Box sx={{ mt: "40px", mb: "24px" }}>
          <Divider variant="fullWidth" />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="contained"
            sx={{
              fontSize: "18px",
              lineHeight: "24px",
              py: "16px",
              px: "48px",

              fontWeight: 500,
              backgroundColor: colors.backGroundBlue,
            }}
          >
            Update
          </Button>
        </Box> */}
      </Card>
      {option === "Add Scheme" && (
        <ImageDropzone onDropped={() => {}} heading="Upload Thumbnail Image" />
      )}
    </Box>
  );
};

export default SchemeManagement;
