import { Box, Button, Card } from "@mui/material";
import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import { useState, useEffect } from "react";

import ExcelAddRm from "./ExcelAddRm";

import { useAppSelector } from "hooks/useAppSelector";

import ActivityOverlay from "components/ActivityOverlay";
import Warning from "@mui/icons-material/Warning";
import colors from "res/colors";
//
import { useAppDispatch } from "hooks/useAppDispatch";
import { loadSipPermanceList } from "./store/slice";
import SipList from "./SipList";
import ToastCenter from "utils/ToastCenter";
const SipPerformance = () => {
  const {
    isUploadingSipPermanceList,
    isloadingSipPermanceList,
    isDeletingSip,
  } = useAppSelector((state) => state.sipPerformance);
  const dispatch = useAppDispatch();

  const [option, setOption] = useState("Upload excel");
  const { baseURL } = useAppSelector((state) => state.auth);

  const handleDownloadSipPerformanceSheet = () => {
    const url = baseURL + "v1/admin/content/sipPerformanceExcel/download";
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.click();
    ToastCenter.success(
      "File downloading in progress. Please wait for some time."
    );
  };
  const handleEventClick = (displayComponent: string) => {
    setOption(displayComponent);
  };

  useEffect(() => {
    dispatch(loadSipPermanceList());
  }, []);

  const renderContent = () => {
    if (option === "Upload excel") {
      return <ExcelAddRm />;
      // } else if (option === "Manually") {
      //   return <ManualAddRM />;
    }
  };

  const addSalesRmOptions = [
    { label: "Upload excel" },
    //  { label: "Manually" }
  ];
  return (
    <Box
      sx={{ minHeight: "vh", width: "65%", ml: "20%", pt: "50px", pb: "48px" }}
    >
      <Text type="headingBold">SIP Performance</Text>
      <Card
        sx={{
          px: "40px",
          py: "40px",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          mt: "20px",
          mb: "48px",
          borderRadius: "16px",
        }}
      >
        {/* <Text type="extraLargeBold">ADD Sip Performance</Text> */}

        <Box sx={{ mt: "16px" }}>
          <ToggleOption
            options={addSalesRmOptions}
            onClick={handleEventClick}
            selected={option}
          />
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Warning sx={{ color: colors.red, fontSize: "18px", mr: "5px" }} />
          <Text type="medium" sx={{ color: colors.red }}>
            Warning : Please do not upload/edit/delete SIP Performance during
            business hours (9 AM - 7 PM).
          </Text>
        </Box>
        {renderContent()}
        {/* <Box
          sx={{
            mt: "40px",
            // display: "flex",
            // flexDirection: "row",

            alignItems: "center",
          }}
        >
          <Text
            type="largeBold"
            sx={{
              fontSize: "16px",
              letterSpacing: "0em",
              lineHeight: "22px",
              fontFamily: "Graphik",
              textDecoration: "underline",
              fontWeight: 600,
            }}
          >
            Note:
          </Text>

          <Text
            type="medium"
            sx={{
              fontSize: "16px",
              letterSpacing: "0em",
              lineHeight: "25px",
              fontFamily: "Graphik",
              mt: "5px",
            }}
          >
            <b>1. Lead Mapping -</b> Once uploaded the SalesRM Cluster Head
            data, Please re-upload Lead sheet.
          </Text>
        </Box> */}
      </Card>
      {/* <Text type="subHeadingBold">List of Sales RM</Text>
      <RMList /> */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text type="subHeadingBold">List of SIP Performance</Text>
        {/** Added export Sip performance sheet logic */}
        <Button
          variant="contained"
          sx={{
            py: "5px",
            px: "20px",
            ml: "10px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDownloadSipPerformanceSheet}
        >
          Export SIP Performance List
        </Button>
      </Box>
      <SipList />
      {(isUploadingSipPermanceList ||
        isloadingSipPermanceList ||
        isDeletingSip) && <ActivityOverlay />}
    </Box>
  );
};
export default SipPerformance;
