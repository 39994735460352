import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  sentNotification,
  didSentNotification,
  didFailToSentNotification,
  didFailToLoadMarketInsights,
  didFailToLoadOnePager,
  didFailToLoadPresentation,
  didLoadMarketInsights,
  didLoadOnePager,
  didLoadPresentation,
  loadMarketInsights,
  loadOnePager,
  loadPresentation,
  didFailToLoadFactsheet,
  didLoadLoadFactsheet,
  loadFactsheet,
} from "./slice";
import ToastCenter from "utils/ToastCenter";

const SENT_NOTIFICATION_EDNPOINT = "v1/notification/create";
const LOAD_PRESENTATION_DATA = "v1/admin/presentation/findByCategory";
const LOAD_ONEPAGER_DATA = "v1/admin/onepager/findByCategory";
const LOAD_MARKETINSIGHTS_DATA = "v1/admin/marketinsight/articles";
const LOAD_FACTSHEET_DATA = "v1/admin/factsheets";

function* handleSentNotification(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.createNotification,
    SENT_NOTIFICATION_EDNPOINT,
    action.payload
  );
  if (response.error) {
    ToastCenter.error(response.error);
    yield put(didFailToSentNotification(response.error));
  } else {
    ToastCenter.success("Successfully sent notification to users");
    yield put(didSentNotification());
  }
}

function* handleLoadPresentation(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    LOAD_PRESENTATION_DATA,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadPresentation(response.error));
  } else {
    yield put(
      didLoadPresentation(response?.data?.data?.presentationListResponse)
    );
  }
}
function* handleLoadOnePager(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    LOAD_ONEPAGER_DATA,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadOnePager(response.error));
  } else {
    yield put(didLoadOnePager(response?.data?.data?.onePagerList));
  }
}
function* handleLoadMarketInsights(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    LOAD_MARKETINSIGHTS_DATA,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadMarketInsights(response.error));
  } else {
    yield put(didLoadMarketInsights(response?.data?.data?.insightArticles));
  }
}
function* handleLoadFactsheet(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    LOAD_FACTSHEET_DATA,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadFactsheet(response.error));
  } else {
    yield put(didLoadLoadFactsheet(response?.data?.data?.factSheetList));
  }
}

function* watcherSentNotification() {
  yield takeLatest(sentNotification.type, handleSentNotification);
}
function* watcherLoadPresentation() {
  yield takeLatest(loadPresentation.type, handleLoadPresentation);
}
function* watcherLoadOnePager() {
  yield takeLatest(loadOnePager.type, handleLoadOnePager);
}
function* watcherLoadMarketInsights() {
  yield takeLatest(loadMarketInsights.type, handleLoadMarketInsights);
}
function* watcherLoadFactsheet() {
  yield takeLatest(loadFactsheet.type, handleLoadFactsheet);
}

export function* notificationSaga() {
  yield all([
    watcherSentNotification(),
    watcherLoadPresentation(),
    watcherLoadOnePager(),
    watcherLoadMarketInsights(),
    watcherLoadFactsheet(),
  ]);
}
