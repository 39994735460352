import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";
import {
    marketInsightContent,
    didMarketInsightContent,
    didFailToMarketInsightContent,
    resetMarketInsightStates,
    articlesContent,
    didArticlesContent,
    didFailToArticlesContent,
    resetArticlesStates,
} from "./slice";

const MARKET_INSIGHTS = "v1/admin/marketinsight/videos"
const MARKET_ARTICLES = "v1/admin/marketinsight/articles"



function* handleMarketInsightVideo(action:PayloadAction<any>) {

    const response: TResponse = yield call(
        Request.POST,
        MARKET_INSIGHTS,
        action.payload
    );
       
    if(response.error){
        yield put(didFailToMarketInsightContent(response.error));
    } else {
        yield put(didMarketInsightContent());
    }
}

function* handleMarketInsightArticles(action:PayloadAction<any>) {

    const response: TResponse = yield call(
        Request.POST,
        MARKET_ARTICLES,
        action.payload
    );
        
    if(response.error){
        yield put(didFailToArticlesContent(response.error));
    } else {
        yield put(didArticlesContent());
    }
}

function* watcherMarketInsightVideos(){
    yield takeLatest(marketInsightContent.type,handleMarketInsightVideo)
}

function* watcherMarketInsightArticles(){
    yield takeLatest(articlesContent.type,handleMarketInsightArticles)
}

export function* marketInsightSaga(){
    yield all([watcherMarketInsightVideos(),watcherMarketInsightArticles()]);
}