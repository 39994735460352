import {
  Box,
  Divider,
  Grid,
  InputAdornment,
  Button,
  IconButton,
} from "@mui/material";
import Input from "components/Input";
import Text from "components/Text";
import { FC, useState } from "react";
import { DisplayRmListProps, RmList } from "./types";
import RmListItem from "./RmListItem";
import { ReactComponent as SearchIcon } from "res/images/searchIcon.svg";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  deleteSalesRM,
  findSalesRM,
  getCount,
  loadSalesRMs,
  resetDeleteSalesRMStates,
  resetSalesRMUpdateStates,
} from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import colors from "res/colors";
import SearchIconMUI from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { verifyEmail } from "utils/verifyEmail";
const RMList = () => {
  const dispatch = useAppDispatch();

  const { didDeleteSalesRM, deleteSalesRMError, didLoadSalesRMs, salesRMs } =
    useAppSelector((state) => state.salesRM);
  const [searchResult, setSearchResult] = useState(salesRMs);
  const [searchValue, setSearchValue] = useState("");
  const [emailError, setEmailError] = useState("");
  const [lastSearchValue, setLastSearchValue] = useState("");
  const [inputCleared, setInputCleared] = useState(true);

  const {
    isUpdatingSalesRM,
    updateSalesRMError,
    didUpdateSalesRM,
    isSearchingSalesRM,
    searchSalesRMResult,
    searchSalesRMError,
    countData,
  } = useAppSelector((state) => state.salesRM);
  useEffect(() => {
    dispatch(
      loadSalesRMs({
        pageNumber: 1,
        endPageNumber: 100,
      })
    );
    dispatch(getCount());
  }, []);
  const handleDelete = (data: RmList) => {
    dispatch(deleteSalesRM(data));
  };

  useEffect(() => {
    setSearchResult(salesRMs);
  }, [salesRMs]);

  const handleEdit = (data: RmList) => {
    //
  };

  useEffect(() => {
    if (searchSalesRMError && !inputCleared) {
      setSearchResult(salesRMs);
    } else if (
      searchSalesRMResult?.salesRMResponseList &&
      !inputCleared
      //  &&
      // lastSearchValue?.toUpperCase().startsWith(searchValue?.toUpperCase())
    ) {
      setSearchResult(searchSalesRMResult?.salesRMResponseList);
    }
    // else if (lastSearchValue) {
    //   setSearchResult(salesRMs);
    // }
    if (!searchValue) {
      setEmailError("");
      setSearchResult(salesRMs);
      setInputCleared(true);
    }
  }, [searchSalesRMResult, searchValue, searchSalesRMError]);

  useEffect(() => {
    if (didUpdateSalesRM) {
      ToastCenter.success("Successfully updated SalesRM");
      dispatch(resetSalesRMUpdateStates());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
      dispatch(getCount());
    }
  }, [didUpdateSalesRM]);

  // const handleSearch = (text: string) => {
  //   if (!salesRMs && salesRMs.length < 1) return;

  //   const filteredValues = salesRMs?.filter((item: any) => {
  //     return (
  //       item?.name?.toUpperCase().includes(text.toUpperCase()) ||
  //       item?.email?.toUpperCase().startsWith(text.toUpperCase()) ||
  //       item?.mobileNum?.toUpperCase().startsWith(text.toUpperCase())
  //     );
  //   });
  //   setSearchResult(filteredValues);
  // };
  const handleSearchPress = () => {
    setEmailError("");
    if (searchValue) {
      if (!verifyEmail(searchValue)) {
        setEmailError("Please enter valid email.");
        return;
      }
      // setLastSearchValue(searchValue);
      setInputCleared(false);
      dispatch(
        findSalesRM({
          name: "",
          email: searchValue,
          mobileNum: "",
          pageNumber: "1",
          endPageNumber: "2",
        })
      );
    }
  };
  useEffect(() => {
    if (didDeleteSalesRM) {
      ToastCenter.success("Successfully deleted ");
      dispatch(resetDeleteSalesRMStates());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
      dispatch(getCount());
    }
  }, [didDeleteSalesRM]);

  return (
    <Box>
      <Box
        sx={{
          mt: "24px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Text type={"mediumBold"}>{`Sales RM Count: ${
          countData?.salesRmCount ? countData?.salesRmCount : 0
        }`}</Text>
        <Text type={"mediumBold"}>{`MFD Count: ${
          countData?.mfdCount ? countData?.mfdCount : 0
        }`}</Text>
        <Text type={"mediumBold"}>{`Leads Count: ${
          countData?.leadsCount ? countData?.leadsCount : 0
        }`}</Text>
      </Box>
      <Box sx={{ mt: "24px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ mt: "24px" }}>{deleteSalesRMError}</Box>
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Input
          name="email"
          sx={{
            [`& .MuiFilledInput-root`]: {
              backgroundColor: "#F8F8F8",
            },
          }}
          InputProps={{
            disableUnderline: true,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {searchValue ? (
                  <IconButton
                    color="default"
                    size="small"
                    sx={{}}
                    onClick={() => {
                      setSearchValue("");
                      setEmailError("");
                      setInputCleared(true);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                ) : null}
              </InputAdornment>
            ),
            placeholder: "Search by Email ID",
          }}
          onChange={(e: any) => {
            // handleSearch(e?.target?.value);
            setSearchValue(e?.target?.value);
          }}
          value={searchValue}
        />
        <Button
          variant="contained"
          sx={{
            py: "14px",
            px: "30px",
            ml: "10px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleSearchPress}
        >
          <SearchIconMUI /> Search
        </Button>
      </Box>
      {!emailError ? (
        <Box sx={{ mt: "24px", color: colors?.red, ml: "10px", mb: "10px" }}>
          {searchValue && !inputCleared ? searchSalesRMError : ""}
        </Box>
      ) : null}
      <Box sx={{ mt: "24px", color: colors?.red, ml: "10px", mb: "10px" }}>
        {emailError}
      </Box>
      <Box sx={{ mb: "50px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container direction="row" sx={{ pl: "18px", mb: "16px" }}>
        <Grid xs={4.5}>
          <Text type="listMediumBold">Sales RM Name</Text>
        </Grid>
        {/* <Grid xs={2.5}>
          <Text type="listMediumBold">EmployeeId</Text>
        </Grid> */}
        <Grid xs={4.75}>
          <Text type="listMediumBold">Mobile</Text>
        </Grid>
        <Grid xs={1}>
          <Text type="listMediumBold">Status</Text>
        </Grid>
        <Grid xs={0.5}>
          <Box></Box>
        </Grid>
      </Grid>

      {searchResult.map((rowData: any) => {
        return (
          <RmListItem
            data={rowData}
            key={rowData.email}
            onDelete={handleDelete}
            onEdit={handleEdit}
          />
        );
      })}
    </Box>
  );
};

export default RMList;
