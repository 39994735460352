import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import Request from "networking/Request";
import { TPresentationByCategoryParams, TPresentationParams } from "./types";
import {
  loadAllPresentation,
  didLoadAllPresentation,
  didFailToLoadAllPresentation,
} from "./slice";

const ALL_PRESENTATION_ENDPOINT = "v1/admin/presentation/fetchAll";

function* handleLoadAllPresentation(
  action: PayloadAction<TPresentationParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    ALL_PRESENTATION_ENDPOINT,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadAllPresentation(response.error));
  } else {
    yield put(didLoadAllPresentation(response.data.data));
  }
  //
  //
}

function* watcherLoadAllPresentation() {
  yield takeLatest(loadAllPresentation.type, handleLoadAllPresentation);
}

export function* presentationSaga() {
  yield all([watcherLoadAllPresentation()]);
}
