/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Button, Grid } from "@mui/material";
import CheckBox from "components/CheckBox";
import FormInput from "components/FormInput";
import RichTextInput from "components/RichTextInput";
import ToggleOption from "components/ToggleOption";
import ImageDropzone from "components/ImageDropZone";
import FormSelect from "components/FormSelect/FormSelect";
import FileInput from "components/FileInput";
import { ChangeEvent, useState, useRef } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import { ReactComponent as UploadDetailIcon } from "res/images/uploadDetailIcon.svg";
import Text from "components/Text";
import ActivityOverlay from "components/ActivityOverlay";
import { useEffect } from "react";
import MultiSelect from "components/MultiSelect";
import { loadInterviewVideos } from "./store/slice";

import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "../ProductVideos/validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { uploadContent, resetStates } from "../store/slice";
import ToastCenter from "utils/ToastCenter";
import DateInput from "components/DateInput";
import moment from "moment";
import Editor from "components/Editor";

const InterviewVideos = () => {
  const dispatch = useAppDispatch();
  const { isUploadingContent, uploadContentError, didUploadContent } =
    useAppSelector((state) => state.contentManagement);
  const { isLodingInterviewVideos, isDeleteInterviewVideos } = useAppSelector(
    (state) => state.interviewVideosManagement
  );
  const { schemeNames } = useAppSelector((state) => state.schemes);
  const [isTrending, setIsTrending] = useState(false);
  const [isRecomended, setIsRecomended] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isYoutube, setIsYoutube] = useState(false);
  const [isVimeo, setIsVimeo] = useState(false);
  const [thumbnailImageError, setThumbnailImageError] = useState("");
  const [videoError, setVideoError] = useState("");
  const [videoType, setVideoType] = useState("url link");
  const imageDropzoneVideoRef = useRef<any>(null);
  const videoUrlInputRef = useRef<any>(null);
  const imageDropzoneThumbnailRef = useRef<any>(null);
  const videoTypeOptions = [{ label: "url link" }, { label: "video file" }];
  const [isShowNotification, setIsShowNotification] = useState(false);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    if (didUploadContent) {
      ToastCenter.success("Successfully added Interview Videos");

      formMethods.setValue("thumbnail_file", null);
      formMethods.setValue("material_file", null);
      setIsTrending(false);
      setIsRecomended(false);
      setIsFeatured(false);
      setIsYoutube(false);
      setIsVimeo(false);
      setIsShowNotification(false);
      setVideoType("url link");
      imageDropzoneThumbnailRef.current.clearImage();
      if (imageDropzoneVideoRef.current)
        imageDropzoneVideoRef.current.clearImage();
      if (videoUrlInputRef.current) videoUrlInputRef.current.clearText();
      formMethods.setValue("title", "");
      formMethods.setValue("category", "");
      formMethods.setValue("description", "");
      formMethods.setValue("creator", "");
      formMethods.setValue("designation", "");
      formMethods.setValue("contentUploadDate", "");
      formMethods.setValue("schemes", "");
      formMethods.setValue("notificationTitle", "");
      formMethods.setValue("notificationShortDescription", "");
      formMethods.setValue("notificationDescription", "");
      dispatch(resetStates());
      dispatch(
        loadInterviewVideos({
          pageNo: 1,
          size: 100,
          userId: "ARN-3343",
        })
      );
    }
  }, [didUploadContent]);

  const onSubmit = (data: TContentForm) => {
    let showError = false;

    if (!data.thumbnail_file) {
      showError = true;
      setThumbnailImageError("Please set thumbnail image");
    }

    if (videoType === "video file" && !data.material_file) {
      showError = true;
      setVideoError("Please set video content");
    }

    if (videoType === "url link" && !data.videoUrl) {
      showError = true;
      setVideoError("Please set video url");
    }

    if (showError) {
      return;
    }

    const params: any = {
      thumbnail_file: data.thumbnail_file,

      imageRequest: {
        trending: isTrending,
        recommended: isRecomended,
        youtubeVideo: isYoutube,
        wealthForumVideo: isVimeo,
        title: data.title,
        contentCategories: "INTERVIEW_VIDEO",
        category: data.category,
        description: data.description || "",
        videoCreator: data.creator,
        CreatorDesignation: data.designation,
        featuredVideo: isFeatured,
        schemes: data.schemes || "",
        contentUploadDate:
          data.contentUploadDate || moment().format("YYYY-MM-DD"),
        enableNotification: isShowNotification,
        userType: ["MFD", "MFDRM", "RIA"],
        notificationTitle: data?.notificationTitle ?? "",
        notificationShortDescription: data?.notificationShortDescription ?? "",
        notificationDescription: data?.notificationDescription ?? "",
      },
    };
    if (videoType === "url link") {
      params.imageRequest.videoUrl = data.videoUrl;
    } else {
      params.material_file = data.material_file;
    }

    dispatch(uploadContent(params));
    // signInCallBack(data.email);
  };

  const handleOnDescriptionChange = (data: any) => {
    formMethods.setValue("description", data);
  };
  const handleVideoType = (videoTypeSelected: string) => {
    setVideoType(videoTypeSelected);
  };
  const handleVideoDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    formMethods.setValue("material_file", imageData);
  };
  const handleThumbnailDrop = (imageData: { url: string; name: string }) => {
    // const { name } = imageDropzoneRef.current.getImage();
    formMethods.setValue("thumbnail_file", imageData);
  };
  const handleTrendingCheckBoxChange = (checked: boolean) => {
    setIsTrending(checked);
  };
  const handleIsYoutubeCheckBoxChange = (checked: boolean) => {
    setIsYoutube(checked);
  };
  const handleIsVimeoCheckBoxChange = (checked: boolean) => {
    setIsVimeo(checked);
  };

  const handleRecomendedCheckBoxChange = (checked: boolean) => {
    setIsRecomended(checked);
  };
  const handleFeaturedCheckBoxChange = (checked: boolean) => {
    setIsFeatured(checked);
  };

  const handleNotificationCheckBoxChange = (checked: boolean) => {
    setIsShowNotification(checked);
    formMethods.setValue("isShowNotification", checked, {
      shouldValidate: true,
    });
  };

  const handleVideoURLChane = (url: string) => {
    formMethods.setValue("videoUrl", url);
  };

  const onSelectSchemes = (item: Array<string>) => {
    formMethods.setValue("schemes", item.join("$"));
  };

  const renderVideoSelectType = () => {
    if (videoType === "url link") {
      return (
        <Box sx={{ mt: "24px" }}>
          <FileInput
            icon={<UploadDetailIcon height={"44px"} width={"56px"} />}
            type="Url"
            onChange={handleVideoURLChane}
            error={videoError}
            ref={videoUrlInputRef}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ mt: "24px" }}>
        <ImageDropzone
          onDropped={handleVideoDrop}
          ref={imageDropzoneVideoRef}
          heading=""
          icon={<UploadDetailIcon height={"44px"} width={"56px"} />}
          type="Video"
          error={videoError}
        />
      </Box>
    );
  };

  return (
    <Box sx={{ mt: "40px" }}>
      <ImageDropzone
        onDropped={handleThumbnailDrop}
        ref={imageDropzoneThumbnailRef}
        heading="Upload Thumbnail Image"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={thumbnailImageError}
        required
        resolution={"780w X 440h"}
      />
      <Box
        sx={{
          pb: "16px",
          pt: "24px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text type="mediumBold">Upload Video here</Text>
        <Text
          type="mediumBold"
          sx={{
            fontWeight: 600,
            fontSize: "16px",
            letterSpacing: "0em",
            lineHeight: "20px",
            fontFamily: "Graphik",
            color: colors.radicalRed,
          }}
        >
          *
        </Text>
      </Box>

      <ToggleOption
        options={videoTypeOptions}
        onClick={handleVideoType}
        selected={videoType}
      />
      {renderVideoSelectType()}
      <Grid container spacing={2} mt={"15px"}>
        <Grid item xs={6}>
          <CheckBox
            type="Youtube"
            checked={isYoutube}
            onClick={handleIsYoutubeCheckBoxChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="WealthForumVimeo"
            checked={isVimeo}
            onClick={handleIsVimeoCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box sx={{ pb: "16px" }}>
        <Text type="mediumBold">And add text here</Text>
      </Box>
      <FormProvider {...formMethods}>
        <FormInput
          variant="filled"
          name="title"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Title"
          required
        />
        <Box sx={{ height: "20px" }} />
        <DateInput
          label="Content Date"
          // error={formMethods.formState.errors.eventDate?.message}
          onChange={(newValue: Date | null) => {
            if (newValue) {
              formMethods.setValue(
                "contentUploadDate",
                moment(newValue).format("YYYY-MM-DD")
              );
            }
          }}
        />
        <Box sx={{ height: "20px" }} />
        <FormInput
          variant="filled"
          name="creator"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Creator Name"
        />
        <Box sx={{ height: "20px" }} />
        <FormInput
          variant="filled"
          name="designation"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Creator Designation"
        />
        <Box sx={{ height: "20px" }} />
        <FormSelect
          label="Select Type"
          labelId="type"
          id="type"
          options={[
            {
              title: "Equity",
              value: "Equity",
            },
            {
              title: "Fixed Income",
              value: "Fixed Income",
            },
            {
              title: "Fund Of Funds",
              value: "Fund Of Funds",
            },
          ]}
          name="category"
          required
        />
        <Box sx={{ height: "20px" }} />
        <MultiSelect
          label="Select Related Schemes"
          labelId="schemes"
          id="schemes"
          options={schemeNames}
          onOptionSelect={onSelectSchemes}
        />
        <Box sx={{ height: "20px" }} />
        {/* <RichTextInput
        onChange={handleOnDescriptionChange}
        error={formMethods.formState.errors["description"]?.message}
      /> */}
        <FormInput
          variant="filled"
          name="description"
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Description"
          multiline
          rows={4}
        />
      </FormProvider>
      <Box sx={{ my: "40px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBox
            type="Trending"
            checked={isTrending}
            onClick={handleTrendingCheckBoxChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="Recomended"
            checked={isRecomended}
            onClick={handleRecomendedCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={"15px"}>
        <Grid item xs={6}>
          <CheckBox
            type="Featured"
            checked={isFeatured}
            onClick={handleFeaturedCheckBoxChange}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="ShowNotification"
            checked={isShowNotification}
            onClick={handleNotificationCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      {isShowNotification ? (
        <>
          <Text type="mediumBold">Notification Details</Text>
          <FormProvider {...formMethods}>
            <FormInput
              variant="filled"
              name="notificationTitle"
              InputProps={{
                disableUnderline: true,
              }}
              label="Add Title (max 1000 characters) *"
              sx={{ mt: "20px " }}
              inputProps={{ maxLength: 1000 }}
            />

            <FormInput
              variant="filled"
              name="notificationShortDescription"
              label="Short descriptions (Max. 1000 characters) *"
              sx={{ my: "30px " }}
              inputProps={{ maxLength: 1000 }}
            />
            <Editor
              placeholder="Descriptions (Max. 1000 characters)"
              onChange={(text) => {
                formMethods.setValue("notificationDescription", text, {
                  shouldValidate: true,
                });
              }}
              reset={didUploadContent}
            />
            {formMethods.formState.errors?.notificationDescription?.message && (
              <Text type="error">
                {formMethods.formState.errors?.notificationDescription?.message}
              </Text>
            )}
            <Box sx={{ width: "100%", height: "20px" }} />
          </FormProvider>
          <Box sx={{ mt: "40px", mb: "21px" }}>
            <Divider variant="fullWidth" />
          </Box>
        </>
      ) : null}
      <Text type="error">{uploadContentError}</Text>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          onClick={formMethods.handleSubmit(onSubmit)}
          variant="contained"
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>
      </Box>
      {isUploadingContent ||
      isLodingInterviewVideos ||
      isDeleteInterviewVideos ? (
        <ActivityOverlay />
      ) : null}
    </Box>
  );
};
export default InterviewVideos;
