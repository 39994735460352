import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import JoditEditor from "jodit-react";

const Editor = ({ placeholder, reset, onChange = (data) => {} }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  useEffect(() => {
    if (onChange) {
      onChange(content);
    }
  }, [content]);

  useEffect(() => {
    if (reset) {
      setContent("");
    }
  }, [reset]);

  const config = {
    readOnly: false,
    autofocus: false,
    useSearch: false,
    spellcheck: true,
    enter: "P",
    defaultMode: "1",
    toolbarAdaptive: false,
    toolbarSticky: false,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    minHeight: 200,
    maxHeight: 500,
    limitChars: 1000,
    minWidth: null,
    uploader: {
      insertImageAsBase64URI: true,
    },
    buttons:
      "bold, italic, underline, strikethrough |,ul, ol |,link |,font, fontsize ",
    editorCssClass: "alic",
    placeholder: placeholder,
  };
  return (
    <JoditEditor
      ref={editor}
      value={content}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => {
        return setContent(newContent);
      }} // preferred to use only this option to update the content for performance reasons
    />
  );
};

export default Editor;
