import Text from "components/Text";

const Roles = () => {
  return (
    <div>
      <Text type="largeBold">Roles</Text>
    </div>
  );
};

export default Roles;
