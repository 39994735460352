import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import { TProductVideosParams, TProductVideoDetailsParams } from "./types";
import ToastCenter from "utils/ToastCenter";
import Request from "networking/Request";
import {
  loadProductVideos,
  didLoadProductVideos,
  didFailToLoadProductVideos,
  deleteProductVideos,
  didDeleteProductVideos,
  didFailedToDeleteProductVideos,
} from "./slice";

const PRODUCT_VIDEOS = "v1/admin/productVideo/fetchAll";
const PRODUCT_VIDEOS_DELETE = "v1/admin/content/delete";

function* handleLoadProductVideos(action: PayloadAction<TProductVideosParams>) {
  const response: TResponse = yield call(
    Request.POST,
    PRODUCT_VIDEOS,
    action.payload
  );
  if (response.error) {
    yield put(didFailToLoadProductVideos(response.error));
  } else {
    yield put(didLoadProductVideos(response.data.data.resultList));
  }
}

function* handleDeleteProductVideos(
  action: PayloadAction<TProductVideosParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    PRODUCT_VIDEOS_DELETE,
    action.payload
  );
  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailedToDeleteProductVideos(response.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteProductVideos(action?.payload?.id));
  }
}

function* watcherLoadProductVideos() {
  yield takeLatest(loadProductVideos.type, handleLoadProductVideos);
}

function* watcherDeleteProductVideos() {
  yield takeLatest(deleteProductVideos.type, handleDeleteProductVideos);
}

export function* productVideosSaga() {
  yield all([watcherLoadProductVideos(), watcherDeleteProductVideos()]);
}
