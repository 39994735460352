import { Box, Button, Card } from "@mui/material";
import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import { useState, useEffect } from "react";

import ExcelAddRm from "./ExcelAddRm";
import ManualAddRM from "./ManualAddRM";
import RMList from "./RMList";

import { useAppSelector } from "hooks/useAppSelector";

import ActivityOverlay from "components/ActivityOverlay";
import colors from "res/colors";
//
import { useAppDispatch } from "hooks/useAppDispatch";
import ToastCenter from "utils/ToastCenter";

const SalesRMManagement = () => {
  const {
    isUploadingSalesRMList,
    isAddingSalesRM,
    isLoadingSalesRMs,
    isUpdatingSalesRM,
    isSearchingSalesRM,
    isAddingNewSalesRM,
    isDeletingNewSalesRM,
    isEditingNewSalesRM,
    isSwapingNewSalesRM,
  } = useAppSelector((state) => state.salesRM);
  const dispatch = useAppDispatch();
  const [option, setOption] = useState("Upload excel");
  const { baseURL } = useAppSelector((state) => state.auth);
  const handleEventClick = (displayComponent: string) => {
    setOption(displayComponent);
  };
  const handleDownloadSalesRMSheet = () => {
    // dispatch(downloadSalesRMSheet());
    const url = baseURL + "v1/admin/rm/getRmExcel";
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.click();
    ToastCenter.success(
      "File downloading in progress. Please wait for some time."
    );
  };

  const renderContent = () => {
    if (option === "Upload excel") {
      return <ExcelAddRm />;
    } else if (option === "Manually") {
      return <ManualAddRM />;
    }
  };
  //    const leadDatas = salesRMs.data.salesRMResponseList.map((rowData: any, index: number)) => {
  //
  // }
  // const leadData = [
  //   {
  //     id: 1,
  //     name: "Vinayak Champak Gadepalli",
  //     email: "vinayak.cg@gmail.com",
  //     mobile: 9876543210,
  //     employeeId: "A123456789",
  //     status: "active",
  //   },
  //   {
  //     id: 2,
  //     name: "Vinayak",
  //     email: "vinayak.cg@gmail.com",
  //     mobile: 9876543210,
  //     employeeId: "A123456789",
  //     status: "inactive",
  //   },
  // ];

  const addSalesRmOptions = [
    { label: "Upload excel" },
    //  { label: "Manually" }
  ];
  return (
    <Box
      sx={{ minHeight: "vh", width: "65%", ml: "20%", pt: "50px", pb: "48px" }}
    >
      <Text type="headingBold">Sales RM</Text>
      <Card
        sx={{
          px: "40px",
          py: "40px",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          mt: "20px",
          mb: "48px",
          borderRadius: "16px",
        }}
      >
        <Text type="extraLargeBold">ADD Sales RM</Text>

        <Box sx={{ mt: "16px" }}>
          <ToggleOption
            options={addSalesRmOptions}
            onClick={handleEventClick}
            selected={option}
          />
        </Box>
        {renderContent()}
      </Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text type="subHeadingBold">List of Sales RM</Text>
        {/** Added export salesRM sheet logic */}
        <Button
          variant="contained"
          sx={{
            py: "5px",
            px: "20px",
            ml: "10px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDownloadSalesRMSheet}
        >
          Export SalesRM List
        </Button>
      </Box>

      <RMList />
      {(isUploadingSalesRMList ||
        isAddingSalesRM ||
        isLoadingSalesRMs ||
        isSearchingSalesRM ||
        isUpdatingSalesRM ||
        isAddingNewSalesRM ||
        isDeletingNewSalesRM ||
        isEditingNewSalesRM ||
        isSwapingNewSalesRM) && <ActivityOverlay />}
    </Box>
  );
};
export default SalesRMManagement;
