import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import {
  addUser,
  didAddedUser,
  didFailToAddUser,
  addAdmin,
  didAddedAdmin,
  didFailToAddAdmin,
  userLists,
  didUserList,
  didFailToLoadUserList,
  deleteUser,
  didDeleteUser,
  didFailToDeleteUser,
  didFailToUpdateUser,
  didUpdatedUser,
  updateUser,
} from "./slice";

const ADD_ADMIN_ENDPOINT = "v1/admin/addAdmin";
const ADD_USER_ENDPOINT = "v1/admin/addUser";
const DELETE_USER_ENDPOINT = "v1/admin/deleteUser";
const EDIT_USER_ENDPOINT = "v1/admin/editUser";
const USER_LIST_ENDPOINT = "v1/admin/userListByPage";

// find sales rm
// update sales rm
function* handleAddUser(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    ADD_USER_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToAddUser(response.error));
  } else {
    yield put(didAddedUser());
  }
}

function* handleAddAdmin(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    ADD_ADMIN_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToAddAdmin(response.error));
  } else {
    yield put(didAddedAdmin());
  }
}

function* handleLoadUser(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    USER_LIST_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToLoadUserList(response.error));
  } else {
    yield put(didUserList(response.data.data.findAdminResponse));
  }
}

function* handleDeleteUser(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    DELETE_USER_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToDeleteUser(response.error));
  } else {
    yield put(didDeleteUser());
  }
}
function* handleUpdateUser(action: PayloadAction<any>) {
  const params = {
    ...action.payload,
  };

  const response: TResponse = yield call(
    Request.POST,
    EDIT_USER_ENDPOINT,
    params
  );
  if (response.error) {
    yield put(didFailToUpdateUser(response.error));
  } else {
    yield put(didUpdatedUser());
  }
}

function* watcherAddUser() {
  yield takeLatest(addUser.type, handleAddUser);
}
function* watcherAddAdmin() {
  yield takeLatest(addAdmin.type, handleAddAdmin);
}

function* watcherLoadUser() {
  yield takeLatest(userLists.type, handleLoadUser);
}

function* watcherDeleteUser() {
  yield takeLatest(deleteUser.type, handleDeleteUser);
}
function* watcherUpdateUser() {
  yield takeLatest(updateUser.type, handleUpdateUser);
}

export function* userManagementSaga() {
  yield all([
    watcherAddAdmin(),
    watcherAddUser(),
    watcherLoadUser(),
    watcherDeleteUser(),
    watcherUpdateUser(),
  ]);
}
