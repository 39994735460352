import { Box, Button, Card, Divider } from "@mui/material";
import Select from "components/Select";
import React, { useEffect, useRef, useState } from "react";
import { SelectOption } from "components/FormSelect";
import { TContentForm } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import ImageDropzone from "components/ImageDropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import FormInput from "components/FormInput";
import colors from "res/colors";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import {
  loadLoginBanner,
  resetLoginBannerStates,
  uploadLoginBanner,
} from "../store/slice";
import { useAppDispatch } from "hooks/useAppDispatch";

const AddLoginBanner = () => {
  const {
    isUploadingLoginBanner,
    uploadLoginBannerError,
    didUploadLoginBanner,
  } = useAppSelector((state) => state.bannerManagement);
  const dispatch = useAppDispatch();
  const { schemeNames } = useAppSelector((state) => state.schemes);
  const [bannerImageError, setBannerImageError] = useState("");
  const imageDropzoneBannerRef = useRef<any>(null);
  const [scheme, setScheme] = React.useState("");

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    if (didUploadLoginBanner) {
      ToastCenter.success("Successfully added login banner.");
      formMethods.setValue("banner", null);
      formMethods.setValue("description", "");
      imageDropzoneBannerRef.current.clearImage();
      dispatch(resetLoginBannerStates());
      dispatch(loadLoginBanner());
    }
  }, [didUploadLoginBanner]);

  const onSubmit = (data: TContentForm) => {
    let hasError = false;

    if (!data.banner) {
      setBannerImageError("Please set banner image");
      hasError = true;
    }
    if (hasError) {
      return;
    }
    const params = {
      banner: data.banner,
      imageRequest: {
        description: data.description,
      },
    };

    dispatch(uploadLoginBanner(params));
  };

  const handleBannerDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("banner", imageData);
  };

  return (
    <Box sx={{ pt: "30px" }}>
      <ImageDropzone
        onDropped={handleBannerDrop}
        ref={imageDropzoneBannerRef}
        heading={`Upload Banner Image`}
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="Image"
        error={bannerImageError}
        required
        resolution={"960w X 630h"}
      />
      <Box sx={{ mt: "10px" }} />
      <FormProvider {...formMethods}>
        <FormInput
          variant="filled"
          name={`description`}
          InputProps={{
            disableUnderline: true,
          }}
          label="Add Description (Max. 100 characters)"
          multiline
          rows={2}
          required
        />
      </FormProvider>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          mt: "20px",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",

            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default AddLoginBanner;
