import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type EventsState = {
  isCreatingEvent: boolean;
  createEventError: string;
  didCreateEvent: boolean;

  isLoadingEvent: boolean;
  loadEventError: string;
  allEvent: Array<any>;

  isDeletingEvent: boolean;
  deleteEventError: string;
  deletedEvent: string;

  isUpdatingEvent: boolean;
  updateEventError: string;
  didUpdateEvent: boolean;
};

const INITIAL_STATE: EventsState = {
  isCreatingEvent: false,
  createEventError: "",
  didCreateEvent: false,

  isLoadingEvent: false,
  loadEventError: "",
  allEvent: [],

  isDeletingEvent: false,
  deleteEventError: "",
  deletedEvent: "",

  isUpdatingEvent: false,
  updateEventError: "",
  didUpdateEvent: false,
};

const events = createSlice({
  name: "events",
  initialState: INITIAL_STATE,
  reducers: {
    createEvent: (state, action: PayloadAction<any>) => {
      state.createEventError = "";
      state.isCreatingEvent = true;
      state.didCreateEvent = false;
    },
    didCreateEvent: (state) => {
      state.isCreatingEvent = false;
      state.didCreateEvent = true;
    },
    didFailToCreateEvent: (state, action: PayloadAction<string>) => {
      state.isCreatingEvent = false;
      state.createEventError = action.payload;
    },
    loadEvent: (state, action: PayloadAction<any>) => {
      state.loadEventError = "";
      state.isLoadingEvent = true;
      state.allEvent = [];
    },
    didLoadEvent: (state, action: PayloadAction<any>) => {
      state.isLoadingEvent = false;
      state.allEvent = action.payload;
    },
    didFailToLoadEvent: (state, action: PayloadAction<string>) => {
      state.isLoadingEvent = false;
      state.loadEventError = action.payload;
    },
    deleteEvent: (state, action: PayloadAction<any>) => {
      state.deleteEventError = "";
      state.isDeletingEvent = true;
      state.deletedEvent = "";
    },
    didDeleteEvent: (state, action: PayloadAction<any>) => {
      state.isDeletingEvent = false;
      state.deletedEvent = action.payload;
      state.deleteEventError = "";
    },
    didFailToDeleteEvent: (state, action: PayloadAction<string>) => {
      state.isDeletingEvent = false;
      state.deleteEventError = action.payload;
    },
    updateEvent: (state, action: PayloadAction<any>) => {
      state.isUpdatingEvent = true;
      state.didUpdateEvent = false;
    },
    didUpdateEvent: (state) => {
      state.isUpdatingEvent = false;
      state.didUpdateEvent = true;
    },
    didFailToUpdateEvent: (state, action: PayloadAction<string>) => {
      state.isUpdatingEvent = false;
      state.updateEventError = action.payload;
    },
    resetCreateEventState: (state) => {
      state.didCreateEvent = false;
    },
    resetUpdateEventState: (state) => {
      state.isUpdatingEvent = false;
      state.didUpdateEvent = false;
      state.updateEventError = "";
    },
  },
});

export const {
  resetUpdateEventState,
  updateEvent,
  didUpdateEvent,
  didFailToUpdateEvent,
  deleteEvent,
  didDeleteEvent,
  didFailToDeleteEvent,
  loadEvent,
  didLoadEvent,
  didFailToLoadEvent,
  createEvent,
  didCreateEvent,
  didFailToCreateEvent,
  resetCreateEventState,
} = events.actions;

export default events.reducer;
