import { Box, Button } from "@mui/material";
import { useState } from "react";
import colors from "res/colors";
import { ToggleOptioProps } from ".";

const ToggleOption: React.FC<ToggleOptioProps> = ({
  options,
  onClick,
  selected,
}) => {
  // const [selected, setSelected] = useState(options[0].label);

  const handleClick = (buttonOption: string) => {
    // setSelected(buttonOption);
    onClick(buttonOption);
  };
  return (
    <div>
      <Box>
        {options.map((buttonOption, index) => {
          return (
            <Button
              sx={[
                classes.body,
                selected === buttonOption.label
                  ? {
                      backgroundColor: colors.backGroundBlue,
                      color: colors.white,
                    }
                  : {
                      backgroundColor: "#d3d3d3",

                      color: "#000000",
                      opacity: 0.6,
                    },
              ]}
              onClick={() =>
                handleClick(buttonOption.value || buttonOption.label)
              }
              key={`${buttonOption.label}+${index}`}
            >
              {buttonOption.label}
            </Button>
          );
        })}
      </Box>
    </div>
  );
};

const classes = {
  body: {
    mr: "16px",
    py: "10px",
    px: "24px",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: 0,
    borderRadius: "24px",
  },
  selected: {
    backGroundColor: colors.backGroundBlue,
    color: colors.red,
  },
  default: {
    backGroundColor: colors.backGroundBlue,
    color: colors.black,
  },
};
export default ToggleOption;
