import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { TResponse } from "types";
import Request from "networking/Request";
import ToastCenter from "utils/ToastCenter";
import { TOnePagerByCategoryParams, TOnePagerDeleteParams } from "./types";
import {
  loadAllOnePager,
  didLoadAllOnePager,
  didFailToLoadAllOnePager,
  deleteOnePager,
  didDeleteOnePager,
  didFailedToDeleteOnePager,
} from "./slice";

const ALL_ONEPAGER_ENDPOINT = "v1/admin/onepager/fetchAll";
const ONEPAGER_DELETE = "v1/admin/content/delete";

function* handleLoadAllOnePager() {
  const response: TResponse = yield call(
    Request.POST,
    ALL_ONEPAGER_ENDPOINT,
    {}
  );
  if (response.error) {
    yield put(didFailToLoadAllOnePager(response.error));
  } else {
    yield put(didLoadAllOnePager(response.data.data));
  }
  //
  //
}

function* handleDeleteOnePager(action: PayloadAction<TOnePagerDeleteParams>) {
  const response: TResponse = yield call(
    Request.POST,
    ONEPAGER_DELETE,
    action.payload
  );
  if (response.error) {
    ToastCenter.success("Successfully deleted content");
    yield put(didFailedToDeleteOnePager(response.error));
  } else {
    ToastCenter.error("Unable to delete content");
    yield put(didDeleteOnePager(response.data.data.resultList));
  }
}

function* watcherLoadAllOnePager() {
  yield takeLatest(loadAllOnePager.type, handleLoadAllOnePager);
}

function* watcherDeleteAllOnePager() {
  yield takeLatest(deleteOnePager.type, handleDeleteOnePager);
}

export function* onepagerSaga() {
  yield all([watcherLoadAllOnePager(), watcherDeleteAllOnePager()]);
}
