import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from "@mui/material";
import { useState, useEffect } from "react";
import TagInput from "components/TagInput";
import { FormProvider, useForm } from "react-hook-form";
import { salesRmProps } from "./types";
import { yupResolver } from "@hookform/resolvers/yup";
import { rmDataValidationSchema } from "./RmDataValidationSchema";
import FormInput from "components/FormInput";
import FormSelect, { SelectOption } from "components/FormSelect";
import colors from "res/colors";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import {
  addSalesRM,
  resetAddSalesRMStates,
  loadSalesRMs,
  getCount,
} from "./store/slice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";

const ManualAddRM = () => {
  const [addMFDForm, setAddMFDForm] = useState(false);
  const [mfdCategory, setMfdCategory] = useState("");

  const dispatch = useAppDispatch();
  const { didAdddSalesRM, addSalesRMError } = useAppSelector(
    (state) => state.salesRM
  );

  const mfdCategoryOptions = [
    { title: "IFA", value: "IFA" },
    { title: "IFA", value: "IFA" },
  ];

  const handleAddMFD = () => {
    setAddMFDForm(true);
  };

  const handleCategory = (option: SelectOption) => {
    setMfdCategory(option.value);
  };

  const formMethods = useForm<salesRmProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(rmDataValidationSchema),
  });

  const openDialogRender = () => {};

  const closeDialog = () => {
    setAddMFDForm(false);
  };

  useEffect(() => {
    if (didAdddSalesRM) {
      ToastCenter.success("Successfully added SalesRM");

      formMethods.setValue("address", "");
      formMethods.setValue("city", "");
      formMethods.setValue("email", "");
      formMethods.setValue("name", "");
      // formMethods.setValue("mobile", " ");
      // formMethods.setValue("pincode", " ");
      formMethods.setValue("state", "");

      formMethods.setValue("mfdArn", "");
      formMethods.setValue("mfdCity", "");
      formMethods.setValue("mfdEmail", "");
      formMethods.setValue("mfdName", "");
      formMethods.setValue("mfdState", "");
      dispatch(resetAddSalesRMStates());
      dispatch(getCount());
      dispatch(
        loadSalesRMs({
          pageNumber: 1,
          endPageNumber: 100,
        })
      );
    }
  }, [dispatch, didAdddSalesRM]);

  const onSubmit = (data: salesRmProps) => {
    const params = {
      address1: data.address,
      city: data.city,
      email: data.email,
      mobileNum: data.mobile,
      name: data.name,
      pinCode: data.pincode,
      state: data.state,
      mfdList: [
        {
          arnNumber: data.mfdArn,
          city: data.mfdCity,
          mfdEmail: data.mfdEmail,
          name: data.mfdName,
          state: data.mfdState,
          type: data.mfdCategory,
        },
      ],
    };
    dispatch(addSalesRM(params));
  };

  const renderAddMFDForm = () => {
    if (addMFDForm) {
      return (
        <Dialog open={addMFDForm}>
          <DialogActions>
            <Button onClick={closeDialog}>
              <Grid item xs={8}>
                <CloseOutlinedIcon />
              </Grid>
            </Button>
          </DialogActions>
          <DialogTitle>Add MFD</DialogTitle>
          <DialogContent>
            <FormProvider {...formMethods}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormInput
                  variant="filled"
                  label="Name"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"mfdName"}
                />
                <FormInput
                  variant="filled"
                  label="ARN"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"mfdArn"}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
                <FormInput
                  variant="filled"
                  label="Email"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"mfdEmail"}
                />
                <FormInput
                  variant="filled"
                  label="State"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"mfdState"}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
                <FormInput
                  variant="filled"
                  label="City"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"mfdCity"}
                />
                <FormSelect
                  placeHolder={"Category"}
                  onOptionSelect={handleCategory}
                  value={mfdCategory}
                  options={mfdCategoryOptions}
                  name={"mfdCategory"}
                />
              </Box>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ py: "12px", px: "20px", textTransform: "none" }}
                // onClick={formMethods.handleSubmit(onSubmit)}
                onClick={closeDialog}
              >
                Add
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      );
    }
  };

  return (
    <Box sx={{ mt: "24px" }}>
      {addSalesRMError}
      <FormProvider {...formMethods}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <FormInput
            variant="filled"
            label="Name"
            sx={{ mr: "16px" }}
            InputProps={{
              disableUnderline: true,
            }}
            name={"name"}
          />
          <FormInput
            variant="filled"
            label="Mobile Number"
            sx={{ mr: "16px" }}
            InputProps={{
              disableUnderline: true,
            }}
            name={"mobile"}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
          <FormInput
            variant="filled"
            label="Email"
            InputProps={{
              disableUnderline: true,
            }}
            name={"email"}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
          <FormInput
            variant="filled"
            label="Address"
            sx={{ mr: "16px" }}
            InputProps={{
              disableUnderline: true,
            }}
            name={"address"}
          />
          <FormInput
            variant="filled"
            label="State"
            InputProps={{
              disableUnderline: true,
            }}
            name={"state"}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
          <FormInput
            variant="filled"
            label="City"
            sx={{ mr: "16px" }}
            InputProps={{
              disableUnderline: true,
            }}
            name={"city"}
          />
          <FormInput
            variant="filled"
            label="Pincode"
            InputProps={{
              disableUnderline: true,
            }}
            name={"pincode"}
          />
        </Box>
      </FormProvider>

      <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
        <TagInput />
      </Box>

      <Box sx={{ mt: "40px", mb: "20px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          sx={{
            py: "12px",
            px: "20px",
            mr: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={formMethods.handleSubmit(onSubmit)}
        >
          Add User
        </Button>
        <Button
          variant="contained"
          onClick={handleAddMFD}
          sx={{
            py: "12px",
            px: "20px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
        >
          Add MFD
        </Button>
      </Box>
      {renderAddMFDForm()}
    </Box>
  );
};

export default ManualAddRM;
