import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import Text from "components/Text";
import { ChangeEvent, FC, useState, useEffect } from "react";
import colors from "res/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DoNotDisturbAltOutlinedIcon from "@mui/icons-material/DoNotDisturbAltOutlined";
import { userListType } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { userDataProps } from "../Users/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { userDataValidationSchema } from "../Users/UserDataValidationSchema";
import FormInput from "components/FormInput";
import FormSelect from "components/Select";
import { SelectOption } from "components/FormSelect";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { updateUser, resetUpdateUser } from "../store/slice";

import ToastCenter from "utils/ToastCenter";
import ActivityOverlay from "components/ActivityOverlay";
export interface UserListItemProps {
  data: userListType;
  onDelete: (data: userListType) => void;
  onEdit: (data: userListType) => void;
}
const UserListItem: FC<UserListItemProps> = ({ data, onDelete, onEdit }) => {
  const dispatch = useAppDispatch();
  const { isUpdateUser, didUpdatedUser, updateUserError } = useAppSelector(
    (state) => state.userManagement
  );
  const [expanded, setExpanded] = useState(false);
  const [dialog, setDialog] = useState("");
  const [writeAccess, setWriteAccess] = useState(data.writeAccess);
  const [readAccess, setReadAccess] = useState(data.readAccess);
  const [deleteAccess, setDeleteAccess] = useState(data.deleteAccess);
  const [accessTo, setAccessTo] = useState(data.accessTo);

  useEffect(() => {
    formMethods.setValue("name", data.name);
    formMethods.setValue("email", data.emailId);
    formMethods.setValue("writeAccess", data.writeAccess);
    formMethods.setValue("readAccess", data.readAccess);
    formMethods.setValue("deleteAccess", data.deleteAccess);
    formMethods.setValue("accessTo", data.accessTo);
    // if (didUpdatedUser) {
    //   ToastCenter.success("User has been updated successfully");
    //   dispatch(resetUpdateUser());
    // }
  }, [didUpdatedUser, data]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const renderExpandIcon = () => {
    if (expanded) return <ExpandLessIcon />;
    return <ExpandMoreIcon />;
  };
  const handleClose = () => {
    setDialog("");
  };

  const handleButtonClick = (type: string) => {
    setDialog(type);
  };

  const handleConfirmEdit = (data: userListType) => {
    setDialog("");

    onEdit(data);
  };

  const handleConfirmDelete = (data: any) => {
    setDialog("");
    onDelete(data);
  };

  const handleAccessTo = (option: SelectOption) => {
    setAccessTo(option.value);
  };

  const handleDeleteAccess = (option: SelectOption) => {
    setDeleteAccess(option.value);
  };

  const handleWriteAccess = (option: SelectOption) => {
    setWriteAccess(option.value);
  };

  const handleReadAccess = (option: SelectOption) => {
    setReadAccess(option.value);
  };
  const saleModule = [{ title: "SALE RM MODULE", value: "SALES_RM_MODULE" }];

  const formMethods = useForm<userListType>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(userDataValidationSchema),
  });

  const onSubmit = (data: userListType) => {
    // onEdit(data);
    const params = {
      name: data.name,
      emailId: data.email,
      readAccess: data.readAccess,
      writeAccess: data.writeAccess,
      deleteAccess: data.deleteAccess,
      accessTo: data.accessTo,
    };
    dispatch(updateUser(params));
    setDialog("");
  };

  const Access = [
    {
      title: "YES",
      value: "true",
    },
    {
      title: "NO",
      value: "false",
    },
  ];

  const renderDialog = () => {
    if (dialog === "delete") {
      return (
        <Dialog open={dialog === "delete"}>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            This action will permanently delete the content.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleConfirmDelete(data)} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (dialog === "edit") {
      return (
        <Dialog open={dialog === "edit"}>
          <DialogTitle>Edit User data</DialogTitle>
          <DialogContent>
            <FormProvider {...formMethods}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormInput
                  variant="filled"
                  label="Name"
                  sx={{ mr: "16px" }}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  name={"name"}
                />
                <FormInput
                  variant="filled"
                  label="Email"
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                  name={"email"}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: "24px",
                  alignContent: "space-between",
                }}
              >
                <FormSelect
                  placeHolder="Write Access"
                  onOptionSelect={handleWriteAccess}
                  options={Access}
                  name={"writeAccess"}
                  value={writeAccess}
                />
                <Box sx={{ mr: "16px" }}></Box>
                <FormSelect
                  placeHolder="Read Access"
                  onOptionSelect={handleReadAccess}
                  options={Access}
                  name={"readAccess"}
                  value={readAccess}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: "24px",
                  alignContent: "space-between",
                }}
              >
                <FormSelect
                  onOptionSelect={handleAccessTo}
                  options={saleModule}
                  placeHolder="Has Access To"
                  name={"accessTo"}
                  value={accessTo}
                />
                <Box sx={{ mr: "16px" }}></Box>
                <FormSelect
                  placeHolder="Delete Access"
                  onOptionSelect={handleDeleteAccess}
                  options={Access}
                  name={"deleteAccess"}
                  value={deleteAccess}
                />
              </Box>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={formMethods.handleSubmit(onSubmit)} autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (dialog === "view") {
      <Dialog open={dialog === "view"}>
        <DialogTitle>View User</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Text type="listMediumBold">Name:</Text>
            <Text type="listMedium">Ravi</Text>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "24px",
              alignContent: "space-between",
            }}
          >
            <Text type="listMediumBold">Name:</Text>
            <Text type="listMedium">Ravi</Text>
            {/* <Box sx={{ mr: "16px" }}></Box> */}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: "24px",
              alignContent: "space-between",
            }}
          >
            <Text type="listMediumBold">Name:</Text>
            <Text type="listMedium">Ravi</Text>
          </Box>
        </DialogContent>
      </Dialog>;
    }
  };
  const renderExpandedView = () => {
    if (expanded) {
      return (
        <div>
          <Grid container direction="row" sx={{ mt: "16px" }}>
            <Grid xs={4.5}>
              <Text type="listSmallBold">Read Access</Text>
              <Text type="listSmall" sx={{ mt: "10px" }}>
                {data.readAccess ? "YES" : "NO"}
              </Text>
            </Grid>
          </Grid>

          <Box sx={{ py: "20px" }}>
            <Divider />
          </Box>

          {!data?.admin && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <Box>
                <IconButton
                  sx={{ mr: "10px" }}
                  onClick={() => handleButtonClick("edit")}
                >
                  <EditOutlinedIcon sx={{ color: colors.backGroundBlue }} />
                </IconButton>
                {/* <IconButton>
                <DoNotDisturbAltOutlinedIcon
                  sx={{ color: colors.backGroundBlue, mx: "25px" }}
                />
              </IconButton> */}
                <Button
                  variant="outlined"
                  onClick={() => handleButtonClick("delete")}
                  sx={{
                    backgroundColor: colors.white,
                    color: colors.backGroundBlue,

                    borderColor: colors.backGroundBlue,
                  }}
                >
                  Delete User
                </Button>
                {renderDialog()}
              </Box>
            </Box>
          )}
        </div>
      );
    }
  };

  return (
    <Box>
      <Card sx={{ px: "20px", mb: "12px", pt: "20px", pb: "10px" }}>
        <Grid>
          <Grid container direction="row">
            <Grid xs={4.5}>
              <Text type="listMediumBold">{data.name}</Text>
            </Grid>
            <Grid xs={5.0}>
              <Text type="listMedium">{data.emailId}</Text>
            </Grid>
            <Grid xs={1.5}>
              <Text type="listMedium">{data.admin ? "Admin" : "User"}</Text>
            </Grid>
            {/* <Grid xs={3.5}>
              <Text type="listMedium">{data.readAccess}</Text>
            </Grid> */}
            {/* <Grid xs={1.5}>
              <Text type="listMedium">{data.accessTo}</Text>
            </Grid> */}
            <Grid xs={0.5}>
              <Box
                sx={{
                  mt: "-10px",
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                }}
              >
                <IconButton onClick={handleExpand}>
                  {renderExpandIcon()}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {renderExpandedView()}
      </Card>
    </Box>
  );
};

export default UserListItem;
