import React, { useState, useEffect } from "react";
import { Card, Box } from "@mui/material";
import Logo from "res/images/Invesco_MF_Logo_Blue_Pos_RGB@2x.png";

import UserNameForm from "./UserNameForm";
import OtpForm from "./OtpForm";
import colors from "res/colors";
// import { makeStyles } from "@material-ui/styles";

import { useAppDispatch } from "hooks/useAppDispatch";

import {
  resetStates,
  resetResendOtpStates,
  resetValidOtpStates,
} from "./store/slice";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();

  // const classes = useStyles();
  const [displayComponent, setDisplayComponent] = useState("Username");
  const [email, setEmail] = useState("");

  useEffect(() => {
    return () => {
      dispatch(resetStates());
      dispatch(resetResendOtpStates());
      dispatch(resetValidOtpStates());
    };
  }, []);

  const signInCallBack = (email: string) => {
    setEmail(email);
    setDisplayComponent("OTP");
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: colors.backGroundBlue,
      }}
    >
      <Card
        sx={{
          width: "586px",
          height: "420px",
          marginTop: "222px",
          paddingTop: "48px",
          paddingLeft: "48px",
          paddingRight: "48px",
          marginBottom: "138px",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img src={Logo} height={"24px"} width={"293px"} alt="Invesco Logo" />
        {displayComponent === "Username" ? (
          <UserNameForm signInCallBack={signInCallBack} />
        ) : (
          <OtpForm email={email} />
        )}
      </Card>
    </Box>
  );
};

// const useStyles = makeStyles({
//   container: {
//     display: "flex",
//     flexDirection: "column",
//     alignItems: "center",
//     backgroundColor: colors.backGroundBlue,
//   },
//   cardStyle: {
//     width: "586px",
//     height: "420px",
//     marginTop: "222px",
//     paddingTop: "48px",
//     paddingLeft: "48px",
//     paddingRight: "48px",
//     marginBottom: "138px",
//     borderRadius: "8px",
//     display: "flex",
//     flexDirection: "column",
//   },
// });
export default Login;
