import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type SalesRMState = {
  isUploadingLoginBanner: boolean;
  uploadLoginBannerError: string;
  didUploadLoginBanner: boolean;

  isUpdateLoginBanner: boolean;
  updateLoginBannerError: string;
  didUpdateLoginBanner: boolean;

  isLoadingLoginBanner: boolean;
  loginBannerError: string;
  loginBannerData: Array<any>;

  isDeleteLoginBanner: boolean;
  loginBannerDeleteError: string;
  loginBannerDeleted: string;
  ///////////////////////

  isUploadingSchemeBanner: boolean;
  uploadSchemeBannerError: string;
  didUploadSchemeBanner: boolean;

  isUpdateSchemeBanner: boolean;
  updateSchemeBannerError: string;
  didUpdateSchemeBanner: boolean;

  isLoadingSchemeBanner: boolean;
  schemeBannerError: string;
  schemeBannerData: Array<any>;

  isDeleteSchemeBanner: boolean;
  schemeBannerDeleteError: string;
  schemeBannerDeleted: string;
};

const INITIAL_STATE: SalesRMState = {
  /**Login Banner */
  isUploadingLoginBanner: false,
  uploadLoginBannerError: "",
  didUploadLoginBanner: false,

  isUpdateLoginBanner: false,
  updateLoginBannerError: "",
  didUpdateLoginBanner: false,

  isLoadingLoginBanner: false,
  loginBannerError: "",
  loginBannerData: [],

  isDeleteLoginBanner: false,
  loginBannerDeleteError: "",
  loginBannerDeleted: "",
  /**Scheme Banner */

  isUploadingSchemeBanner: false,
  uploadSchemeBannerError: "",
  didUploadSchemeBanner: false,

  isUpdateSchemeBanner: false,
  updateSchemeBannerError: "",
  didUpdateSchemeBanner: false,

  isLoadingSchemeBanner: false,
  schemeBannerError: "",
  schemeBannerData: [],

  isDeleteSchemeBanner: false,
  schemeBannerDeleteError: "",
  schemeBannerDeleted: "",
};

const bannerManagement = createSlice({
  name: "banner",
  initialState: INITIAL_STATE,
  reducers: {
    /**Login Banner */
    uploadLoginBanner: (state, _action: PayloadAction<any>) => {
      state.isUploadingLoginBanner = true;
    },
    didUploadLoginBanner: (state) => {
      state.isUploadingLoginBanner = false;
      state.didUploadLoginBanner = true;
    },
    didFailToUploadLoginBanner: (state, action: PayloadAction<any>) => {
      state.isUploadingLoginBanner = false;
      state.uploadLoginBannerError = action.payload;
    },
    resetLoginBannerStates: (state) => {
      state.isUploadingLoginBanner = false;
      state.didUploadLoginBanner = false;
      state.uploadLoginBannerError = "";
    },

    updateLoginBanner: (state, _action: PayloadAction<any>) => {
      state.isUpdateLoginBanner = true;
      state.didUpdateLoginBanner = false;
    },
    didUpdateLoginBanner: (state) => {
      state.isUpdateLoginBanner = false;
      state.didUpdateLoginBanner = true;
    },
    didFailToUpdateLoginBanner: (state, action: PayloadAction<any>) => {
      state.isUpdateLoginBanner = false;
      state.updateLoginBannerError = action.payload;
    },
    resetUpdateLoginBanner: (state) => {
      state.updateLoginBannerError = "";
      state.isUpdateLoginBanner = false;
      state.didUpdateLoginBanner = false;
    },

    loadLoginBanner: (state) => {
      state.isLoadingLoginBanner = true;
      state.loginBannerError = "";
      state.loginBannerData = [];
    },
    didLoadLoginBanner: (state, action: PayloadAction<any>) => {
      state.isLoadingLoginBanner = false;
      state.loginBannerData = action.payload;
    },
    didFailToLoadLoginBanner: (state, action: PayloadAction<any>) => {
      state.isLoadingLoginBanner = false;
      state.loginBannerError = action.payload;
    },
    deleteLoginBanner: (state, action: PayloadAction<any>) => {
      state.isDeleteLoginBanner = true;
      state.loginBannerDeleteError = "";
      state.loginBannerDeleted = "";
    },
    didDeleteLoginBanner: (state, action: PayloadAction<any>) => {
      state.isDeleteLoginBanner = false;
      state.loginBannerDeleteError = "";
      state.loginBannerDeleted = action.payload;
    },
    didFailedToDeleteLoginBanner: (state, action: PayloadAction<any>) => {
      state.isDeleteLoginBanner = false;
      state.loginBannerDeleteError = action.payload;
    },

    /**Scheme Banner */

    uploadSchemeBanner: (state, _action: PayloadAction<any>) => {
      state.isUploadingSchemeBanner = true;
    },
    didUploadSchemeBanner: (state) => {
      state.isUploadingSchemeBanner = false;
      state.didUploadSchemeBanner = true;
    },
    didFailToUploadSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isUploadingSchemeBanner = false;
      state.uploadSchemeBannerError = action.payload;
    },
    resetSchemeBannerStates: (state) => {
      state.isUploadingSchemeBanner = false;
      state.didUploadSchemeBanner = false;
      state.uploadSchemeBannerError = "";
    },

    updateSchemeBanner: (state, _action: PayloadAction<any>) => {
      state.isUpdateSchemeBanner = true;
      state.didUpdateSchemeBanner = false;
    },
    didUpdateSchemeBanner: (state) => {
      state.isUpdateSchemeBanner = false;
      state.didUpdateSchemeBanner = true;
    },
    didFailToUpdateSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isUpdateSchemeBanner = false;
      state.updateSchemeBannerError = action.payload;
    },
    resetUpdateSchemeBanner: (state) => {
      state.updateSchemeBannerError = "";
      state.isUpdateSchemeBanner = false;
      state.didUpdateSchemeBanner = false;
    },

    loadSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isLoadingSchemeBanner = true;
      state.schemeBannerError = "";
      state.schemeBannerData = [];
    },
    didLoadSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isLoadingSchemeBanner = false;
      state.schemeBannerData = action.payload;
    },
    didFailToLoadSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isLoadingSchemeBanner = false;
      state.schemeBannerError = action.payload;
    },
    deleteSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isDeleteSchemeBanner = true;
      state.schemeBannerDeleteError = "";
      state.schemeBannerDeleted = "";
    },
    didDeleteSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isDeleteSchemeBanner = false;
      state.schemeBannerDeleteError = "";
      state.schemeBannerDeleted = action.payload;
    },
    didFailedToDeleteSchemeBanner: (state, action: PayloadAction<any>) => {
      state.isDeleteSchemeBanner = false;
      state.schemeBannerDeleteError = action.payload;
    },
  },
});

export const {
  uploadSchemeBanner,
  didUploadSchemeBanner,
  didFailToUploadSchemeBanner,
  resetSchemeBannerStates,
  updateSchemeBanner,
  didUpdateSchemeBanner,
  didFailToUpdateSchemeBanner,
  resetUpdateSchemeBanner,
  loadSchemeBanner,
  didLoadSchemeBanner,
  didFailToLoadSchemeBanner,
  deleteSchemeBanner,
  didDeleteSchemeBanner,
  didFailedToDeleteSchemeBanner,

  deleteLoginBanner,
  didDeleteLoginBanner,
  didFailedToDeleteLoginBanner,
  loadLoginBanner,
  didLoadLoginBanner,
  didFailToLoadLoginBanner,
  resetUpdateLoginBanner,
  uploadLoginBanner,
  didUploadLoginBanner,
  didFailToUploadLoginBanner,
  updateLoginBanner,
  didUpdateLoginBanner,
  didFailToUpdateLoginBanner,
  resetLoginBannerStates,
} = bannerManagement.actions;
export default bannerManagement.reducer;
