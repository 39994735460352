import React from "react";
import { Divider, Grid } from "@mui/material";
import Text from "components/Text";
import { Box } from "@mui/system";
import moment from "moment";
import { FundManagerItemProps } from "./types";
import LoginBannerAction from "./FundManagerAction";

const FundManagerContentItem: React.FC<FundManagerItemProps> = ({
  data,
  index,
  handleContentDelete,
  handleContentEdit,
  handleContentView,
}) => {
  return (
    <div>
      <Grid container columnSpacing={"40px"}>
        <Grid item xs={1}>
          <Text type="mediumBold">{index + 1}</Text>
        </Grid>
        <Grid item xs={3.7}>
          <Text type="medium" sx={{ textTransform: "capitalize" }}>
            {data?.fundManagerName}
          </Text>
        </Grid>
        <Grid item xs={2.3}>
          <Text type="medium">
            {moment(data?.updatedOn).format("DD-MMM-YYYY")}
          </Text>
        </Grid>
        <Grid item xs={2}>
          {/* <Text type="medium">{data.upload_file}</Text> */}
          <Box
            component="img"
            sx={{
              height: 100,
              width: 100,
              maxHeight: { xs: 100, md: 50 },
              maxWidth: { xs: 100, md: 65 },
            }}
            src={data.imageUrl}
            alt={data.imageUrl}
          />
        </Grid>
        <Grid item xs={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <LoginBannerAction
              data={data}
              index={index}
              onDelete={handleContentDelete}
              onEdit={handleContentEdit}
              onView={handleContentView}
            />
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{ my: "21px" }} />
    </div>
  );
};
export default FundManagerContentItem;
