import { Box } from "@mui/material";
import Text from "components/Text";
import CreateNotification from "./CreateNotification";

const NotificationManagment = () => {
  return (
    <Box sx={{ ml: "20%", minHeight: "vh", py: "50px", width: "65%" }}>
      <Text type="headingBold">Create Notification</Text>

      <Box sx={{ mt: "20px" }}>
        <CreateNotification />
      </Box>
    </Box>
  );
};
export default NotificationManagment;
