import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { Button, Card, Divider, Grid, IconButton, Link } from "@mui/material";
import colors from "res/colors";
import Text from "components/Text";
import { Box } from "@mui/system";
import EventContentItem from "./EventContentItem";
import { deleteEvent, loadEvent } from "./store/slice";

const InfoGraphicsContents = () => {
  const dispatch = useAppDispatch();

  const {
    isCreatingEvent,
    isLoadingEvent,
    loadEventError,
    allEvent,
    deletedEvent,
  } = useAppSelector((state) => state.events);

  //   const digitalPostersResponse = digitalPostersData.map(function (val: any) {
  //     const response = {
  //       id: val.id,
  //       section: "Digital Poster",
  //       post_title: val.title,
  //       upload_date: "20 nov 2021",
  //       upload_file: val.assetUrl,
  //     };
  //     return response;
  //   });
  const { didUpdateEvent } = useAppSelector((state) => state.events);

  useEffect(() => {
    dispatch(
      loadEvent({
        pageNumber: 0,
        size: 100,
      })
    );
  }, [didUpdateEvent]);

  const [events, setEvents] = useState([]);
  useEffect(() => {
    if (allEvent && allEvent?.length > 0) {
      setEvents(allEvent);
    }
  }, [allEvent]);
  useEffect(() => {
    if (deletedEvent) {
      const data = events?.filter(
        (ele: any) => ele?.uniqueReference !== deletedEvent
      );
      setEvents(data);
    }
  }, [deletedEvent]);

  const handleContentDelete = (rowData: any, index: number) => {
    const deleteData = {
      uniqueReference: rowData?.uniqueReference,
    };
    dispatch(deleteEvent(deleteData));
  };
  const handleContentEdit = (rowData: any) => {};
  const handleContentView = (rowData: any) => {};

  return (
    <div>
      <Box>
        <Box
          sx={{
            pt: "48px",
            pb: "16px",
          }}
        >
          <Text type="subHeadingBold">Updated Content</Text>
        </Box>

        <Card
          sx={{
            flexGrow: 1,
            borderRadius: "16px",
            pt: "42px",
            pb: "20px",
            px: "32px",
          }}
        >
          <Grid container columnSpacing={"40px"}>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Section"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Event Title"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{" Created Date"}</Text>
            </Grid>
            <Grid item xs={2}>
              <Text type="smallTransparent">{"Speaker"}</Text>
            </Grid>
            <Grid item xs={3}>
              <Text type="smallTransparent">{"Action"}</Text>
            </Grid>
          </Grid>
          <Box sx={{ mt: "16px", mb: "20px" }}>
            <Divider />
          </Box>
          {events?.map((rowData: any, index: number) => {
            return (
              <EventContentItem
                key={rowData.id}
                data={rowData}
                index={index}
                handleContentDelete={handleContentDelete}
                handleContentEdit={handleContentEdit}
                handleContentView={handleContentView}
              />
            );
          })}
          {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: colors.lightButtonBlue,
                color: colors.backGroundBlue,
              }}
            >
              See More
            </Button>
          </Box> */}
        </Card>
      </Box>
    </div>
  );
};

export default InfoGraphicsContents;
