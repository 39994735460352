import { Box } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import AssignPopup from "./AssignPopup/AssignPopup";
import AssignRmSuccessPopup from "./AssignPopup/AssignRmSuccessPopup";
import DisplayRm from "./DisplayRm";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import ActivityOverlay from "components/ActivityOverlay";

import { loadLeads, mapLead, resetMapLeadStates } from "../store/slice";
import { getCount } from "pages/SalesRMManagement/store/slice";

const AssignLead = () => {
  const dispatch = useAppDispatch();
  const { isLoadingLeads, leads, isMappingLead, didMapLead, mapLeadError } =
    useAppSelector((state) => state.leadManagement);

  useEffect(() => {
    dispatch(
      loadLeads({
        pageNumber: 0,
        size: 1000,
      })
    );
    dispatch(getCount());
  }, []);

  useEffect(() => {
    if (didMapLead) {
      dispatch(resetMapLeadStates());
      dispatch(
        loadLeads({
          pageNumber: 0,
          size: 1000,
        })
      );
      dispatch(getCount());
    }
  }, [didMapLead]);

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const leadEmail = useRef("");
  const leadIdref = useRef(null);
  const handleClickAssignRm = (email: any, leadId: any) => {
    setOpen(true);
    leadEmail.current = email;
    leadIdref.current = leadId;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAssign = (newValue?: string) => {
    setOpen(false);
    const params = {
      leadEmailId: leadEmail.current,
      salesRmEmailId: newValue,
      leadId: leadIdref.current,
    };

    dispatch(mapLead(params));
  };

  return (
    <div>
      <Box>
        <DisplayRm
          rmList={leads.filter((lead: any) => {
            return lead.leadStatus === "TO_BE_ASSIGNED_SALES_RM";
          })}
          onClick={handleClickAssignRm}
        />
      </Box>
      <AssignPopup
        id="assign_salesRm"
        keepMounted
        open={open}
        value={value}
        onClose={handleClose}
        onAssign={handleAssign}
      />
      {isMappingLead && <ActivityOverlay />}
      {isLoadingLeads && <ActivityOverlay />}
    </div>
  );
};

export default AssignLead;
