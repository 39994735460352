import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import AutorenewOutlinedIcon from "@mui/icons-material/Autorenew";
import { makeStyles } from "@mui/styles";
import colors from "res/colors";
import Logo from "res/images/Invesco_MF_Logo_Blue_Pos_RGB@2x.png";
import Text from "components/Text";
import { Box } from "@mui/material";
const NavBar = () => {
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: colors.white,
        pl: 0,
        maxHeight: "75px",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar className={classes.toolBarContainer}>
        <div className={classes.imageContainer}>
          <img
            className={classes.imageStyle}
            src={Logo}
            height={"24px"}
            width={"293px"}
            alt="Invesco Logo"
          />
        </div>
        {/* <Box>
          <Text color={colors.black} type="smallTransparent">
            Results as on 23 July 2021 at 2.45 pm
            <IconButton>
              <AutorenewOutlinedIcon color="primary" />
            </IconButton>
          </Text>
        </Box> */}
      </Toolbar>
    </AppBar>
  );
};
const useStyles = makeStyles({
  appbar: {
    minHeight: "75px",
    justifyContent: "center",
    borderBottom: "1px solid",
    borderBottomColor: colors.lightGray,
    // marginBottom: '1rem',
  },
  toolBarContainer: {
    paddingLeft: "20px",
    backgroundColor: "transparent",
    marginTop: 0,
  },
  imageContainer: {
    flexGrow: 1,
  },
  imageStyle: {
    paddingLeft: "16px",
    paddingTop: "30px",
    paddingBottom: "30px",
  },
});
export default NavBar;
