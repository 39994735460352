import * as yup from "yup";
export const notificationValidationSchema = yup.object().shape({
  title: yup.string().min(3).max(1000).required("Please enter a valid title"),
  description: yup
    .string()
    .max(
      1000,
      "The number of characters exceeded the specified limit (Max. 1000)."
    ),
  // .required("Please enter a valid description"),
  shortDescription: yup
    .string()
    .max(1000)
    .required("Please enter a valid short description"),
  notificationType: yup
    .string()
    .required("Please select a valid notification type"),
  createdBy: yup.string().min(3).max(100),
  // imageUrl: yup.string().optional(),
  relatedLink: yup.string().optional(),

  // description: yup
  //   .string()
  //   .min(15)
  //   .required("Please enter a valid description"),
  //   thumbnail_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
  //   detail_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
  //   material_file: yup.object().shape({
  //     imageData: yup.object().required(),
  //     name: yup.string().optional(),
  //   }),
});
