import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";
import ToastCenter from "utils/ToastCenter";
import { TTrainingMaterialsParams } from "./types";
import {
  loadTrainingMaterials,
  didTrainingMaterials,
  didFailToLoadTrainingMaterials,
  deleteTrainingMaterials,
  didDeleteTrainingMaterials,
  didFailedToDeleteTrainingMaterials,
} from "./slice";

const TRAINING_MATERIAL = "v1/admin/trainingmaterials/fetchAll";
const TRAINING_MATERIAL_DELETE = "v1/admin/content/delete";

function* handleTrainingMaterials(action: PayloadAction<any>) {
  const response: TResponse = yield call(
    Request.POST,
    TRAINING_MATERIAL,
    action.payload
  );

  if (response.error) {
    yield put(didFailToLoadTrainingMaterials(response.error));
  } else {
    yield put(didTrainingMaterials(response.data.data.resultList));
  }
}

function* handleDeleteTrainingMaterials(
  action: PayloadAction<TTrainingMaterialsParams>
) {
  const response: TResponse = yield call(
    Request.POST,
    TRAINING_MATERIAL_DELETE,
    action.payload
  );

  if (response.error) {
    ToastCenter.error("Unable to delete content");
    yield put(didFailedToDeleteTrainingMaterials(response?.error));
  } else {
    ToastCenter.success("Successfully deleted content");
    yield put(didDeleteTrainingMaterials(action?.payload?.id));
  }
}

function* watcherTrainingMaterials() {
  yield takeLatest(loadTrainingMaterials.type, handleTrainingMaterials);
}

function* watcherDeleteTrainingMaterials() {
  yield takeLatest(deleteTrainingMaterials.type, handleDeleteTrainingMaterials);
}
export function* trainingMaterialSaga() {
  yield all([watcherTrainingMaterials(), watcherDeleteTrainingMaterials()]);
}
