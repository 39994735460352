import { Box, Button, Divider, Grid } from "@mui/material";
import Input from "components/FormInput";
import ImageDropzone from "components/ImageDropZone";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import React, { useState, useRef, useEffect } from "react";
import CheckBox from "components/CheckBox";
import colors from "res/colors";
import DateInput from "components/DateInput";
import TimeInput from "components/TimeInput";
import Text from "components/Text";
import { EventForm } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "./validationSchema";
import moment from "moment";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import {
  createEvent,
  resetCreateEventState,
  resetUpdateEventState,
} from "./store/slice";
import ToastCenter from "utils/ToastCenter";
import ToggleOption from "components/ToggleOption";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import ActivityOverlay from "components/ActivityOverlay";
import { updateEvent } from "./store/slice";
import FormInput from "components/FormInput";
import Editor from "components/Editor";
export interface WebinarsProps {
  eventData: any;
  onCancel: () => void;
}
const EditEventManagement: React.FC<WebinarsProps> = ({
  eventData,
  onCancel,
}) => {
  const dispatch = useAppDispatch();
  const { isUpdatingEvent, didUpdateEvent, updateEventError } = useAppSelector(
    (state) => state.events
  );
  const [checked, setChecked] = useState(false);

  const [eventImageError, setEventImageError] = useState("");
  const [speakerImageError, setSpeakerImageError] = useState("");
  const [bannerImageError, setBannerImageError] = useState("");
  const [userType, setUserType] = useState(eventData?.userType);
  const [isShowNotification, setIsShowNotification] = useState(false);
  const eventImageDropzoneRef = useRef<any>(null);
  const speakerImageDropzoneRef = useRef<any>(null);
  const bannerImageDropzoneRef = useRef<any>(null);

  const formMethods = useForm<EventForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("eventImage", "");
    formMethods.setValue("speakerImage", "");
    formMethods.setValue("banner_image", "");
    formMethods.setValue(
      "startTime",
      moment(eventData?.eventStartDateTime).format("HH:mm:ss")
    );
    formMethods.setValue(
      "endTime",
      moment(eventData?.eventEndDateTime).format("HH:mm:ss")
    );
    formMethods.setValue("eventStartTime", eventData?.eventStartDateTime);
    formMethods.setValue("eventEndTime", eventData?.eventEndDateTime);
    formMethods.setValue("eventDate", eventData?.eventStartDateTime);

    formMethods.setValue("eventName", eventData?.eventName);
    formMethods.setValue("eventBy", eventData?.eventBy);
    formMethods.setValue("organizerEmail", eventData?.organizerEmail);
    formMethods.setValue("designation", eventData?.designation);
    formMethods.setValue("about", eventData?.about);
    formMethods.setValue("learners", eventData?.learners);
    formMethods.setValue("courses", eventData?.courses);
    formMethods.setValue("education", eventData?.education);
    formMethods.setValue("experience", eventData?.experience);
    formMethods.setValue("whatsInIt", eventData?.whatsInIt);
    formMethods.setValue("totalSeats", eventData?.totalSeats);
    formMethods.setValue("eventLink", eventData?.eventLink);
    setUserType(eventData?.userType);
    setChecked(eventData?.topBanner);
    if (didUpdateEvent) {
      ToastCenter.success("Successfully created event");
      eventImageDropzoneRef.current.clearImage();
      speakerImageDropzoneRef.current.clearImage();
      formMethods.setValue("eventImage", null);
      formMethods.setValue("speakerImage", null);
      formMethods.setValue("banner_image", null);
      formMethods.setValue("startTime", "");
      formMethods.setValue("endTime", "");
      formMethods.setValue("eventDate", "");

      formMethods.setValue("eventName", "");
      formMethods.setValue("eventBy", "");
      formMethods.setValue("organizerEmail", "");
      formMethods.setValue("designation", "");
      formMethods.setValue("about", "");
      formMethods.setValue("learners", "");
      formMethods.setValue("courses", "");
      formMethods.setValue("education", "");
      formMethods.setValue("experience", "");
      formMethods.setValue("whatsInIt", "");
      formMethods.setValue("totalSeats", "");
      formMethods.setValue("eventLink", "");
      formMethods.setValue("isShowNotification", false);
      setIsShowNotification(false);
      setUserType("ALL_USER");
      dispatch(resetUpdateEventState());
      setChecked(false);
      onCancel();
    }
  }, [didUpdateEvent]);

  const handleNotificationCheckBoxChange = (checked: boolean) => {
    setIsShowNotification(checked);
    formMethods.setValue("isShowNotification", checked, {
      shouldValidate: true,
    });
  };

  const onSubmit = (data: EventForm) => {
    let showError = false;
    // if (!data.eventImage) {
    //   showError = true;
    //   setEventImageError("Please set event image");
    // }
    // if (!data.speakerImage) {
    //   showError = true;
    //   setSpeakerImageError("Please set speaker image");
    // }

    // if (showError) {
    //   return;
    // }

    const starttime = moment(data.eventStartTime).format("HH:mm:ss");
    const endtime = moment(data.eventEndTime).format("HH:mm:ss");
    const startDate = moment(data.eventDate).format("DD-MM-YYYY");

    let isostarttimeTimestamp = `${startDate} ${starttime}`;
    let isoendtimeTimestamp = `${startDate} ${endtime}`;

    const final_start_date = moment(
      isostarttimeTimestamp,
      "DD-MM-YYYY HH:mm:ss"
    ).format("DD-MM-YYYY HH:mm:ss");
    const final_end_date = moment(
      isoendtimeTimestamp,
      "DD-MM-YYYY HH:mm:ss"
    ).format("DD-MM-YYYY HH:mm:ss");

    const params = {
      event_image: data.eventImage,
      speaker_image: data.speakerImage,
      banner_image: data.banner_image,
      eventRequest: {
        uniqueReference: eventData?.uniqueReference,
        eventName: data.eventName,
        eventBy: data.eventBy,
        organizerEmail: data.organizerEmail,
        designation: data.designation,
        about: data.about,
        learners: data.learners,
        courses: data.courses,
        education: data.education,
        experience: data.experience,
        whatsInIt: data.whatsInIt,
        totalSeats: data.totalSeats,
        topBanner: checked,
        eventLink: data.eventLink,
        eventType: eventData?.eventType,
        eventStartDateTime: final_start_date,
        eventEndDateTime: final_end_date,
        userType: userType,
        enableNotification: isShowNotification,
        notificationTitle: data?.notificationTitle ?? "",
        notificationShortDescription: data?.notificationShortDescription ?? "",
        notificationDescription: data?.notificationDescription ?? "",
      },
    };

    dispatch(updateEvent(params));
  };

  const handleEventImageDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("eventImage", imageData);
    setEventImageError("");
  };
  const handleSpeakerImageDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("speakerImage", imageData);
    setSpeakerImageError("");
  };

  const handleBannerImageDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("banner_image", imageData);
    setBannerImageError("");
  };
  const handleCheckBoxChange = (checked: boolean) => {
    setChecked(checked);
  };

  const handleEventDateChange = (newValue: Date | null) => {
    formMethods.setValue("eventDate", newValue?.toISOString() || "", {
      shouldValidate: true,
    });
  };
  const handleEventStartTimeChange = (newValue: Date | null) => {
    formMethods.setValue("eventStartTime", newValue?.toISOString() || "", {
      shouldValidate: true,
    });
  };
  const handleEventEndTimeChange = (newValue: Date | null) => {
    formMethods.setValue("eventEndTime", newValue?.toISOString() || "", {
      shouldValidate: true,
    });
  };

  return (
    <Box sx={{}}>
      {/* <Box
        sx={{
          pb: "16px",
        }}
      >
        <Text type="mediumBold">Event Details</Text>
      </Box> */}
      <Text type="subHeadingBold"> Event For</Text>

      <Select
        options={[
          { title: "All", value: "ALL_USER" },
          { title: "MFD", value: "MFD" },
          { title: "MFD RM", value: "MFDRM" },
          { title: "SALES RM", value: "SALESRM" },
          { title: "RIA", value: "RIA" },
        ]}
        onOptionSelect={(option: SelectOption) => {
          setUserType(option.value);
        }}
        value={userType}
        sx={{ pb: "40px" }}
      />

      <Box sx={{ my: "40px" }}>
        <ImageDropzone
          onDropped={handleEventImageDrop}
          ref={eventImageDropzoneRef}
          heading=" Upload Event Image"
          icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
          type="Image"
          error={eventImageError}
          resolution={"780w X 450h"}
        />
      </Box>
      <FormProvider {...formMethods}>
        <Input
          variant="filled"
          name="eventName"
          label="Event Name"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="about"
          label="Event Details"
          InputProps={{
            disableUnderline: true,
          }}
          multiline
          rows={4}
        />

        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="whatsInIt"
          label="Whats it for you"
          InputProps={{
            disableUnderline: true,
          }}
          multiline
          rows={4}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <DateInput
            label="Event Date"
            error={formMethods.formState.errors.eventDate?.message}
            onChange={handleEventDateChange}
            preValue={new Date(eventData?.eventStartDateTime)}
          />
          <Box sx={{ mr: "24px" }}></Box>
          <TimeInput
            label="Start Time"
            error={formMethods.formState.errors.eventStartTime?.message}
            onChange={handleEventStartTimeChange}
            preValue={new Date(eventData?.eventStartDateTime)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pb: "40px" }}></Box>

        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {/* <DateInput /> */}
          <Box sx={{ width: "100%" }} />
          <Box sx={{ mr: "24px" }}></Box>
          <TimeInput
            label="End Time"
            error={formMethods.formState.errors.eventEndTime?.message}
            onChange={handleEventEndTimeChange}
            preValue={new Date(eventData?.eventEndDateTime)}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pb: "40px" }}></Box>

        <Input
          variant="filled"
          name="totalSeats"
          label="Total Seats"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", pb: "40px" }}></Box>

        <Input
          variant="filled"
          name="eventLink"
          label="Event URL"
          InputProps={{
            disableUnderline: true,
          }}
        />

        {/* <Box sx={{ display: "flex", flexDirection: "row", pb: "40px" }}></Box>

        <Input
          variant="filled"
          name="password"
          label="Password"
          InputProps={{
            disableUnderline: true,
          }}
        /> */}
        <Divider />

        <Box
          sx={{
            pb: "16px",
            mt: "40px",
          }}
        >
          <Text type="mediumBold">Speaker Details</Text>
        </Box>

        <Box sx={{ my: "40px" }}>
          <ImageDropzone
            onDropped={handleSpeakerImageDrop}
            ref={speakerImageDropzoneRef}
            heading=" Upload Speaker Image"
            icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
            type="Image"
            error={speakerImageError}
            resolution={"500w X 500h"}
          />
        </Box>

        {/* <Box sx={{ display: "flex", flexDirection: "row", pb: "40px" }}></Box> */}

        <Input
          variant="filled"
          name="eventBy"
          label="Speaker Name"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="experience"
          label="Speaker Details"
          InputProps={{
            disableUnderline: true,
          }}
          multiline
          rows={4}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="organizerEmail"
          label="Organizer Email"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="designation"
          label="Designation"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="learners"
          label="Learners"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="courses"
          label="Courses"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Input
          variant="filled"
          name="education"
          label="Education"
          InputProps={{
            disableUnderline: true,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", height: "30px" }} />

        <Box sx={{ my: "40px" }}>
          <Divider variant="fullWidth" />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CheckBox
              onClick={handleCheckBoxChange}
              type="TopBanner"
              checked={checked}
            />
          </Grid>
          <Grid item xs={6}>
            <CheckBox
              onClick={handleNotificationCheckBoxChange}
              type="ShowNotification"
              checked={isShowNotification}
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: "40px", mb: "24px" }}>
          <Divider variant="fullWidth" />
        </Box>
        {checked ? (
          <>
            <Box sx={{ my: "40px" }}>
              <ImageDropzone
                onDropped={handleBannerImageDrop}
                ref={bannerImageDropzoneRef}
                heading=" Upload Banner Image"
                icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
                type="Image"
                error={bannerImageError}
                resolution={"1032w X 450h"}
              />
            </Box>
            <Box sx={{ mt: "40px", mb: "24px" }}>
              <Divider variant="fullWidth" />
            </Box>
          </>
        ) : null}
        {isShowNotification ? (
          <>
            <Text type="mediumBold">Notification Details</Text>
            <FormProvider {...formMethods}>
              <FormInput
                variant="filled"
                name="notificationTitle"
                InputProps={{
                  disableUnderline: true,
                }}
                label="Add Title (max 1000 characters) *"
                sx={{ mt: "30px " }}
                inputProps={{ maxLength: 1000 }}
              />

              <FormInput
                variant="filled"
                name="notificationShortDescription"
                label="Short descriptions (Max. 1000 characters) *"
                sx={{ my: "30px " }}
                inputProps={{ maxLength: 1000 }}
              />
              <Editor
                placeholder="Descriptions (Max. 1000 characters)"
                onChange={(text) => {
                  formMethods.setValue("notificationDescription", text, {
                    shouldValidate: true,
                  });
                }}
                reset={false}
              />
              <Box sx={{ width: "100%", height: "20px" }} />
            </FormProvider>
            <Box sx={{ mt: "40px", mb: "24px" }}>
              <Divider variant="fullWidth" />
            </Box>
          </>
        ) : null}
        <Text type="error">{updateEventError}</Text>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={formMethods.handleSubmit(onSubmit)}
            variant="contained"
            sx={{
              fontSize: "18px",
              lineHeight: "24px",
              py: "16px",
              px: "48px",

              fontWeight: 500,
              backgroundColor: colors.backGroundBlue,
            }}
          >
            Submit
          </Button>
          <Button
            onClick={onCancel}
            variant="contained"
            sx={{
              fontSize: "18px",
              lineHeight: "24px",
              py: "16px",
              px: "48px",
              fontWeight: 500,
              backgroundColor: colors.backGroundBlue,
              ml: "100px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </FormProvider>
      {isUpdatingEvent && <ActivityOverlay />}
    </Box>
  );
};

export default EditEventManagement;
