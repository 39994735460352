import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "hooks/useAppSelector";

export function ControlledRoute({ children }: { children: JSX.Element }) {
  const { didLogin } = useAppSelector((state) => state.auth);
  let location = useLocation();

  if (didLogin) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return children;
}
