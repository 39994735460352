import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextareaAutosize,
} from "@mui/material";
import Text from "components/Text";
import { ChangeEvent, FC, useState, useEffect } from "react";
import colors from "res/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { assignedLeadListType } from "./types";
import { FormProvider, useForm } from "react-hook-form";
import { leadDataProps } from "pages/LeadManagement/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { leadDataValidationSchema } from "pages/LeadManagement/LeadDataValidationSchema";
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import moment from "moment";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { updateLead, deleteLead } from "../../../store/slice";

import ActivityOverlay from "components/ActivityOverlay";

export interface UserListItemProps {
  data: assignedLeadListType;
  onDelete: (data: assignedLeadListType) => void;
  onEdit: (data: assignedLeadListType) => void;
}
const AssignedLeadListItem: FC<UserListItemProps> = ({
  data,
  onDelete,
  onEdit,
}) => {
  const dispatch = useAppDispatch();
  const {
    isUpdatingLead,
    didUpdateLead,
    updateLeadError,
    isDeletingLead,
    didDeleteLead,
    didFailToDeleteLead,
  } = useAppSelector((state) => state.leadManagement);

  const [expanded, setExpanded] = useState(false);
  const [dialog, setDialog] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [mobile, setMobile] = useState("");

  const handleName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handleEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleLocation = (event: ChangeEvent<HTMLInputElement>) => {
    setLocation(event.target.value);
  };
  const handleAddress = (event: ChangeEvent<HTMLTextAreaElement>) => {
    formMethods.setValue("address", event.target.value);
    setAddress(event.target.value);
  };
  const handleMobile = (event: ChangeEvent<HTMLInputElement>) => {
    setMobile(event.target.value);
  };

  const getLeadStatus = (value: string) => {
    switch (value) {
      case "TO_BE_ASSIGNED":
        return "Ready to Assigned to MFD";
      case "TO_BE_ASSIGNED_SALES_RM":
        return "Ready to Assigned to RM";
      case "Not_Interested":
        return "Not Interested";
      case "Assigned":
        return "Assigned to MFD";
      case "Converted":
        return "Converted";
      case "Expired_NoResponse":
        return "Expired or NoResponse";

      default:
        return "NULL";
    }
  };
  const formMethods = useForm<leadDataProps>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(leadDataValidationSchema),
  });

  useEffect(() => {
    formMethods.setValue("address", data.address);
    formMethods.setValue("email", data.email);
    formMethods.setValue("location", data.location);
    formMethods.setValue("mobile", data.mobileNum);
    formMethods.setValue("name", data.name);
    if (didUpdateLead || didDeleteLead) {
      formMethods.setValue("address", data.address);
      formMethods.setValue("email", data.email);
      formMethods.setValue("location", data.location);
      formMethods.setValue("mobile", data.mobileNum);
      formMethods.setValue("name", data.name);
      handleClose();
      // if (didUpdateLead) {
      //   dispatch(resetUpdateLeadStates());
      //   ToastCenter.success("Successfully updated Lead");
      // }
      // if (didDeleteLead === true) {
      //   dispatch(resetDeleteLeadStates());
      //   ToastCenter.success("Successfully delete Lead");
      // }
      // dispatch(
      //   loadLeads({
      //     pageNumber: 0,
      //     size: 10,
      //   })
      // );
    }
  }, [didUpdateLead, didDeleteLead, data]);

  const onSubmit = (data: leadDataProps) => {
    const params = {
      name: data.name,
      email: data.email,
      mobileNumber: data.mobile,
      location: data.location,
      address: data.address,
      fundDescription: "fundDescription okay need in implement",
    };

    dispatch(updateLead(params));
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const renderExpandIcon = () => {
    if (expanded) return <ExpandLessIcon />;
    return <ExpandMoreIcon />;
  };
  const handleClose = () => {
    setDialog("");
  };

  const handleButtonClick = (type: string) => {
    setDialog(type);
  };

  const handleConfirmEdit = (data: assignedLeadListType) => {
    setDialog("");

    onEdit(data);
  };

  const handleConfirmDelete = (data: assignedLeadListType) => {
    setDialog("");
    // onDelete(data);
    dispatch(deleteLead({ email: data.email, leadId: data?.leadId }));
  };
  const renderDialog = () => {
    if (dialog === "delete") {
      return (
        <Dialog open={dialog === "delete"}>
          <DialogTitle>Confirm Delete?</DialogTitle>
          <DialogContent>
            This action will permanently delete the data.
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={() => handleConfirmDelete(data)} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else if (dialog === "edit") {
      return (
        <Dialog open={dialog === "edit"}>
          <DialogTitle>Edit Lead Data</DialogTitle>
          <DialogContent>
            {updateLeadError}
            <FormProvider {...formMethods}>
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <FormInput
                  name="name"
                  variant="filled"
                  label="Name"
                  sx={{ mr: "16px" }}
                  onChange={handleName}
                  InputProps={{
                    disableUnderline: true,
                  }}
                  // defaultValue={}
                />
                <FormInput
                  name="email"
                  variant="filled"
                  label="Email"
                  onChange={handleEmail}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", flexDirection: "row", mt: "24px" }}>
                <FormInput
                  name="mobile"
                  variant="filled"
                  label="Mobile"
                  sx={{ mr: "16px" }}
                  onChange={handleMobile}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
                <FormInput
                  name="location"
                  variant="filled"
                  label="Location"
                  onChange={handleLocation}
                  InputProps={{
                    disableUnderline: true,
                  }}
                />
              </Box>
              <Box sx={{ mt: "24px", mr: "8px" }}>
                <TextareaAutosize
                  placeholder="Address"
                  name="address"
                  minRows={3}
                  style={{ width: "100%" }}
                  onChange={handleAddress}
                  defaultValue={formMethods.getValues("address")}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  mt: "24px",
                }}
              >
                <FormSelect
                  name="fundInput"
                  options={[{ title: "1", value: "1" }]}
                  placeHolder="Funds Input"
                />
                <Box sx={{ mr: "16px" }}></Box>
                <FormSelect
                  name="schemeType"
                  options={[{ title: "1", value: "1" }]}
                  placeHolder="Scheme Type"
                />
              </Box>
            </FormProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={formMethods.handleSubmit(onSubmit)} autoFocus>
              Update
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };
  const renderExpandedView = () => {
    if (expanded) {
      return (
        <div>
          <Grid>
            <Grid container direction="row" sx={{ mt: "16px" }}>
              <Grid xs={4.5}>
                <Text type="listMediumBold">Email Id</Text>
                <Text type="listMedium">{data.email || "NULL"}</Text>
              </Grid>
              <Grid xs={2.5}>
                <Text type="listMediumBold">Mobile</Text>
                <Text type="listMedium">{data.mobileNum || "NULL"}</Text>
              </Grid>
              <Grid xs={3.5}>
                <Text type="listMediumBold">Location</Text>
                <Text type="listMedium">{data.location || "NULL"}</Text>
              </Grid>
            </Grid>
            <Grid container direction="row" sx={{ mt: "16px" }}>
              <Grid xs={4.5}>
                <Text type="listMediumBold">Address</Text>
                <Text type="listMedium">{data.address || "NULL"}</Text>
              </Grid>
              <Grid xs={3}>
                <Text type="listMediumBold">Upload Date</Text>
                <Text type="listMedium">
                  {data?.uploadDate
                    ? moment(data?.uploadDate).format("DD-MMM-YYYY")
                    : "--"}
                </Text>
              </Grid>
              <Grid xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                  }}
                >
                  {/* <Button
                    variant="outlined"
                    onClick={() => handleButtonClick("edit")}
                    sx={{
                      mr: "16px",
                      px: "16px",
                      py: "10px",
                      fontSize: "15px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: 0,
                      backgroundColor: colors.white,
                      color: colors.black,
                      borderColor: colors.black,
                    }}
                  >
                    Edit
                  </Button> */}

                  <Button
                    variant="outlined"
                    onClick={() => handleButtonClick("delete")}
                    sx={{
                      px: "16px",
                      py: "10px",
                      fontSize: "15px",
                      fontWeight: 500,
                      lineHeight: "20px",
                      letterSpacing: 0,
                      backgroundColor: colors.white,
                      color: colors.black,
                      borderColor: colors.black,
                    }}
                  >
                    Delete
                  </Button>
                  {renderDialog()}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  };

  return (
    <Box>
      <Card sx={{ px: "10px", my: "16px", pt: "20px", pb: "10px" }}>
        <Grid>
          <Grid container direction="row">
            <Grid xs={4.5}>
              <Text type="listMediumBold">{data.name}</Text>
            </Grid>
            <Grid xs={2.5}>
              <Text type="listMedium">{data.salesRmName || ""}</Text>
            </Grid>
            <Grid xs={2.5}>
              <Text type="listMedium">
                {data.assignedOnDate
                  ? moment(data.assignedOnDate).format("DD-MMM-YYYY")
                  : ""}
              </Text>
            </Grid>
            <Grid xs={1.5}>
              <Text type="listMedium">
                {getLeadStatus(data.leadStatus) || ""}
              </Text>
            </Grid>
            <Grid xs={0.5}>
              <Box
                sx={{
                  mt: "-10px",
                  display: "flex",
                  alignItems: "flex-start",
                  alignContent: "flex-start",
                }}
              >
                <IconButton onClick={handleExpand}>
                  {renderExpandIcon()}
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {renderExpandedView()}
      </Card>
      {didFailToDeleteLead}
      {isUpdatingLead && <ActivityOverlay />}
      {isDeletingLead && <ActivityOverlay />}
    </Box>
  );
};

export default AssignedLeadListItem;
