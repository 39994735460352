/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { Box, Button, Card } from "@mui/material";
import Input from "components/Input";
import ToggleOption from "components/ToggleOption";
import ImageDropzone from "components/ImageDropZone";
import { useState, useRef } from "react";
import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";
import Text from "components/Text";
import FormSelect from "components/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TNotificationForm } from "../types";
import { notificationValidationSchema } from "../validationSchema";
import FormInput from "components/FormInput";
import { useAppSelector } from "hooks/useAppSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import {
  sentNotification,
  resetNotificationStates,
  loadPresentation,
  loadOnePager,
  loadMarketInsights,
  loadFactsheet,
} from "../store/slice";
import ActivityOverlay from "components/ActivityOverlay";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import Editor from "components/Editor";

const CreateNotification = () => {
  const dispatch = useAppDispatch();
  const {
    isSendingNotification,
    notificationError,
    didSentNotification,
    isLoadingPresentation,
    presentationsList,
    presentationError,
    isLoadingOnePager,
    onePagerList,
    onePagerError,
    isLoadingMarketInsights,
    marketInsightsList,
    marketInsightsError,
    isLoadingFactsheet,
    factsheetList,
    factsheetError,
  } = useAppSelector((state) => state.notification);

  const [activeButton, setActiveButton] = useState("investor");
  const [presentationType, setPresentationType] = useState("");
  const [presentationTypeError, setPresentationTypeError] = useState("");
  const [presentationListError, setPresentationListError] = useState("");
  const [onePagerType, setOnePagerType] = useState("");
  const [onePagerTypeError, setOnePagerTypeError] = useState("");
  const [onePagerListError, setOnePagerListError] = useState("");
  const [factsheetType, setFactsheetType] = useState("");
  const [factsheetTypeError, setFactsheetTypeError] = useState("");
  const [factsheetListError, setFactsheetListError] = useState("");
  const [marketInsightsListError, setMarketInsightsListError] = useState("");
  const [userType, setUserType] = useState("ALL_USER");
  const [yearList, setYearList] = useState<Array<any>>([]);
  const [year, setYear] = useState(new Date().getFullYear() + "");
  const imageDropzoneRef = useRef<any>(null);

  const notificationForOptions = [
    { label: "investor" },
    { label: "distributor" },
  ];

  const formMethods = useForm<TNotificationForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(notificationValidationSchema),
  });

  const marketInsightsValue = useMemo(() => {
    if (marketInsightsList?.length < 1) {
      return [];
    }
    return marketInsightsList?.map((item: any) => ({
      title: item?.title,
      value: item,
    }));
  }, [marketInsightsList]);
  const presentationValue = useMemo(() => {
    if (presentationsList?.length < 1) {
      return [];
    }
    return presentationsList?.map((item: any) => ({
      title: item?.presentationName,
      value: item,
    }));
  }, [presentationsList]);
  const onePagerValue = useMemo(() => {
    if (onePagerList?.length < 1) {
      return [];
    }
    return onePagerList?.map((item: any) => ({
      title: item?.onePagerName,
      value: item,
    }));
  }, [onePagerList]);
  const factsheetValue = useMemo(() => {
    if (factsheetList?.length < 1) {
      return [];
    }
    return factsheetList?.map((item: any) => ({
      title: item?.factSheetName,
      value: item,
    }));
  }, [factsheetList]);

  useEffect(() => {
    if (didSentNotification) {
      imageDropzoneRef.current.clearImage();
      formMethods.setValue("title", "");
      formMethods.setValue("image", null);
      formMethods.setValue("description", "");
      formMethods.setValue("shortDescription", "");
      // formMethods.setValue("createdBy", "Invesco");
      // formMethods.setValue("imageUrl", "");
      formMethods.setValue("relatedLink", "");
      formMethods.setValue("notificationType", "");
      setUserType("ALL_USER");
      setPresentationType("");
      setOnePagerType("");
      setFactsheetType("");
      formMethods.setValue("presentationType", "");
      formMethods.setValue("onePagerType", "");
      formMethods.setValue("factsheetType", "");
      formMethods.setValue("presentation", "");
      formMethods.setValue("onePager", "");
      formMethods.setValue("marketInsights", "");
      formMethods.setValue("factsheet", "");
      dispatch(resetNotificationStates());
    }
  }, [didSentNotification]);
  useEffect(() => {
    formMethods.setValue("createdBy", "Invesco");
  }, []);

  const handleActiveNotificationFor = (activeNotification: string) => {
    setActiveButton(activeNotification);
  };
  const handleNotificationTypeChange = (option: SelectOption) => {
    setPresentationType("");
    setOnePagerType("");
    setFactsheetType("");
    formMethods.setValue("presentationType", "");
    formMethods.setValue("onePagerType", "");
    formMethods.setValue("factsheetType", "");
    formMethods.setValue("onePager", "");
    formMethods.setValue("presentation", "");
    formMethods.setValue("marketInsights", "");
    formMethods.setValue("factsheet", "");
    if (option.value === "MARKET_INSIGHTS") {
      dispatch(loadMarketInsights({ pageNo: 1, size: 1000 }));
    }
  };
  const handlePresentationChange = (option: SelectOption) => {
    setPresentationListError("");
  };
  const handleOnepagerChange = (option: SelectOption) => {
    setOnePagerListError("");
  };
  const handleFactsheetChange = (option: SelectOption) => {
    setFactsheetListError("");
  };
  const handleMarketInsightsChange = (option: SelectOption) => {
    setMarketInsightsListError("");
  };
  const handletypeChange = (option: SelectOption) => {
    if (formMethods.getValues("notificationType") === "PRESENTATION") {
      setPresentationTypeError("");
      setPresentationType(option.value);
      if (option.value) {
        dispatch(
          loadPresentation({
            category: option.value,
            pageNo: 1,
            size: 20,
          })
        );
      }
    } else if (formMethods.getValues("notificationType") === "ONEPAGER") {
      setOnePagerType(option.value);
      setOnePagerTypeError("");
      if (option.value) {
        dispatch(
          loadOnePager({
            category: option.value,
            pageNo: 1,
            size: 20,
          })
        );
      }
    } else if (formMethods.getValues("notificationType") === "FACT_SHEET") {
      setFactsheetType(option.value);
      setFactsheetTypeError("");
      if (option.value) {
        dispatch(
          loadFactsheet({
            year: option.value,
            byYear: true,
          })
        );
      }
    }
  };

  const handleSubmit = (data: TNotificationForm) => {
    setPresentationTypeError("");
    setPresentationListError("");
    setOnePagerTypeError("");
    setOnePagerListError("");
    setMarketInsightsListError("");
    if (formMethods.getValues("notificationType") === "PRESENTATION") {
      if (!presentationType) {
        setPresentationTypeError("Please select a valid presentation type.");
        return;
      } else if (!formMethods.getValues("presentation")) {
        setPresentationListError("Please select a valid presentation.");
        return;
      }
    } else if (formMethods.getValues("notificationType") === "ONEPAGER") {
      if (!onePagerType) {
        setOnePagerTypeError("Please select a valid onepager type.");
        return;
      } else if (!formMethods.getValues("onePager")) {
        setOnePagerListError("Please select a valid onepager.");
        return;
      }
    } else if (formMethods.getValues("notificationType") === "FACT_SHEET") {
      if (!factsheetType) {
        setFactsheetTypeError("Please select a valid factsheet year.");
        return;
      } else if (!formMethods.getValues("factsheet")) {
        setFactsheetListError("Please select a valid factsheet.");
        return;
      }
    } else if (
      formMethods.getValues("notificationType") === "MARKET_INSIGHTS"
    ) {
      if (!formMethods.getValues("marketInsights")) {
        setMarketInsightsListError("Please select a valid market insights.");
        return;
      }
    }
    const params = {
      image: data.image,
      notificationRequestString: {
        title: data.title,
        notificationType: data.notificationType,
        description: data.description,
        createdBy: data.createdBy,
        shortDescription: data.shortDescription,
        userType: userType,
        eventUniqueReference:
          data?.marketInsights?.uniqueReference ||
          data?.onePager?.uniqueReference ||
          data?.presentation?.uniqueReference ||
          data?.factsheet?.factSheetName ||
          null,
      },
    };
    dispatch(sentNotification(params));
  };

  const getYears = (yearValue: string) => {
    const currentYear = new Date(yearValue).getFullYear();
    const totalYears = 10;
    const _years = Array.from(
      { length: totalYears },
      (v, i) => currentYear - totalYears + i + 1
    ).reverse();
    return _years.map((item) => ({ title: item + "", value: item + "" }));
  };

  useEffect(() => {
    setYearList(getYears(year));
  }, []);
  const handleImageDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("image", imageData);
  };

  return (
    <Box>
      <Card
        sx={{
          flexGrow: 1,
          px: "40px",
          py: "40px",
          boxShadow: "0 2px 14px 5px rgba(237,237,237,0.5)",
        }}
      >
        {/* <Text type="mediumBold">Create Notification for</Text> */}
        {/* <Box sx={{ display: "flex", flexDirection: "row", mt: "16px" }}>
          <ToggleOption
            options={notificationForOptions}
            onClick={handleActiveNotificationFor}
            selected={activeButton}
          />
        </Box> */}
        <Text type="subHeadingBold"> Notification For</Text>

        <Select
          options={[
            { title: "All", value: "ALL_USER" },
            { title: "MFD", value: "MFD" },
            { title: "MFD RM", value: "MFDRM" },
            { title: "SALES RM", value: "SALESRM" },
            { title: "RIA", value: "RIA" },
          ]}
          onOptionSelect={(option: SelectOption) => {
            setUserType(option.value);
          }}
          value={userType}
          sx={{ pb: "40px" }}
        />
        <FormProvider {...formMethods}>
          <FormInput
            variant="filled"
            name="title"
            label="Title(max 1000 characters)"
            sx={{ mt: "30px " }}
            inputProps={{ maxLength: 1000 }}
            required
          />
          <FormInput
            variant="filled"
            name="shortDescription"
            label="Short descriptions (Max. 1000 characters)"
            sx={{ mt: "24px " }}
            inputProps={{ maxLength: 1000 }}
            required
          />
          <Box sx={{ width: "100%", height: "50px" }} />
          <Editor
            placeholder="Descriptions (Max. 1000 characters)"
            onChange={(text) => {
              formMethods.setValue("description", text, {
                shouldValidate: true,
              });
            }}
            reset={didSentNotification}
          />
          {formMethods.formState.errors.description?.message ? (
            <Text type="error">
              {formMethods.formState.errors.description?.message || ""}
            </Text>
          ) : null}
          {/* <FormInput
            variant="filled"
            name="userId"
            label="User Id"
            sx={{ mt: "24px " }}
          /> */}
          <Box sx={{ width: "100%", height: "50px" }} />
          <FormSelect
            label="Select Type"
            labelId="notificationType"
            id="notificationType"
            name="notificationType"
            options={[
              {
                title: "Webinar",
                value: "WEBINARS",
              },
              {
                title: "Market Information",
                value: "MARKET_INFORMATION",
              },
              {
                title: "Product",
                value: "PRODUCT_EVENTS",
              },
              {
                title: "Business",
                value: "BUSINESS",
              },

              {
                title: "Brokerage",
                value: "BROKERAGE",
              },
              {
                title: "Contents",
                value: "CONTENTS",
              },
              {
                title: "NFO",
                value: "NFO_CONSTANT",
              },
              {
                title: "Self Declaration",
                value: "SELF_DECLARATION",
              },
              {
                title: "Presentations",
                value: "PRESENTATION",
              },
              {
                title: "One Pager",
                value: "ONEPAGER",
              },
              {
                title: "Market Insights",
                value: "MARKET_INSIGHTS",
              },
              {
                title: "Factsheet",
                value: "FACT_SHEET",
              },
            ]}
            onOptionSelect={handleNotificationTypeChange}
            required
          />
          {formMethods.getValues("notificationType") === "PRESENTATION" ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select Presentation Type"
                labelId="presentationType"
                id="presentationType"
                name="presentationType"
                onOptionSelect={handletypeChange}
                options={[
                  {
                    title: "Equity",
                    value: "equity",
                  },
                  {
                    title: "Fixed Income",
                    value: "Fixed Income",
                  },
                  {
                    title: "Other",
                    value: "Others",
                  },
                ]}
                required
                errorMessage={presentationTypeError}
              />
            </>
          ) : null}
          {formMethods.getValues("notificationType") === "ONEPAGER" ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select OnePager Type"
                labelId="onePagerType"
                id="onePagerType"
                name="onePagerType"
                onOptionSelect={handletypeChange}
                options={[
                  {
                    title: "Equity",
                    value: "equity",
                  },
                  {
                    title: "Fixed Income",
                    value: "Fixed Income",
                  },
                  {
                    title: "Other",
                    value: "Others",
                  },
                ]}
                required
                errorMessage={onePagerTypeError}
              />
            </>
          ) : null}
          {formMethods.getValues("notificationType") === "FACT_SHEET" ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select Factsheet Year"
                labelId="factsheetType"
                id="factsheetType"
                name="factsheetType"
                onOptionSelect={handletypeChange}
                options={yearList}
                required
                errorMessage={factsheetTypeError}
              />
            </>
          ) : null}
          {presentationType ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select Presentation"
                labelId="presentation"
                id="presentation"
                name="presentation"
                onOptionSelect={handlePresentationChange}
                options={presentationValue}
                required
                errorMessage={presentationListError}
              />
            </>
          ) : null}
          {onePagerType ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select OnePager"
                labelId="onePager"
                id="onePager"
                name="onePager"
                onOptionSelect={handleOnepagerChange}
                options={onePagerValue}
                required
                errorMessage={onePagerListError}
              />
            </>
          ) : null}
          {factsheetType ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select Factsheet"
                labelId="factsheet"
                id="factsheet"
                name="factsheet"
                onOptionSelect={handleFactsheetChange}
                options={factsheetValue}
                required
                errorMessage={factsheetListError}
              />
            </>
          ) : null}
          {formMethods.getValues("notificationType") === "MARKET_INSIGHTS" ? (
            <>
              <Box sx={{ width: "100%", height: "30px" }} />
              <FormSelect
                label="Select Market Insights"
                labelId="marketInsights"
                id="marketInsights"
                name="marketInsights"
                onOptionSelect={handleMarketInsightsChange}
                options={marketInsightsValue}
                required
                key={null}
                errorMessage={marketInsightsListError}
              />
            </>
          ) : null}
          <Box sx={{ width: "100%", height: "50px" }} />
          <ImageDropzone
            onDropped={handleImageDrop}
            ref={imageDropzoneRef}
            heading="Upload Image file here (Optional)"
            icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
            type="Image"
          />
          <Box sx={{ width: "100%", height: "50px" }} />
          <FormInput
            variant="filled"
            name="createdBy"
            label="createdBy"
            sx={{ mt: "24px " }}
          />
          {/* <FormInput
            variant="filled"
            name="imageUrl"
            label="ImageUrl"
            sx={{ mt: "24px " }}
          />
          <FormInput
            variant="filled"
            name="relatedLink"
            label="relatedLink"
            sx={{ mt: "24px " }}
          /> */}

          {/* <Input
            variant="filled"
            label="Link"
            onChange={handleLink}
            sx={{ mt: "24px " }}
          /> */}
        </FormProvider>
        <Text type="error">{notificationError}</Text>
        <Button
          sx={{
            fontFamily: "Graphik",
            fontSize: "15px",
            lineHeight: "20px",
            py: "8px",
            px: "28px",
            mt: "40px",
            backgroundColor: colors.backGroundBlue,
          }}
          variant="contained"
          onClick={formMethods.handleSubmit(handleSubmit)}
        >
          Sent
        </Button>
      </Card>
      {(isSendingNotification ||
        isLoadingPresentation ||
        isLoadingOnePager ||
        isLoadingMarketInsights ||
        isLoadingFactsheet) && <ActivityOverlay />}
    </Box>
  );
};

export default CreateNotification;

const t = {
  title: "Title",
  notificationType: "FACT_SHEET",
  description: "<p>Description</p>",
  createdBy: "Invesco",
  shortDescription: "Description",
  userType: "ALL_USER",
  eventUniqueReference: null,
};
