import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TInfographicsParams, TInfographicDetailsParams } from "./types";

type InfographicsState = {
  isLodingInfographics: boolean;
  infographicsError: string;
  infographicsContentData: Array<any>;

  isDeleteInfographics: boolean;
  infographicsDeleteError: string;
  infographicsDeleted: string;
};

const INITIAL_STATE: InfographicsState = {
  isLodingInfographics: false,
  infographicsError: "",
  infographicsContentData: [],

  isDeleteInfographics: false,
  infographicsDeleteError: "",
  infographicsDeleted: "",
};

const infographics = createSlice({
  name: "infographics",
  initialState: INITIAL_STATE,
  reducers: {
    loadInfographics: (state, _action: PayloadAction<TInfographicsParams>) => {
      state.isLodingInfographics = true;
      state.infographicsError = "";
    },
    didLoadInfographics: (state, action: PayloadAction<any>) => {
      state.isLodingInfographics = false;
      state.infographicsError = "";
      state.infographicsContentData = action.payload;
    },
    didFailToLoadInfographics: (state, action: PayloadAction<any>) => {
      state.isLodingInfographics = false;
      state.infographicsError = action.payload;
    },

    deleteInfoGraphics: (state, action: PayloadAction<any>) => {
      state.isDeleteInfographics = true;
      state.infographicsDeleteError = "";
      state.infographicsDeleted = "";
    },
    didDeleteInfographics: (state, action: PayloadAction<any>) => {
      state.isDeleteInfographics = false;
      state.infographicsDeleteError = "";
      state.infographicsDeleted = action.payload;
    },
    didFailedToDeleteInfographics: (state, action: PayloadAction<any>) => {
      state.isDeleteInfographics = false;
      state.infographicsDeleteError = action.payload;
    },
  },
});

export const {
  loadInfographics,
  didLoadInfographics,
  didFailToLoadInfographics,
  deleteInfoGraphics,
  didDeleteInfographics,
  didFailedToDeleteInfographics,
} = infographics.actions;
export default infographics.reducer;
