import React, { useReducer, useEffect } from "react";
import RouteConfig from "./RouteConfig";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from "pages/Login";
import DashBoard from "pages/DashBoard/DashBoard";
import NavBar from "components/NavBar";
import { ControlledRoute } from "./ControlledRoute";
import { PrivateRoute } from "./PrivateRoute";

const AppRoute = () => {
  return (
    <div style={styles.container}>
      <NavBar />
      <BrowserRouter>
        <Routes>
          <Route
            path={RouteConfig.login}
            element={
              <ControlledRoute>
                <Login />
              </ControlledRoute>
            }
          />
          <Route
            path={"*"}
            element={
              <PrivateRoute>
                <DashBoard />
              </PrivateRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

const styles = Object.freeze({
  container: {
    // display: "flex",
    minHeight: "100vh",
    minWidth: "100vw",
    backgroundColor: "#f3f3f3",
  },
});
export default AppRoute;
