// import Cookies from "js-cookie";
const LOCAL_STORE_DID_LOGIN = "@did_login";
const LOCAL_STORE_ACCESS_TOKEN = "@access_token";
const LOCAL_STORE_REFRESH_TOKEN = "@refresh_token";
const LOCAL_STORE_USER = "@user";
class LocalStore {
  saveAccessToken(token: string) {
    localStorage.setItem(LOCAL_STORE_ACCESS_TOKEN, token);
  }
  saveRefreshToken(token: string) {
    localStorage.setItem(LOCAL_STORE_REFRESH_TOKEN, token);
  }
  saveUser(user: { [key: string]: any }) {
    localStorage.setItem(LOCAL_STORE_USER, JSON.stringify(user));
  }
  saveDidLogin(didLogin: boolean) {
    localStorage.setItem(LOCAL_STORE_DID_LOGIN, JSON.stringify(didLogin));
  }
  getDidLogin() {
    const didLogin = localStorage.getItem(LOCAL_STORE_DID_LOGIN);
    if (didLogin) return JSON.parse(didLogin);
    return false;
  }
  getAccessToken() {
    const token = localStorage.getItem(LOCAL_STORE_ACCESS_TOKEN);
    return token || "";
  }
  getRefreshToken() {
    const token = localStorage.getItem(LOCAL_STORE_REFRESH_TOKEN);
    return token || "";
  }
  getUser() {
    const user = localStorage.getItem(LOCAL_STORE_USER);

    if (user) return JSON.parse(user);
    return {};
  }
}

export default new LocalStore();
