import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TOnePagerByCategoryParams} from './types';

type OnePagerState = {
  isLoadingAllOnePager: boolean;
  allOnePager: Array<any>;
  allOnePagerError: string;

  isDeleteOnePager:boolean;
  onePagerDeleteError: string;
  onePagerDeleted: Array<any>;
};

const INITIAL_STATE: OnePagerState = {
  isLoadingAllOnePager: false,
  allOnePager: [],
  allOnePagerError: '',

  isDeleteOnePager: false,
  onePagerDeleteError: '',
  onePagerDeleted: [],
};

const onepager = createSlice({
  name: 'onepager',
  initialState: INITIAL_STATE,
  reducers: {
    loadAllOnePager: state => {
      state.isLoadingAllOnePager = true;
    },
    didLoadAllOnePager: (state, action: PayloadAction<Array<any>>) => {
      state.isLoadingAllOnePager = false;
      state.allOnePager = action.payload;
    },
    didFailToLoadAllOnePager: (state, action: PayloadAction<string>) => {
      state.isLoadingAllOnePager = false;
      state.allOnePagerError = action.payload;
    },
    deleteOnePager: (state, action: PayloadAction<any>) => {
      state.isDeleteOnePager = true;
      state.onePagerDeleteError = '';
    },
    didDeleteOnePager: (state, action: PayloadAction<any>) => {
      state.isDeleteOnePager = false;
      state.onePagerDeleteError = '';
      state.onePagerDeleted = action.payload;
    },
    didFailedToDeleteOnePager: (state, action: PayloadAction<any>) => {
      state.isDeleteOnePager = false;
      state.onePagerDeleteError = action.payload;
    }
  },
});

export const {
  loadAllOnePager,
  didLoadAllOnePager,
  didFailToLoadAllOnePager,
  deleteOnePager,
  didDeleteOnePager,
  didFailedToDeleteOnePager,
} = onepager.actions;
export default onepager.reducer;
