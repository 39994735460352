import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {TPresentationByCategoryParams, TPresentationParams} from './types';

type PresentationState = {
  isLoadingAllPresentation: boolean;
  allPresentations: Array<any>;
  allPresentationError: string;
};

const INITIAL_STATE: PresentationState = {
  isLoadingAllPresentation: false,
  allPresentations: [],
  allPresentationError: '',
};
const presentation = createSlice({
    name: 'presentation',
    initialState: INITIAL_STATE,
    reducers: {
      loadAllPresentation: (
        state,
        _action: PayloadAction<TPresentationParams>,
      ) => {
        state.isLoadingAllPresentation = true;
      },
      didLoadAllPresentation: (state, action: PayloadAction<Array<any>>) => {
        state.isLoadingAllPresentation = false;
        state.allPresentations = action.payload;
      },
      didFailToLoadAllPresentation: (state, action: PayloadAction<string>) => {
        state.isLoadingAllPresentation = false;
        state.allPresentationError = action.payload;
      },
    },
  });
  
  export const {
    loadAllPresentation,
    didLoadAllPresentation,
    didFailToLoadAllPresentation,
  } = presentation.actions;
  export default presentation.reducer;
  