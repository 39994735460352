import { Box, Button, Card } from "@mui/material";
import ActivityOverlay from "components/ActivityOverlay";
import Text from "components/Text";
import ToggleOption from "components/ToggleOption";
import { useAppSelector } from "hooks/useAppSelector";
import { useState } from "react";
import colors from "res/colors";
import ToastCenter from "utils/ToastCenter";
import ClusterHeadList from "./ClusterHeadList";
import ExcelAddClusterHead from "./ExcelAddClusterHead";
const SalesrmClusterHead = () => {
  const {
    isUploadingSalesRMClusterHeadList,
    isDeletingSalesRMClusterHead,
    isLoadingSalesRMClusterHeads,
  } = useAppSelector((state) => state.salesRMClusterHead);
  const { baseURL } = useAppSelector((state) => state.auth);
  const [option, setOption] = useState("Upload excel");

  const handleEventClick = (displayComponent: string) => {
    setOption(displayComponent);
  };

  const renderContent = () => {
    if (option === "Upload excel") {
      return <ExcelAddClusterHead />;
    }
    // else if (option === "Manually") {
    //   return <ManualAddRM />;
    // }
  };

  const handleDownloadSalesRMClusterSheet = () => {
    // dispatch(downloadSalesRMSheet());
    const url = baseURL + "v1/admin/clusterHeadExcel/download";
    let a = document.createElement("a");
    a.setAttribute("href", url);
    a.click();
    ToastCenter.success(
      "File downloading in progress. Please wait for some time."
    );
  };

  const addSalesRmOptions = [
    { label: "Upload excel" },
    //  { label: "Manually" }
  ];
  return (
    <Box
      sx={{ minHeight: "vh", width: "65%", ml: "20%", pt: "50px", pb: "48px" }}
    >
      <Text type="headingBold">SalesRM-Cluster Head</Text>
      <Card
        sx={{
          px: "40px",
          py: "40px",
          display: "flex",
          flexGrow: 1,
          flexDirection: "column",
          mt: "20px",
          mb: "48px",
          borderRadius: "16px",
        }}
      >
        <Text type="extraLargeBold">ADD SalesRM-Cluster Head</Text>

        <Box sx={{ mt: "16px" }}>
          <ToggleOption
            options={addSalesRmOptions}
            onClick={handleEventClick}
            selected={option}
          />
        </Box>
        {renderContent()}
      </Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Text type="subHeadingBold">List of Sales RM-Cluster heads</Text>
        {/** Added export Sales RM-Cluster heads sheet logic */}
        <Button
          variant="contained"
          sx={{
            py: "5px",
            px: "20px",
            ml: "10px",
            backgroundColor: colors.backGroundBlue,
            textTransform: "none",
          }}
          onClick={handleDownloadSalesRMClusterSheet}
        >
          Export Sales RM-Cluster head List
        </Button>
      </Box>
      <ClusterHeadList />
      {(isUploadingSalesRMClusterHeadList ||
        isDeletingSalesRMClusterHead ||
        isLoadingSalesRMClusterHeads) && <ActivityOverlay />}
    </Box>
  );
};
export default SalesrmClusterHead;
