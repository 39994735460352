import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useRef, useState, useEffect } from "react";
import colors from "res/colors";
import { ReactComponent as Cancel } from "res/images/cancel.svg";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: () => void;
  onAssign: (value?: string) => void;
}

const AssignPopup: React.FC<ConfirmationDialogRawProps> = (props) => {
  const { onClose, onAssign, value: valueProp, open, ...other } = props;

  const dispatch = useAppDispatch();
  const { salesRMs, didLoadSalesRMs } = useAppSelector(
    (state) => state.salesRM
  );

  const [value, setValue] = useState(valueProp);
  const radioGroupRef = useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onAssign(value);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div>
      <Dialog
        sx={{ mx: "25%", mt: "10%", pt: "16px", px: "40px" }}
        fullScreen
        TransitionProps={{ onEntering: handleEntering }}
        open={open}
        {...other}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            pr: "10px",
          }}
        >
          <IconButton onClick={handleCancel}>
            <Cancel />
          </IconButton>
        </Box>
        <DialogTitle
          sx={{ fontSize: "26px", letterSpacing: 0, lineHeight: "34px" }}
        >
          Select Sales RM to Assign Lead
        </DialogTitle>
        <DialogContent dividers>
          <RadioGroup
            ref={radioGroupRef}
            aria-label="ringtone"
            name="ringtone"
            value={value}
            onChange={handleChange}
          >
            {salesRMs.map((rowData: any) => (
              <Box>
                <FormControlLabel
                  value={rowData.email}
                  key={rowData.id}
                  control={<Radio sx={{ color: colors.backGroundBlue }} />}
                  label={rowData.name}
                />
                <Divider />
              </Box>
            ))}
          </RadioGroup>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Button variant="contained" onClick={handleOk}>
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AssignPopup;
