import { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { ReactComponent as ContentManagerIcon } from "res/images/contentManagerIcon.svg";
import { ReactComponent as BrokerageIcon } from "res/images/brokerageIcon.svg";
import { ReactComponent as LeadManagementIcon } from "res/images/leadManagementIcon.svg";
import { ReactComponent as NotificationIcon } from "res/images/notificationIcon.svg";
import { ReactComponent as SalesRmIcon } from "res/images/salesRmIcon.svg";
import { ReactComponent as ScheduleEventIcon } from "res/images/scheduleEventIcon.svg";
import { ReactComponent as UserManagementIcon } from "res/images/userManagementIcon.svg";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import RouteConfig from "routes/RouteConfig";
import colors from "res/colors";
import Text from "components/Text";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LoginBanner from "pages/BannerManagement/BannerManagement";
import LocalStore from "utils/LocalStore";
import { setDidLogin } from "store/auth";
import { useAppDispatch } from "hooks";

const SideMenu = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const drawerWidth = "332px";
  const dispatch = useAppDispatch();
  const [selectedMenu, setSelectedMenu] = useState("Content Management");
  const userData = LocalStore.getUser();

  const drawerList = [
    {
      id: 1,
      text: "Content Management",
      icon: <ContentManagerIcon />,
    },
    {
      id: 2,
      text: "Schedule Event",
      icon: <ScheduleEventIcon />,
    },
    {
      id: 3,
      text: "SalesRM-Cluster Head",
      icon: <BrokerageIcon />,
    },
    {
      id: 4,
      text: "Sales RM List",
      icon: <SalesRmIcon />,
    },
    {
      id: 5,
      text: "SalesRM-Lead Management",
      icon: <LeadManagementIcon />,
    },
    {
      id: 6,
      text: "SIP Performance",
      icon: <SalesRmIcon />,
    },
    {
      id: 7,
      text: "Create Notification",
      icon: <NotificationIcon />,
    },
    {
      id: 8,
      text: "Scheme Management",
      icon: <LeadManagementIcon />,
    },
    {
      id: 9,
      text: "Banner Management",
      icon: <ContentManagerIcon />,
    },
    {
      id: 10,
      text: "Fund Manager",
      icon: <SalesRmIcon />,
    },
    {
      id: 11,
      text: "User Management",
      icon: <UserManagementIcon />,
    },
    {
      id: 12,
      text: "Brokerage Structure Management",
      icon: <BrokerageIcon />,
    },
  ];

  const handleMenuSelection = (menuItem: any) => {
    setSelectedMenu(menuItem.text);
    switch (menuItem.id) {
      case 1:
        navigate(RouteConfig.content_management);
        break;
      case 2:
        navigate(RouteConfig.event_management);
        break;
      case 3:
        navigate(RouteConfig.salesrm_cluster_Head);
        break;
      case 4:
        navigate(RouteConfig.sales_rm_management);
        break;
      case 5:
        navigate(RouteConfig.lead_management);
        break;

      case 6:
        navigate(RouteConfig.sip_performance);
        break;
      case 7:
        navigate(RouteConfig.notification_management);
        break;
      case 8:
        navigate(RouteConfig.scheme_management);
        break;
      case 9:
        navigate(RouteConfig.login_banner);
        break;
      case 10:
        navigate(RouteConfig.fund_manager);
        break;
      case 11:
        navigate(RouteConfig.user_management);
        break;
      case 12:
        navigate(RouteConfig.broker_structre_management);
        break;
    }
  };

  const handleExpandClick = () => {};

  const handleLogout = () => {
    dispatch(setDidLogin(false));
    LocalStore.saveAccessToken("");
    LocalStore.saveRefreshToken("");
    LocalStore.saveUser({});
    navigate(RouteConfig.login, { replace: true });
  };

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      {/* <Toolbar /> */}
      <Box
        sx={{
          mt: "90px",
          overflow: "auto",
        }}
      >
        <div className={classes.drawerNameContainer}>
          <Text
            type="nameLarge"
            sx={{
              fontSize: "20px",
              letterSpacing: "0em",
              lineHeight: "26px",
              fontFamily: "Graphik",
              textTransform: "capitalize",
            }}
          >
            <Grid container>
              <Grid item xs={10} sx={{ mb: "10px" }}>
                {userData?.name}
              </Grid>
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <IconButton onClick={handleExpandClick}>
                  <KeyboardArrowDownIcon
                    sx={{ color: colors.backGroundBlue }}
                  />
                </IconButton>
              </Grid>
            </Grid>
          </Text>
          <Text type="medium">{userData?.admin ? "Admin" : "User"}</Text>
        </div>

        <Divider />

        {drawerList.map((drawerData) => {
          if (!userData?.admin && drawerData?.text === "User Management") {
            //User Login should not have user management tab
            return null;
          }
          return (
            <div>
              <List>
                <ListItem
                  sx={{
                    py: "5px",
                    pl: "0px",
                  }}
                  onClick={() => handleMenuSelection(drawerData)}
                >
                  <Box
                    sx={[
                      selectedMenu === drawerData.text
                        ? {
                            py: "20px",
                            width: "5px",
                            backgroundColor: colors.backGroundBlue,
                          }
                        : {
                            py: "20px",
                            width: "5px",
                            backgroundColor: colors.white,
                          },
                    ]}
                  ></Box>
                  <ListItemIcon sx={{ pl: "27px" }}>
                    {drawerData.icon}
                  </ListItemIcon>
                  {/* {selectedMenu === drawerData.text ? (
                    <Text type="largeBold">{drawerData.text}</Text>
                  ) : (
                    <Text type="large">{drawerData.text}</Text>
                  )} */}
                  <Typography
                    sx={
                      selectedMenu === drawerData.text
                        ? {
                            fontSize: "16px",
                            lineHeight: "22px",
                            fontWeight: "bold",
                          }
                        : {
                            fontSize: "16px",
                            lineHeight: "22px",
                          }
                    }
                  >
                    {drawerData.text}
                  </Typography>
                </ListItem>
              </List>
              <Box sx={{ mx: "32px" }}>
                <Divider light />
              </Box>
            </div>
          );
        })}
      </Box>
    </Drawer>
  );
};
const useStyles = makeStyles({
  drawerNameContainer: {
    paddingTop: "15px",
    paddingLeft: "32px",
    paddingBottom: "15px",
    backgroundColor: "#EAF8F9",
  },
});
export default SideMenu;
