export const TEMP = 1;
export const ContentOptions = [
  {
    title: "Ready to Assigned to MFD",
    value: "TO_BE_ASSIGNED",
  },
  {
    title: "Ready to Assigned to RM",
    value: "TO_BE_ASSIGNED_SALES_RM",
  },
  {
    title: "Assigned to MFD",
    value: "Assigned",
  },
  {
    title: "Not Interested",
    value: "Not_Interested",
  },
  {
    title: "Converted",
    value: "Converted",
  },
  {
    title: "Expired or No Response",
    value: "Expired_NoResponse",
  },
];
