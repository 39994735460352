import { Button, Divider, Grid } from "@mui/material";
import { Box } from "@mui/system";
import CheckBox from "components/CheckBox";
import { SelectOption } from "components/FormSelect";
import FormInput from "components/FormInput";
import FormSelect from "components/FormSelect";
import ImageDropzone from "components/ImageDropZone";
import { useState, useRef } from "react";
import { TContentForm } from "../types";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { contentValidationSchema } from "../validationSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { uploadContent } from "../store/slice";

import colors from "res/colors";
import { ReactComponent as ThumbnailIcon } from "res/images/thumbnailIcon.svg";

const OnePager = () => {
  const dispatch = useAppDispatch();
  const { isUploadingContent, uploadContentError, didUploadContent } =
    useAppSelector((state) => state.contentManagement);

  const [isTrending, setIsTrending] = useState(false);
  const [isRecomended, setIsRecomended] = useState(false);

  const [type, setType] = useState("");
  const imageDropzoneRef = useRef<any>(null);

  const formMethods = useForm<TContentForm>({
    mode: "onSubmit",
    reValidateMode: "onChange",

    resolver: yupResolver(contentValidationSchema),
  });

  const onSubmit = (data: TContentForm) => {
    const prams = {
      // thumbnail_file: data.thumbnail_file,
      material_file: data.material_file,
      imageRequest: {
        trending: isTrending,
        recommended: isRecomended,
        title: data.title,
        category: data.category,
        videoCreator: "Invesco User",
        contentCategories: "ONEPAGER",
      },
    };

    dispatch(uploadContent(prams));
  };

  const handleImageDrop = (imageData: { url: string; name: string }) => {
    formMethods.setValue("material_file", imageData);
  };
  const handleTrendingCheckBoxChange = (checked: boolean) => {
    setIsTrending(checked);
  };
  const handleRecomendedCheckBoxChange = (checked: boolean) => {
    setIsRecomended(checked);
  };
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "column", my: "24px" }}>
        <FormProvider {...formMethods}>
          <FormInput
            variant="filled"
            name="title"
            InputProps={{
              disableUnderline: true,
            }}
            label="Add Title"
          />

          <Box sx={{ width: "100%", height: "20px" }} />
          <FormSelect
            label="Select Type"
            labelId="type"
            id="type"
            options={[
              {
                title: "Equity",
                value: "Equity",
              },
              {
                title: "Fixed Income",
                value: "Fixed Income",
              },
              {
                title: "Other",
                value: "Other",
              },
            ]}
            name="category"
          />
        </FormProvider>
      </Box>
      <Box sx={{ width: "100%", height: "50px" }} />
      <ImageDropzone
        onDropped={handleImageDrop}
        ref={imageDropzoneRef}
        heading="Upload PDF file her"
        icon={<ThumbnailIcon height={"44px"} width={"56px"} />}
        type="PDF"
      />
      <Box sx={{ mt: "40px", mb: "21px" }}>
        <Divider variant="fullWidth" />
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CheckBox type="Trending" onClick={handleTrendingCheckBoxChange} />
        </Grid>
        <Grid item xs={6}>
          <CheckBox
            type="Recomended"
            onClick={handleRecomendedCheckBoxChange}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={formMethods.handleSubmit(onSubmit)}
          sx={{
            fontSize: "18px",
            lineHeight: "24px",
            py: "16px",
            px: "48px",
            fontWeight: 500,
            backgroundColor: colors.backGroundBlue,
          }}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default OnePager;
