import { all, takeLatest, call, put } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import Request from "networking/Request";
import { TResponse } from "types";

import { loadSchemeNames, didLoadSchemeNames } from "./slice";

const SCHEME_NAMES_ENDPOINT = "v1/admin/scheme/titleList";

function* handleLoadSchemeNames() {
  const response: TResponse = yield call(
    Request.POST,
    SCHEME_NAMES_ENDPOINT,
    {}
  );

  if (response.data) {
    const names = response.data.data.map((item: string) => ({
      title: item,
      value: item,
    }));
    yield put(didLoadSchemeNames(names));
  }
  //
}

function* watcherLoadSchemeNames() {
  yield takeLatest(loadSchemeNames.type, handleLoadSchemeNames);
}

export function* schemesSaga() {
  yield all([watcherLoadSchemeNames()]);
}
