import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type TrainingMaterialsState = {
  isTrainingMaterials: boolean;
  trainingMaterialsError: string;
  trainingMaterialsData: Array<any>;

  isDeleteTrainingMaterials: boolean;
  trainingMaterialsDeleteError: string;
  trainingMaterialsDeleted: string;
};

const INITIAL_STATE: TrainingMaterialsState = {
  isTrainingMaterials: false,
  trainingMaterialsError: "",
  trainingMaterialsData: [],

  isDeleteTrainingMaterials: false,
  trainingMaterialsDeleteError: "",
  trainingMaterialsDeleted: "",
};

const TrainingMaterialsManagement = createSlice({
  name: "TrainingMaterialsManagement",
  initialState: INITIAL_STATE,
  reducers: {
    loadTrainingMaterials: (state, _action: PayloadAction<any>) => {
      state.isTrainingMaterials = true;
      state.trainingMaterialsError = "";
    },
    didTrainingMaterials: (state, action: PayloadAction<any>) => {
      state.isTrainingMaterials = false;
      state.trainingMaterialsData = action.payload;
    },
    didFailToLoadTrainingMaterials: (state, action: PayloadAction<any>) => {
      state.isTrainingMaterials = false;
      state.trainingMaterialsError = action.payload;
    },
    deleteTrainingMaterials: (state, action: PayloadAction<any>) => {
      state.isDeleteTrainingMaterials = true;
      state.trainingMaterialsDeleteError = "";
      state.trainingMaterialsDeleted = "";
    },
    didDeleteTrainingMaterials: (state, action: PayloadAction<any>) => {
      state.isDeleteTrainingMaterials = false;
      state.trainingMaterialsDeleteError = "";
      state.trainingMaterialsDeleted = action.payload;
    },
    didFailedToDeleteTrainingMaterials: (state, action: PayloadAction<any>) => {
      state.isDeleteTrainingMaterials = false;
      state.trainingMaterialsDeleteError = action.payload;
    },
  },
});

export const {
  loadTrainingMaterials,
  didTrainingMaterials,
  didFailToLoadTrainingMaterials,
  deleteTrainingMaterials,
  didDeleteTrainingMaterials,
  didFailedToDeleteTrainingMaterials,
} = TrainingMaterialsManagement.actions;

export default TrainingMaterialsManagement.reducer;
