import { Box, Card, Divider } from "@mui/material";
import React, { useEffect } from "react";
import ProductVideos from "./ProductVideos";
import MarketInsights from "./MarketInsights";
import InfoGraphics from "./InfoGraphics";
import FactSheet from "./FactSheet";
import Select from "components/Select";
import { SelectOption } from "components/FormSelect";
import Presentation from "./Presentation";
import DigitalPoster from "./DigitalPoster";
import ProductTraining from "./ProductTraining";
import OnePager from "./OnePager";
import ProductNote from "./ProductNote";
import TrainingMaterials from "./TrainingMaterials";
import { GridRenderCellParams } from "@mui/x-data-grid";
import ContentAction from "components/ContentAction";
import UpdatedContent from "components/UpdatedContent";
import Text from "components/Text";
import InteviewVideos from "./InterviewVideos";
import { useAppSelector } from "hooks/useAppSelector";

import { useAppDispatch } from "hooks/useAppDispatch";
import DigitalPosterContents from "./DigitalPoster/DigitalPosterContents";
import InfoGraphicsContents from "./InfoGraphics/InfoGraphicsContents";
import ProductVideosContents from "./ProductVideos/ProductVideosContents";
import InterviewVideosContents from "./InterviewVideos/InterviewVideosContents";
import ProductNoteContents from "./ProductNote/ProductNoteContents";
import ProductTrainingContents from "./ProductTraining/ProductTrainingContents";
import TrainingMaterialsContents from "./TrainingMaterials/TrainingMaterialsContents";
import { loadSchemeNames } from "pages/SchemeManagement/store/slice";

const ContentManagement = () => {
  const dispatch = useAppDispatch();
  const [contentOption, setContentOption] = React.useState("");

  useEffect(() => {
    dispatch(loadSchemeNames());
  }, []);
  const handleContentDelete = (rowData: any) => {};
  const handleContentEdit = (rowData: any) => {};
  const handleContentView = (rowData: any) => {};

  const ContentOptions = [
    // {
    //   // no upload
    //   title: "Market Insight",
    //   value: "MarketInsight",
    // },
    {
      title: "InfoGraphic",
      value: "InfoGraphic",
    },
    {
      title: "Product Videos",
      value: "ProductVideos",
    },
    {
      title: "Interview Videos",
      value: "Interview Videos",
    },
    // {
    //   title: "Factsheet",
    //   value: "FactSheet",
    // },
    // {
    //   title: "Presentation",
    //   value: "Presentation",
    // },
    // {
    //   title: "One Pager",
    //   value: "OnePager",
    // },
    {
      title: "Product Note",
      value: "ProductNote",
    },
    {
      title: "Digital Poster",
      value: "DigitalPoster",
    },
    {
      title: "Product Training",
      value: "ProductTraining",
    },
    {
      title: "Training Material",
      value: "TrainingMaterial",
    },
  ];

  const handleContentManagementChange = (option: SelectOption) => {
    setContentOption(option.value);
  };

  const renderContentManagementOptions = () => {
    if (contentOption === "MarketInsight") {
      return <MarketInsights />;
    } else if (contentOption === "InfoGraphic") {
      return <InfoGraphics />;
    } else if (contentOption === "ProductVideos") {
      return <ProductVideos />;
    } else if (contentOption === "FactSheet") {
      return <FactSheet />;
    } else if (contentOption === "Presentation") {
      return <Presentation />;
    } else if (contentOption === "DigitalPoster") {
      // dispatch(loadInfographics(marketInsightsData));
      return <DigitalPoster />;
    } else if (contentOption === "ProductTraining") {
      return <ProductTraining />;
    } else if (contentOption === "OnePager") {
      return <OnePager />;
    } else if (contentOption === "ProductNote") {
      return <ProductNote />;
    } else if (contentOption === "TrainingMaterial") {
      return <TrainingMaterials />;
    } else if (contentOption === "Interview Videos") {
      return <InteviewVideos />;
    }
  };

  const renderContentManagementContents = () => {
    if (contentOption === "MarketInsight") {
      return <MarketInsights />;
    } else if (contentOption === "InfoGraphic") {
      return <InfoGraphicsContents />;
    } else if (contentOption === "ProductVideos") {
      return <ProductVideosContents />;
    } else if (contentOption === "FactSheet") {
      return <FactSheet />;
    } else if (contentOption === "Presentation") {
      return <Presentation />;
    } else if (contentOption === "DigitalPoster") {
      return <DigitalPosterContents />;
    } else if (contentOption === "ProductTraining") {
      return <ProductTrainingContents />;
    } else if (contentOption === "OnePager") {
      return <OnePager />;
    } else if (contentOption === "ProductNote") {
      return <ProductNoteContents />;
    } else if (contentOption === "TrainingMaterial") {
      return <TrainingMaterialsContents />;
    } else if (contentOption === "Interview Videos") {
      return <InterviewVideosContents />;
    }
  };
  return (
    <Box
      sx={{
        pt: "50px",
        ml: "20%",
        minHeight: "100%",
        width: "65%",
        pb: "40px",
        // backgroundColor: "blue",
      }}
    >
      <Box sx={{ pb: "20px" }}>
        <Text type="headingBold">Content Management</Text>
      </Box>

      <Card sx={{ flexGrow: 1, mb: "48px", borderRadius: "16px" }}>
        <Box sx={{ px: "40px", py: "43px" }}>
          <Box sx={{ pb: "16px" }}>
            <Text type="largeBold">Select Section</Text>
          </Box>
          <Select
            options={ContentOptions}
            onOptionSelect={handleContentManagementChange}
            value={contentOption}
            sx={{ pb: "40px" }}
            placeHolder={"Select Section"}
          />

          <Box sx={{ my: "40px" }}>
            <Divider variant="fullWidth" />
          </Box>
          {renderContentManagementOptions()}
        </Box>
      </Card>
      {renderContentManagementContents()}
      {/* <UpdatedContent rows={rows} columns={columns} pageSize={5} /> */}
    </Box>
  );
};
export default ContentManagement;
