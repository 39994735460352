import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type LeadsManagementState = {
  isUploadingLeads: boolean;
  uploadLeadsError: string;
  didUploadLeads: boolean;

  isAddingLead: boolean;
  addLeadError: string;
  didAdddLead: boolean;

  isSearchingLead: boolean;
  searchLeadError: string;
  searchLeadResult: Array<any>;

  isDeletingLead: boolean;
  didDeleteLead: boolean;
  deleteLeadError: string;

  isDeletingLeadExcel: boolean;
  didDeleteLeadExcel: boolean;
  deleteLeadExcelError: string;

  isMappingLead: boolean;
  didMapLead: boolean;
  mapLeadError: string;

  isUpdatingLead: boolean;
  didUpdateLead: boolean;
  updateLeadError: string;

  isLoadingLeads: boolean;
  loadLeadsError: string;
  leads: Array<any>;

  isLoadingAutoAssign: boolean;
  didAutoAssign: boolean;
};

const INITIAL_STATE: LeadsManagementState = {
  isUploadingLeads: false,
  uploadLeadsError: "",
  didUploadLeads: false,

  isAddingLead: false,
  addLeadError: "",
  didAdddLead: false,

  isSearchingLead: false,
  searchLeadError: "",
  searchLeadResult: [],

  isDeletingLead: false,
  didDeleteLead: false,
  deleteLeadError: "",

  isDeletingLeadExcel: false,
  didDeleteLeadExcel: false,
  deleteLeadExcelError: "",

  isMappingLead: false,
  didMapLead: false,
  mapLeadError: "",

  isUpdatingLead: false,
  didUpdateLead: false,
  updateLeadError: "",

  isLoadingLeads: false,
  loadLeadsError: "",
  leads: [],

  isLoadingAutoAssign: false,
  didAutoAssign: false,
};

const leadsManagement = createSlice({
  name: "leadsManagement",
  initialState: INITIAL_STATE,
  reducers: {
    uploadLeads: (state, _action: PayloadAction<any>) => {
      state.isUploadingLeads = true;
    },
    didUploadLeads: (state) => {
      state.isUploadingLeads = false;
      state.didUploadLeads = true;
    },
    didFailToUploadLeads: (state, action: PayloadAction<any>) => {
      state.isUploadingLeads = false;
      state.uploadLeadsError = action.payload;
    },

    addLead: (state, _action: PayloadAction<any>) => {
      state.isAddingLead = true;
    },
    didAddLead: (state) => {
      state.isAddingLead = false;
      state.didAdddLead = true;
    },
    didFailToAddLead: (state, action: PayloadAction<any>) => {
      state.isAddingLead = false;
      state.addLeadError = action.payload;
    },

    findLead: (state, _action: PayloadAction<any>) => {
      state.isSearchingLead = true;
    },
    didFindLead: (state, action: PayloadAction<any>) => {
      state.isSearchingLead = false;
      state.searchLeadResult = action.payload;
    },
    didFailToFindLead: (state, action: PayloadAction<string>) => {
      state.isSearchingLead = false;
      state.searchLeadError = action.payload;
    },
    resetDeleteLeadStates: (state) => {
      state.isDeletingLead = false;
      state.didDeleteLead = false;
      state.deleteLeadError = "";
    },
    deleteLead: (state, _action: PayloadAction<any>) => {
      state.isDeletingLead = true;
    },

    didDeleteLead: (state) => {
      state.isDeletingLead = false;
      state.didDeleteLead = true;
    },
    didFailToDeleteLead: (state, action: PayloadAction<any>) => {
      state.isDeletingLead = false;
      state.deleteLeadError = action.payload;
    },
    resetMapLeadStates: (state) => {
      state.isMappingLead = false;
      state.didMapLead = false;
      state.mapLeadError = "";
    },
    mapLead: (state, _action: PayloadAction<any>) => {
      state.isMappingLead = true;
    },
    didMapLead: (state) => {
      state.isMappingLead = false;
      state.didMapLead = true;
    },
    didFailToMapLead: (state, _action: PayloadAction<string>) => {
      state.isMappingLead = false;
      state.mapLeadError = _action.payload;
    },
    resetUpdateLeadStates: (state) => {
      state.isUpdatingLead = false;
      state.didUpdateLead = false;
      state.updateLeadError = "";
    },
    updateLead: (state, _action: PayloadAction<any>) => {
      state.isUpdatingLead = true;
    },
    didUpdateLead: (state) => {
      state.isUpdatingLead = false;
      state.didUpdateLead = true;
    },
    didFailToUpdateLead: (state, action: PayloadAction<string>) => {
      state.isUpdatingLead = false;
      state.updateLeadError = action.payload;
    },
    loadLeads: (state, _action: PayloadAction<any>) => {
      state.isLoadingLeads = true;
      state.leads = [];
      state.loadLeadsError = "";
    },
    didLoadLeads: (state, action: PayloadAction<any>) => {
      state.isLoadingLeads = false;
      state.leads = action.payload;
    },
    didFailToLoadLeads: (state, action: PayloadAction<any>) => {
      state.isLoadingLeads = false;
      state.leads = [];
      state.loadLeadsError = action.payload;
    },

    deleteLeadExcel: (state, _action: PayloadAction<any>) => {
      state.isDeletingLeadExcel = true;
    },

    didDeleteLeadExcel: (state) => {
      state.isDeletingLeadExcel = false;
      state.didDeleteLeadExcel = true;
    },
    didFailToDeleteLeadExcel: (state, action: PayloadAction<any>) => {
      state.isDeletingLeadExcel = false;
      state.deleteLeadExcelError = action.payload;
    },
    autoAssignLeads: (state) => {
      state.isLoadingAutoAssign = true;
      state.didAutoAssign = false;
    },
    didAutoAssigned: (state) => {
      state.isLoadingAutoAssign = false;
      state.didAutoAssign = true;
    },
    didFailToAutoAssigned: (state) => {
      state.isLoadingAutoAssign = false;
    },

    resetDeleteLeadExcelStates: (state) => {
      state.isDeletingLeadExcel = false;
      state.didDeleteLeadExcel = false;
      state.deleteLeadExcelError = "";
    },
    resetUploadLeadStates: (state) => {
      state.isUploadingLeads = false;
      state.didUploadLeads = false;
      state.uploadLeadsError = "";
    },
    resetAddLeadStates: (state) => {
      state.isAddingLead = false;
      state.didAdddLead = false;
      state.addLeadError = "";
    },
  },
});

export const {
  autoAssignLeads,
  didAutoAssigned,
  didFailToAutoAssigned,
  deleteLeadExcel,
  didDeleteLeadExcel,
  didFailToDeleteLeadExcel,
  resetDeleteLeadExcelStates,
  resetMapLeadStates,
  uploadLeads,
  didUploadLeads,
  didFailToUploadLeads,
  addLead,
  didAddLead,
  didFailToAddLead,
  resetUploadLeadStates,
  resetAddLeadStates,
  findLead,
  didFindLead,
  didFailToFindLead,
  deleteLead,
  didDeleteLead,
  didFailToDeleteLead,
  mapLead,
  didMapLead,
  didFailToMapLead,
  updateLead,
  didUpdateLead,
  didFailToUpdateLead,
  loadLeads,
  didLoadLeads,
  didFailToLoadLeads,
  resetDeleteLeadStates,
  resetUpdateLeadStates,
} = leadsManagement.actions;
export default leadsManagement.reducer;
