import { createSlice, PayloadAction } from "@reduxjs/toolkit";


type MarketInsightState = {
    isMarketInsightContent: boolean;
    marketInsightContentError: string;
    didMarketInsightContent: boolean;

    isArticlesContent: boolean;
    articlesContentError: string;
    didArticlesContent: boolean;
  };

const INITIAL_STATE: MarketInsightState = {
    isMarketInsightContent: false,
    marketInsightContentError: "",
    didMarketInsightContent: false,

    isArticlesContent: false,
    articlesContentError: "",
    didArticlesContent: false,
};

const marketInsightManagement = createSlice({
    name:"marketInsightManagement",
    initialState: INITIAL_STATE,
    reducers: {
        marketInsightContent:(state, _action: PayloadAction<any>) => {
            state.isMarketInsightContent = true;
        },
        didMarketInsightContent:(state) =>{
            state.isMarketInsightContent = false;
            state.didMarketInsightContent = true;
        },
        didFailToMarketInsightContent:(state, action: PayloadAction<any>) => {
            state.isMarketInsightContent = false;
            state.marketInsightContentError = action.payload
        },

        articlesContent:(state, _action: PayloadAction<any>) => {
            state.isArticlesContent = true;
        },
        didArticlesContent:(state) =>{
            state.isArticlesContent = false;
            state.didArticlesContent = true;
        },
        didFailToArticlesContent:(state, action: PayloadAction<any>) => {
            state.isArticlesContent = false;
            state.articlesContentError = action.payload
        },
        resetArticlesStates: (state) => {
            state.isArticlesContent = false;
            state.didArticlesContent = false;
            state.articlesContentError = "";
        },
        resetMarketInsightStates: (state) => {
            state.isMarketInsightContent = false;
            state.didMarketInsightContent = false;
            state.marketInsightContentError = "";
        },
    },
});

export const {
    marketInsightContent,
    didMarketInsightContent,
    didFailToMarketInsightContent,
    resetMarketInsightStates,
    articlesContent,
    didArticlesContent,
    didFailToArticlesContent,
    resetArticlesStates,
    
} = marketInsightManagement.actions;

export default marketInsightManagement.reducer;